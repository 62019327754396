import {Button, DialogActions, DialogContent, Typography} from '@mui/material';
import {bool, func, string} from 'prop-types';
import styled from 'styled-components';

import BaseModal from '../modal/BaseModal';

const BoldText = styled.span`
  font-family: InstrumentSansBold;
`;

const DeleteUserModal = ({isOpen, onCancel, onSubmit, username, entity}) => {
  return (
    <BaseModal onClose={onCancel} title="Supprimer un utilisateur" open={isOpen}>
      <DialogContent sx={{p: 3}}>
        <Typography>
          Vous êtes sur le point de supprimer <BoldText>{username}</BoldText> de {entity}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onCancel}>
          Annuler
        </Button>
        <Button variant="contained" color="error" onClick={onSubmit}>
          Supprimer l'utilisateur
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

DeleteUserModal.propTypes = {
  isOpen: bool.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  username: string.isRequired,
  entity: string.isRequired
};

export default DeleteUserModal;

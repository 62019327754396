import {Typography} from '@mui/material';
import {string} from 'prop-types';

const FormUppercaseLabel = ({label}) => (
  <Typography fontSize={11} mb={0.5} mt={2} fontFamily="InstrumentSansBold">
    {label.toUpperCase()}
  </Typography>
);

FormUppercaseLabel.propTypes = {
  label: string.isRequired
};

export default FormUppercaseLabel;

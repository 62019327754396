import ReportIcon from '@mui/icons-material/Report';
import {Grid, Typography} from '@mui/material';

import Button from '../components/form/buttons/Button';
import Link from '../components/link/Link';
import {APP_ROUTES} from '../const';
import useAuth from '../hooks/providers/useAuth';
import usePayment from '../hooks/providers/usePayment';
import useProfile from '../hooks/providers/useProfile';

const RestrictedAccess = () => {
  const {productSubscribed} = usePayment();
  const {isPartnerPolicy} = useProfile();
  const {setIsContactFormOpen} = useAuth();

  const buttonText = productSubscribed ? 'Mettre à niveau mon abonnement' : 'Choisir un abonnement';
  const firstParagraph = productSubscribed
    ? 'Vous avez excédé le nombre de projets que votre abonnement vous permet de créer.'
    : 'Vous ne pouvez pas consulter vos rapports car vous n’avez pas d’abonnements en cours.';
  const secondParagraph = productSubscribed ? 'Mettez à niveau votre abonnement pour rétablir le service.' : ' Choisissez-en un pour rétablir le service.';

  const linkHref = isPartnerPolicy ? '#' : APP_ROUTES.subscription;
  const linkText = isPartnerPolicy ? 'Contactez-nous' : buttonText;
  const linkOnClick = isPartnerPolicy ? () => setIsContactFormOpen(true) : null;

  return (
    <Grid sx={{height: 'calc(100vh - 64px)'}} container justifyContent="center" alignItems="center" direction="column" flex={1}>
      <ReportIcon sx={{fontSize: 150, mb: 3}} color="warning" />
      <Typography variant="h3" fontWeight="bold" color="#000" mb={1}>
        Accès restreint
      </Typography>
      <Typography variant="p" sx={{maxWidth: 600}} py={2} textAlign="center" fontSize="large">
        {firstParagraph}
      </Typography>

      {!isPartnerPolicy && (
        <Typography variant="p" mb={3} sx={{maxWidth: 600}} textAlign="center" fontSize="large">
          {secondParagraph}
        </Typography>
      )}

      <Link to={linkHref} onClick={linkOnClick}>
        <Button variant="contained" color="secondary" size="large">
          {linkText}
        </Button>
      </Link>
    </Grid>
  );
};

export default RestrictedAccess;

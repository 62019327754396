import {useEffect, useState} from 'react';

import {TextField, Typography} from '@mui/material';
import {func, string} from 'prop-types';

import {REGULAR_EXPRESSIONS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import {getHumanReadableSiret} from '../../../utils';
import PasswordInputRevealable from '../../form/PasswordInputRevealable';

// eslint-disable-next-line complexity
const CegidQuadraConnectorForm = ({cegidFolderId, setCegidFolderId, siret, setSiret}) => {
  const {username, setUsername, password, setPassword} = useProjects();
  const [isSiretValid, setIsSiretValid] = useState(false);
  const [pristine, setPristine] = useState(true);

  const {createProjectApiKeyError, createProjectSirenError, setCreateProjectSirenError, setCreateProjectApiKeyError, setIsCegidFormValid} = useProjects();

  const checkSiretValidity = siretInput => {
    setIsSiretValid(REGULAR_EXPRESSIONS.siret.test(siretInput));
  };

  const handleSiretChange = e => {
    setPristine(false);
    setCreateProjectSirenError('');
    const siretWithoutSpaces = e.target.value.replaceAll(' ', '');
    setSiret(siretWithoutSpaces);
    checkSiretValidity(siretWithoutSpaces);
  };

  const getSiretTextFieldError = () => {
    if (siret !== '' && !isSiretValid) {
      return 'Le siret doit être composé de 14 chiffres';
    }

    if (!pristine && !siret && !cegidFolderId) {
      return 'Vous devez renseigner un numéro de dossier OU un numéro de siret';
    }

    if (createProjectSirenError) {
      return createProjectSirenError;
    }

    return '';
  };

  const handleAccountIdChange = e => {
    setPristine(false);
    setCreateProjectSirenError('');
    setCegidFolderId(e.target.value);
  };

  const getAccountIdTextFieldError = () => {
    if (cegidFolderId !== '' && cegidFolderId.length !== 6) {
      return 'Le numéro de dossier doit comporter 6 chiffres';
    }

    if (!pristine && !siret && !cegidFolderId) {
      return 'Vous devez renseigner un numéro de dossier OU un numéro de siret';
    }

    return '';
  };

  const cegidFolderIdTextFieldHasError = (!pristine && !siret && !cegidFolderId) || (cegidFolderId !== '' && cegidFolderId.length !== 6);
  const siretTextFieldHasError = (!pristine && !siret && !cegidFolderId) || (siret !== '' && !isSiretValid);

  useEffect(() => {
    let isCegidFormValid = false;
    if (!cegidFolderIdTextFieldHasError && !siretTextFieldHasError && username && password) {
      isCegidFormValid = true;
    }
    setIsCegidFormValid(isCegidFormValid);
  }, [siret, cegidFolderId, username, password]);

  // Sets siret as valid if already filled and valid
  // This happens when we switch to another connector while having siret TextField filled and valid
  useEffect(() => {
    checkSiretValidity(siret);
  }, []);

  return (
    <>
      <TextField
        inputProps={{maxLength: 6, minLength: 6}}
        error={cegidFolderIdTextFieldHasError || createProjectSirenError !== ''}
        helperText={getAccountIdTextFieldError()}
        size="small"
        value={cegidFolderId}
        label="Numéro de dossier"
        color="primary"
        fullWidth
        onChange={handleAccountIdChange}
        onKeyDown={e => e.stopPropagation()}
      />

      <Typography sx={{fontSize: 14, my: 1, fontWeight: 'bold', textAlign: 'center'}}>OU</Typography>

      <TextField
        error={siretTextFieldHasError || createProjectSirenError !== ''}
        helperText={getSiretTextFieldError()}
        inputProps={{maxLength: 17, minLength: 17}}
        size="small"
        value={getHumanReadableSiret(siret).trim()}
        label="Siret"
        color="primary"
        fullWidth
        sx={{mb: 2}}
        onChange={handleSiretChange}
        onKeyDown={e => e.stopPropagation()}
      />

      <Typography sx={{fontSize: 13, mb: 1, fontWeight: 'bold'}}>
        Pour récupérer votre clé d'API, aller dans Mon profil > Clés d'API > Sélectionner un service* > Donner un nom > Générer la clé d'API.
      </Typography>

      <Typography sx={{fontSize: 13, mb: 2, fontWeight: 'bold'}}>Copier/Coller l'identifiant et le secret générés dans les champs ci-dessous.</Typography>

      <PasswordInputRevealable
        error={createProjectApiKeyError !== ''}
        required
        size="small"
        value={username}
        color="primary"
        label="Identifiant"
        fullWidth
        sx={{mb: 2}}
        onChange={e => {
          setUsername(e.target.value);
          setCreateProjectApiKeyError('');
        }}
      />

      <PasswordInputRevealable
        error={createProjectApiKeyError !== ''}
        helperText={createProjectApiKeyError}
        required
        size="small"
        value={password}
        color="primary"
        label="Secret"
        fullWidth
        onChange={e => {
          setPassword(e.target.value);
          setCreateProjectApiKeyError('');
        }}
      />

      <Typography sx={{fontSize: 12, mt: 2}}>* Webapis CPA si vous êtes un cabinet d'expertise-comptable, Webapis - Quadra Entreprise si vous êtes une entreprise</Typography>
    </>
  );
};

CegidQuadraConnectorForm.propTypes = {
  cegidFolderId: string.isRequired,
  setCegidFolderId: func.isRequired,
  siret: string.isRequired,
  setSiret: func.isRequired
};

export default CegidQuadraConnectorForm;

import {Grid} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import {bool, string} from 'prop-types';

import Spinner from '../spinner/Spinner';

const CustomDimmer = ({isOpen, spinnerText}) => {
  if (!isOpen) {
    return null;
  }

  const backgroundColor = '#FFF';

  return (
    <Box
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <Backdrop
        sx={{
          position: 'absolute',
          opacity: '0.90 !important',
          background: backgroundColor,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
        open
      >
        <Grid container alignItems="center">
          <Spinner isLoading text={spinnerText} />
        </Grid>
      </Backdrop>
    </Box>
  );
};

CustomDimmer.propTypes = {
  isOpen: bool.isRequired,
  spinnerText: string.isRequired
};

export default CustomDimmer;

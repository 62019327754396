import {Grid, Typography} from '@mui/material';

import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button';

const SharedDataBlock = () => {
  const {shouldShareAnonymizedData, setIsSummaryShareDataModalOpen} = useReportSummary();

  const spanColor = shouldShareAnonymizedData ? 'success.main' : 'warning.main';
  const dataTypeLabel = shouldShareAnonymizedData ? 'anonymisées' : 'complètes';

  return (
    <Grid item mb={2}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography fontFamily="InstrumentSansBold" fontSize={15}>
          Données{' '}
          <Typography component="span" color={spanColor} fontFamily="inherit" fontSize="inherit">
            {dataTypeLabel}
          </Typography>{' '}
          partagées
        </Typography>
        <Grid item xs={3}>
          <Button onClick={() => setIsSummaryShareDataModalOpen(true)} fullWidth size="small" variant="outlined">
            Modifier
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SharedDataBlock;

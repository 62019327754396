import {FormControlLabel, Radio} from '@mui/material';
import {bool, number, oneOfType, string} from 'prop-types';

const CustomRadio = ({checked, value, label, size = 'small'}) => {
  return (
    <FormControlLabel
      sx={{
        '& .MuiFormControlLabel-label': {
          fontSize: 14
        }
      }}
      value={value}
      control={<Radio size={size} checked={checked} />}
      label={label}
    />
  );
};

CustomRadio.defaultProps = {
  size: 'small'
};

CustomRadio.propTypes = {
  checked: bool.isRequired,
  label: string.isRequired,
  size: string,
  value: oneOfType([string, number, bool]).isRequired
};

export default CustomRadio;

import {node} from 'prop-types';

import {PROJECTS_VIEW_TYPES} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';

const ProjectActionButton = ({cardMarkup, listMarkup}) => {
  const {viewType} = useProjects();

  if (!cardMarkup || !listMarkup) {
    // eslint-disable-next-line no-console
    console.error('ProjectActionButton: Both cardMarkup and listMarkup props are required.');
    return null;
  }

  return viewType === PROJECTS_VIEW_TYPES.list ? listMarkup : cardMarkup;
};

ProjectActionButton.propTypes = {
  cardMarkup: node.isRequired,
  listMarkup: node.isRequired
};

export default ProjectActionButton;

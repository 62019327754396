import React from 'react';

import {Grid, Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';

import {CHATBOT_MESSAGES_ROLES} from '../../const';
import useChatbot from '../../hooks/providers/useChatbot';
import useProfile from '../../hooks/providers/useProfile';
import DefaultAvatar from '../../img/default-avatar.png';
import {messageShape} from '../../proptypes-shapes';
import AssistantAnswerState from './AssistantAnswerState/AssistantAnswerState';
import AssistantPictureTooltip from './AssistantPictureTooltip';
import CsvFileMessage from './CsvFileMessage';
import MarkdownText from './MarkdownText';

// eslint-disable-next-line complexity
const Message = ({message}) => {
  const theme = useTheme();
  const {profile} = useProfile();
  const {assistants, isStreamingMessage} = useChatbot();

  const isMessageMine = message.role === CHATBOT_MESSAGES_ROLES.user;

  const messageBackgroundColor = isMessageMine ? '#fff' : theme.palette.primary.light;
  const justifyContent = isMessageMine ? 'flex-end' : 'flex-start';
  const textAlign = isMessageMine ? 'right' : 'left';
  const messageContent = message.content[0]?.text.value || '';
  const messageContentPaddingLeft = isMessageMine ? 6 : 0;
  const pictureFlexOrder = isMessageMine ? 1 : 0;

  const getMessageAuthorName = () => {
    if (isMessageMine) {
      return profile?.givenName;
    }
    const assistant = assistants.find(a => a.id === message.assistant_id);
    return assistant.name;
  };
  const messageAuthor = getMessageAuthorName();
  const assistant = assistants.find(a => a.id === message.assistant_id);
  const assistantHasJustAnsweredNow = isStreamingMessage && !isMessageMine;

  const formattedContent = messageContent.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <Grid container justifyContent={justifyContent} spacing={1} sx={{background: messageBackgroundColor}} pt={1} pb={2.5} px={1} mb={1}>
      <Grid item xs="auto" sx={{order: pictureFlexOrder}}>
        {isMessageMine ? <Box sx={{maxWidth: 40}} component="img" src={DefaultAvatar} /> : <AssistantPictureTooltip assistant={assistant} />}
      </Grid>

      <Grid item xs={10} pb={1} display="flex" alignItems={isMessageMine ? 'flex-end' : 'flex-start'} flexDirection="column">
        <Typography textAlign={textAlign} color="primary" fontFamily="SoehneBreitKraftig" fontSize={15} mb={1} mt={1.5}>
          {messageAuthor}
          {assistantHasJustAnsweredNow && <AssistantAnswerState stateText="écrit" />}
        </Typography>

        <Box
          sx={{
            textAlign,
            fontFamily: 'InstrumentSans',
            fontSize: 14,
            pl: messageContentPaddingLeft,
            lineHeight: 1.5,
            whiteSpace: 'pre-line' // Ensure white-space handling
          }}
        >
          {isMessageMine ? formattedContent : <MarkdownText message={messageContent} />}
        </Box>

        {/* This is used to display all csv files attached to a message (when they are returned by API) */}
        {message.files?.length > 0 && (
          <Grid container justifyContent="flex-end">
            {message.files?.map((file, index) => (
              <Grid item key={index} mt={1} ml={1}>
                <CsvFileMessage size="small" filename={file.filename} />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

Message.propTypes = {
  message: messageShape.isRequired
};

export default Message;

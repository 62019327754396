import {DeleteForever} from '@mui/icons-material';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';
import {projectShape} from '../../../../proptypes-shapes';
import ProjectActionButton from '../ProjectActionButton';
import ProjectMenuDeleteButton from './ProjectMenuDeleteButton';

const DeleteProjectButton = ({project}) => {
  const {setDeleteProjectModalOpen} = useProjects();
  const {openModal} = useProjectCrudModal({setModalOpen: setDeleteProjectModalOpen, project});

  return <ProjectActionButton cardMarkup={<ProjectMenuDeleteButton />} listMarkup={<DeleteForever sx={{cursor: 'pointer'}} fontSize="small" color="error" onClick={openModal} />} />;
};

DeleteProjectButton.defaultProps = {
  project: null
};

DeleteProjectButton.propTypes = {
  project: projectShape
};

export default DeleteProjectButton;

import {useState} from 'react';

import dayjs from 'dayjs';

const getInstallPromptLastSeenAt = promptName => localStorage.getItem(promptName);

const setInstallPromptSeenToday = promptName => {
  const today = dayjs().toISOString();
  localStorage.setItem(promptName, today);
};

function getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain) {
  const lastPrompt = dayjs(getInstallPromptLastSeenAt(promptName));
  const daysSinceLastPrompt = dayjs().diff(lastPrompt, 'day');
  return Number.isNaN(daysSinceLastPrompt) || daysSinceLastPrompt > daysToWaitBeforePromptingAgain;
}

const useShouldShowPrompt = (promptName, daysToWaitBeforePromptingAgain = 30) => {
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain));

  const handleUserSeeingInstallPrompt = () => {
    setUserShouldBePromptedToInstall(false);
    setInstallPromptSeenToday(promptName);
  };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};

export default useShouldShowPrompt;

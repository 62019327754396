import {useEffect, useState} from 'react';

import {Delete} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import Button from '../components/form/buttons/Button';
import DeleteProfileModal from '../components/profile/DeleteProfileModal/DeleteProfileModal';
import ProfileForm from '../components/profile/ProfileForm';
import CustomSnackbar from '../components/snackbar/Snackbar';
import Spinner from '../components/spinner/Spinner';
import {APP_ROUTES} from '../const';
import useAuth from '../hooks/providers/useAuth';
import useProfile from '../hooks/providers/useProfile';
import useSnackbar from '../hooks/providers/useSnackbar';

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0 0 0;
  margin: 0;
  background-color: ${process.env.REACT_APP_MAIN_COLOR};
  width: 100%;
  min-height: 100vh;
`;

const FormContainer = styled.div`
  max-width: 1200px;
  margin: 48px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 10px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 32px;
`;

const PageTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: #fff;
  font-family: 'SoehneBreitKraftig';
  font-weight: bold;
`;

const PageSubTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin: 12px auto;
  font-family: 'SoehneBreitKraftig';
  font-weight: 500;
`;

const SpinnerContainer = styled.div`
  margin-top: 32px;
`;

const Subscribe = () => {
  const auth = useAuth();
  const profileContext = useProfile();
  const snackbarContext = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const userHasCheckedAllConsents = profileContext.consents.filter(c => c?.consent_value).length >= 2;
      if (auth?.user?.tokenAad && !userHasCheckedAllConsents) {
        setIsLoading(true);
        await profileContext.fetchProfile();
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const userHasCheckedConsents = profileContext.consents && profileContext.consents.filter(c => c.consent_value).length >= 2;
    // const shouldRedirectToAuthenticatedApp = userHasCheckedConsents && !profileContext.consents.find(c => c.usermail === null);
    if (userHasCheckedConsents) {
      navigate(APP_ROUTES.projects);
    }
  }, [profileContext?.consents, isLoading]);

  const deleteProfile = async () => {
    await profileContext.deleteProfile();
  };

  return (
    <Container>
      <CustomSnackbar text={snackbarContext.message} isOpen={snackbarContext.isOpen} severity={snackbarContext.severity} duration={snackbarContext.duration} onClose={snackbarContext.closeSnackbar} />
      <PageTitle>Merci, votre inscription est presque terminée !</PageTitle>
      <PageSubTitle>Il nous faut encore quelques informations supplémentaires sur vous...</PageSubTitle>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner textStyle={{color: '#FFF'}} style={{margin: '12px auto auto auto'}} text="Chargement de votre profil" isLoading />
        </SpinnerContainer>
      ) : (
        <FormContainer>
          <ProfileForm isSubscribe />
          <Button style={{marginTop: '18px'}} onClick={() => setIsDeleteOpen(true)} variant="outlined" color="error" startIcon={<Delete />}>
            Annuler mon inscription
          </Button>
        </FormContainer>
      )}
      <DeleteProfileModal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)} deleteProfile={deleteProfile} />
    </Container>
  );
};

export default Subscribe;

import {useEffect, useMemo, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS} from '../const';
import PaymentContext from '../contexts/PaymentContext';
import useHttp from '../hooks/misc/useHttp';

const PaymentProvider = ({children}) => {
  const [hasMembership, setHasMembership] = useState(false);
  const [productSubscribed, setProductSubscribed] = useState(null);
  const [bills, setBills] = useState(null);
  const [products, setProducts] = useState([]);
  const [getUserSubscriptionsErrorMessage, setGetUserSubscriptionsErrorMessage] = useState('');
  const [getUserBillsErrorMessage, setGetUserBillsErrorMessage] = useState('');
  const [getProductsListErrorMessage, setGetProductsListErrorMessage] = useState('');
  const [updateSubscriptionErrorMessage, setUpdateSubscriptionErrorMessage] = useState('');

  const [authorizationsLoaded, setAuthorizationsLoaded] = useState(false);
  const [authorizationsLoading, setAuthorizationsLoading] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasTrialEnded, setHasTrialEnded] = useState(false);
  const [maxNumberOfProjects, setMaxNumberOfProjects] = useState(0);
  const [numberOfProjectsLeft, setNumberOfProjectsLeft] = useState(0);
  const [numberOfTrialDaysLeft, setNumberOfTrialDaysLeft] = useState(0);
  const [projectsOwnedByUser, setProjectsOwnedByUser] = useState([]);
  const [hasBeenSubscribedOnce, setHasBeenSubscribedOnce] = useState(false);
  const [numberOfProjectsOwnedByUser, setNumberOfProjectsOwnedByUser] = useState(0);
  const [authorizedModules, setAuthorizedModules] = useState([]);
  const [newProductAfterDowngrading, setNewProductAfterDowngrading] = useState(null);
  const [isDowngradingSubscription, setIsDowngradingSubscription] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
  const [userSubscriptionsFetched, setUserSubscriptionsFetched] = useState(false);

  const {_post, _get} = useHttp();

  const getUserSubscriptions = async () => {
    if (userSubscriptionsFetched) return;
    setGetUserSubscriptionsErrorMessage('');
    try {
      const url = API_ENDPOINTS.payment.get;
      const {response, responseJson: data} = await _get(url);

      if (response.status === 200) {
        setUserSubscriptionsFetched(true);
        const subscriptions = data.message;
        if (subscriptions.length > 0) {
          setHasMembership(true);
          setProductSubscribed(subscriptions[0]);
        }
        setIsDowngradingSubscription(data.downgrade_in_progress);
        setNewProductAfterDowngrading(data.new_product_name);
        return {
          status: 200
        };
      }
      throw Error(data.message ? data.message : data.error.message);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      setHasMembership(false);
      setProductSubscribed(null);
      setGetUserSubscriptionsErrorMessage(e.message);
      return {
        message: e.message
      };
    }
  };

  const getUserBills = async () => {
    setGetUserBillsErrorMessage('');
    try {
      const url = API_ENDPOINTS.payment.bills;
      const {response, responseJson: data} = await _get(url);

      if (response.status === 200) {
        const userBills = data;
        if (userBills.length > 0) {
          setBills(userBills);
        }
        return {
          status: 200
        };
      }
      throw Error(data?.message || data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setGetUserBillsErrorMessage(e.message);
      return {
        message: e.message
      };
    }
  };

  const getPaymentLink = async (productPriceId, customerEmail, baseUrl) => {
    try {
      const url = API_ENDPOINTS.payment.create;
      const {response, responseJson: data} = await _post(url, {
        base_url: baseUrl,
        price_id: productPriceId
      });

      if (response.status === 200) {
        return {
          status: 200,
          data
        };
      }
      throw Error(data?.message || data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      return {
        message: e.message
      };
    }
  };

  const getProductsList = async () => {
    setGetProductsListErrorMessage('');
    setProductsLoading(true);
    try {
      const url = API_ENDPOINTS.payment.products;
      const {response, responseJson: data} = await _get(url);

      if (response.status === 200) {
        setProducts(data);
        return {
          status: 200,
          data
        };
      }
      throw Error(data?.message || data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setGetProductsListErrorMessage(e.message);
      return {
        message: e.message
      };
    } finally {
      setProductsLoading(false);
    }
  };

  const updateSubscription = async (subscriptionId, newProductPriceId) => {
    setUpdateSubscriptionErrorMessage('');
    try {
      const url = API_ENDPOINTS.payment.update;
      const {response, responseJson: data} = await _post(url, {
        subscription_id: subscriptionId,
        new_price_id: newProductPriceId
      });

      if (response.status === 200) {
        return {
          status: 200,
          data
        };
      }
      throw Error(data?.message || data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setUpdateSubscriptionErrorMessage(e.message);
      return {
        message: e.message
      };
    }
  };

  const isTrialDatePassed = frenchFormattedDate => {
    const [day, month, year] = frenchFormattedDate.split('/');
    const standardDate = new Date(+year, +month - 1, +day);

    return new Date(standardDate) < new Date();
  };

  const getNumberOfDaysLeftInTrial = frenchFormattedDate => {
    const [day, month, year] = frenchFormattedDate.split('/');
    const standardDate = new Date(+year, +month - 1, +day);
    const diffTime = Math.abs(new Date() - standardDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  // eslint-disable-next-line complexity,consistent-return
  const getUserSubscriptionStatus = async () => {
    if (!authorizationsLoaded && !authorizationsLoading) {
      setAuthorizationsLoading(true);
      try {
        const url = API_ENDPOINTS.payment.authorizations;
        const {response, responseJson: data} = await _get(url);

        if (response.status === 200) {
          setAuthorizationsLoaded(true);
          setAuthorizedModules(data.authorized_modules ?? []);
          setIsTrial(data?.is_trial);
          setIsAdmin(data?.is_admin);
          setProjectsOwnedByUser(data.current_projects_details ?? []);
          setMaxNumberOfProjects(data?.project_max_qty);
          const projectsLeft = (data?.project_max_qty || 0) - (data?.current_projects_details?.length || 0);
          setNumberOfProjectsLeft(projectsLeft);

          const trialEnded = isTrialDatePassed(data?.end_free_trial);
          setHasTrialEnded(trialEnded);
          setHasBeenSubscribedOnce(data?.has_been_subscribed_once);
          if (!trialEnded) {
            const daysLeft = getNumberOfDaysLeftInTrial(data?.end_free_trial);
            setNumberOfTrialDaysLeft(daysLeft);
          }

          return {
            status: 200,
            data
          };
        }
        throw Error(data?.message || data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setAuthorizationsLoaded(true);
        return {
          message: e.message
        };
      } finally {
        setAuthorizationsLoading(false);
      }
    }
  };

  useEffect(() => {
    const newNumberOfProjectsOwnedByUser = projectsOwnedByUser.reduce((acc, p) => {
      return acc + p.numberOfProjects;
    }, 0);
    setNumberOfProjectsOwnedByUser(newNumberOfProjectsOwnedByUser);
  }, [projectsOwnedByUser]);

  const useMemoDeps = [
    getUserSubscriptions,
    getUserSubscriptionsErrorMessage,
    hasMembership,
    productSubscribed,
    getPaymentLink,
    bills,
    getUserBills,
    getUserBillsErrorMessage,
    products,
    getProductsList,
    getProductsListErrorMessage,
    updateSubscription,
    updateSubscriptionErrorMessage,
    getUserSubscriptionStatus,
    isTrial,
    hasTrialEnded,
    maxNumberOfProjects,
    numberOfProjectsLeft,
    numberOfTrialDaysLeft,
    projectsOwnedByUser,
    hasBeenSubscribedOnce,
    numberOfProjectsOwnedByUser,
    setNumberOfProjectsOwnedByUser,
    setMaxNumberOfProjects,
    isDowngradingSubscription,
    newProductAfterDowngrading,
    authorizedModules,
    isAdmin,
    productsLoading,
    isSubscriptionLoading,
    setIsSubscriptionLoading,
    authorizationsLoaded
  ];

  const value = useMemo(
    () => ({
      getUserSubscriptions,
      getUserSubscriptionsErrorMessage,
      hasMembership,
      productSubscribed,
      getPaymentLink,
      bills,
      getUserBills,
      getUserBillsErrorMessage,
      products,
      getProductsList,
      getProductsListErrorMessage,
      updateSubscription,
      updateSubscriptionErrorMessage,
      getUserSubscriptionStatus,
      isTrial,
      // isTrial: true,
      hasTrialEnded,
      maxNumberOfProjects,
      numberOfProjectsLeft,
      numberOfTrialDaysLeft,
      projectsOwnedByUser,
      hasBeenSubscribedOnce,
      setProjectsOwnedByUser,
      numberOfProjectsOwnedByUser,
      setNumberOfProjectsOwnedByUser,
      setMaxNumberOfProjects,
      isDowngradingSubscription,
      newProductAfterDowngrading,
      authorizedModules,
      authorizationsLoading,
      isAdmin,
      productsLoading,
      isSubscriptionLoading,
      setIsSubscriptionLoading,
      setGetUserSubscriptionsErrorMessage,
      setGetProductsListErrorMessage,
      authorizationsLoaded
    }),
    useMemoDeps
  );

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
};

PaymentProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default PaymentProvider;

import {colors} from '@mui/material';
import {arrayOf, shape, oneOfType, string, number, func, bool} from 'prop-types';
import ReactSelect from 'react-select';

const selectStyle = {
  control: baseStyles => ({
    ...baseStyles,
    background: 'transparent',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      outline: 'none'
    }
  }),
  menu: baseStyles => ({
    ...baseStyles,
    zIndex: 99
  }),
  placeholder: (baseStyles, {isDisabled}) => ({
    ...baseStyles,
    color: isDisabled ? colors.grey[400] : '#FFFFFF'
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    color: '#FFFFFF',
    height: '18px'
  }),
  input: (baseStyles, {isDisabled}) => ({
    ...baseStyles,
    color: '#FFFFFF',
    cursor: isDisabled ? 'not-allowed' : 'default'
  })
};

const Select = ({options, value, onChange, isDisabled}) => (
  <ReactSelect isDisabled={isDisabled} options={options} value={value} onChange={onChange} styles={selectStyle} placeholder="Sélectionner un rapport..." />
);

Select.defaultProps = {
  options: [],
  value: null,
  isDisabled: false
};

Select.propTypes = {
  options: arrayOf(shape({value: oneOfType([string, number]), label: string})),
  value: oneOfType([string, number, shape({})]),
  onChange: func.isRequired,
  isDisabled: bool
};

export default Select;

import {useEffect, useState} from 'react';

import {TextField, DialogContent, DialogActions, Grid} from '@mui/material';
import Box from '@mui/material/Box';
import 'dayjs/locale/fr';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../../const';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import Alert from '../../alert/Alert';
import Button from '../../form/buttons/Button';
import Checkbox from '../../form/Checkbox';
import BaseModal from '../../modal/BaseModal';
import ChoseNewPrompteTitleModal from './ChoseNewPromptTitleModal';
import PromptsSelect from './PromptsSelect';

const SummaryPromptInstructionsModal = () => {
  const {
    setIsSummaryInstructionsModalOpen,
    isSummaryInstructionsModalOpen: isOpen,
    shouldCustomizeInstructions,
    setShouldCustomizeInstructions,
    getSummaryPromptModels,
    promptModels,
    setChosenModel,
    instructions,
    setInstructions,
    promptModel
  } = useReportSummary();

  const [isLoading, setIsLoading] = useState(false);
  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);

  const closeModal = () => {
    setIsSummaryInstructionsModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (promptModels.length === 0) {
        setIsLoading(true);
        await getSummaryPromptModels();
        setIsLoading(false);
      }
    })();
  }, []);

  const handleConfirmInstructionsChoice = () => {
    setChosenModel(promptModel);
    closeModal();
  };

  const minimumNumberOrChars = 50;
  const maximumNumberOrChars = 8000;
  const hasInstructionsTextError = instructions !== '' && !REGULAR_EXPRESSIONS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars).test(instructions);
  const disabled = isLoading || !shouldCustomizeInstructions;

  const selectedPromptBeforeUpdate = promptModels.find(m => m.title === promptModel?.title);
  const hasSelectedModelBeenUpdated = selectedPromptBeforeUpdate && instructions !== selectedPromptBeforeUpdate?.prompt;

  const isSaveModelButtonDisabled = () => {
    if (isLoading || !shouldCustomizeInstructions || !instructions || hasInstructionsTextError) {
      return true;
    }

    if (promptModel && !hasSelectedModelBeenUpdated) {
      return true;
    }

    return false;
  };

  const saveModelButtonDisabled = isSaveModelButtonDisabled();

  return (
    <BaseModal maxWidth="md" onClose={closeModal} open={isOpen} title="Instructions">
      <DialogContent>
        <Grid mb={2} container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Checkbox label="Personnaliser les instructions" checked={shouldCustomizeInstructions} onChange={e => setShouldCustomizeInstructions(e.target.checked)} />
          </Grid>

          <Grid item xs={12} sm sx={{mt: {xs: 1}}}>
            <PromptsSelect disabled={disabled} />
          </Grid>
        </Grid>

        {hasSelectedModelBeenUpdated ? (
          <Box mb={2}>
            <Alert severity="info">
              Vous avez sélectionné le modèle "{promptModel.title}" mais vous y avez apporté des modifications. Les instructions seront générées à partir de cette version modifiée.
            </Alert>
          </Box>
        ) : null}

        <TextField
          variant={disabled ? 'filled' : 'outlined'}
          disabled={disabled}
          autoFocus
          multiline
          rows={10}
          sx={{mb: 2}}
          required
          value={instructions}
          onChange={event => {
            setInstructions(event.target.value);
          }}
          label="Instructions"
          placeholder="Renseigner vos instructions..."
          color="primary"
          fullWidth
          error={hasInstructionsTextError}
          helperText={hasInstructionsTextError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars) : ''}
        />
      </DialogContent>

      <DialogActions px={3} sx={{justifyContent: 'flex-start', width: 'auto'}}>
        <Button disabled={saveModelButtonDisabled} variant="contained" color="success" onClick={() => setIsTitleModalOpen(true)}>
          Sauvegarder en tant que modèle
        </Button>
        {shouldCustomizeInstructions ? (
          <Button disabled={hasInstructionsTextError} variant="contained" color="secondary" onClick={handleConfirmInstructionsChoice}>
            Utiliser ces instructions
          </Button>
        ) : (
          <Button onClick={closeModal} variant="outlined" color="error">
            Fermer
          </Button>
        )}
      </DialogActions>

      <ChoseNewPrompteTitleModal instructions={instructions} isOpen={isTitleModalOpen} onClose={() => setIsTitleModalOpen(false)} />
    </BaseModal>
  );
};

export default SummaryPromptInstructionsModal;

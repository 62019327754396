import {createTheme, Alert, ThemeProvider} from '@mui/material';
import {func, node, oneOfType, shape, string} from 'prop-types';

import parentTheme from '../../styles/mui-theme';

const theme = createTheme({
  ...parentTheme,
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          color: 'white'
        },
        message: {
          display: 'flex',
          alignItems: 'baseline',
          color: 'white'
        }
      }
    }
  }
});

const CustomAlert = ({children, variant, severity, sx, icon}) => {
  return (
    <ThemeProvider theme={theme}>
      <Alert sx={sx} variant={variant} severity={severity} icon={icon}>
        {children}
      </Alert>
    </ThemeProvider>
  );
};

CustomAlert.defaultProps = {
  sx: {},
  variant: 'filled',
  severity: 'success',
  icon: null
};

CustomAlert.propTypes = {
  icon: node,
  children: oneOfType([node, func]).isRequired,
  sx: shape({}),
  variant: string,
  severity: string
};

export default CustomAlert;

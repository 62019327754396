import {Divider, Typography} from '@mui/material';
import {string} from 'prop-types';

const ConversationEventSubtitle = ({text}) => {
  return (
    <>
      <Divider width={45} />
      <Typography color="text.primary" fontSize={12} mx={1}>
        {text}
      </Typography>
      <Divider width={45} />
    </>
  );
};

ConversationEventSubtitle.propTypes = {
  text: string.isRequired
};

export default ConversationEventSubtitle;

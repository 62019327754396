import {useEffect, useState} from 'react';

import {Delete} from '@mui/icons-material';
import {Box, Grid, Paper} from '@mui/material';
import Card from '@mui/material/Card';
import styled from 'styled-components';

import Button from '../components/form/buttons/Button';
import PageContainer from '../components/layout/PageContainer';
import DeleteProfileModal from '../components/profile/DeleteProfileModal/DeleteProfileModal';
import Membership from '../components/profile/Membership';
import ProfileForm from '../components/profile/ProfileForm';
import Spinner from '../components/spinner/Spinner';
import PageTitle from '../components/typography/PageTitle';
import {STRIPE_PORTAL_URLS} from '../const';
import useAuth from '../hooks/providers/useAuth';
import usePayment from '../hooks/providers/usePayment';
import useProfile from '../hooks/providers/useProfile';
import useProjects from '../hooks/providers/useProjects';

const LinkToStripePortal = styled.a`
  text-decoration: none;
`;

const Profile = () => {
  const auth = useAuth();
  const paymentContext = usePayment();
  const projectsContext = useProjects();
  const profileContext = useProfile();
  const {isSubscriptionLoading} = paymentContext;

  const linkToStripePortal = STRIPE_PORTAL_URLS[process.env.REACT_APP_DB_ENV];

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (auth?.user?.tokenAad) {
        setIsLoading(true);
        await profileContext.fetchProfile();
        await profileContext.fetchConsents(false);
        setIsLoading(false);
      }
    })();
  }, []);

  const deleteProfile = async () => {
    await profileContext.deleteProfile();
  };

  // Business rule : if user is invited on a project, he cannot manage membership, subscription ect...
  // Exception: demo is activated, he can see this component event if he's invited (on demo projects).
  // Businesss rule : we only activate membership for Drivn users, not for partners.
  const shouldDisplayMembership = (!projectsContext.isGuestMode || paymentContext.isDemoActivated) && process.env.REACT_APP_IS_SUBSCRIPTION_PAGE_ACTIVATED === 'true';

  return (
    <PageContainer>
      <Box sx={{alignSelf: 'flex-start', mb: 5}}>
        <PageTitle>Gérer mon compte</PageTitle>
      </Box>

      {isLoading ? (
        <Grid container flex={1} justifyContent="center">
          <Card elevation={3} sx={{display: 'flex', flex: 1}}>
            <Spinner text="Chargement de votre profil" isLoading />
          </Card>
        </Grid>
      ) : (
        <Grid container columnSpacing={4}>
          {shouldDisplayMembership && (
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{p: 2}}>
                <Membership isLoading={isSubscriptionLoading} />
              </Paper>
              {!isSubscriptionLoading && (
                <Grid item>
                  <LinkToStripePortal href={linkToStripePortal} target="_blank">
                    <Button sx={{display: 'flex', mt: 4, mx: 'auto'}} variant="outlined" color="primary">
                      Gérer mon abonnement
                    </Button>
                  </LinkToStripePortal>
                </Grid>
              )}
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={shouldDisplayMembership ? 8 : 12}
            sx={{
              paddingTop: {
                xs: 3,
                md: 0
              }
            }}
          >
            <Grid container direction="column">
              <Paper elevation={3} sx={{p: 2}}>
                <ProfileForm />
              </Paper>
              <Grid item xs={12} md={8}>
                <Button sx={{display: 'flex', mt: 4, mx: 'auto'}} onClick={() => setIsDeleteOpen(true)} variant="outlined" color="error" startIcon={<Delete />}>
                  Supprimer mon compte
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <DeleteProfileModal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)} deleteProfile={deleteProfile} />
        </Grid>
      )}
    </PageContainer>
  );
};

export default Profile;

import {Grid, Typography} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import styled from 'styled-components';

import useAuth from '../../hooks/providers/useAuth';
import Button from '../form/buttons/Button';

const Container = muiStyled(Grid)(() => ({
  position: 'relative',
  background: '#90A8FC',
  borderRadius: 10
}));

const Title = muiStyled(Typography)(({theme}) => ({
  font: 'normal normal bold 24px/26px SoehneBreitKraftig',
  color: '#FFFFFF',
  marginBottom: theme.spacing(3)
}));

const List = styled.ul`
  color: white;
  font: normal normal normal 16px InstrumentSans;
  line-height: 1.5;
  list-style-type: disc;
  padding: 0 20px;
`;

const WhiteLabelCTA = () => {
  const auth = useAuth();

  return (
    <Container maxWidth="md" container py={4} px={5}>
      <Title>DeFi Premium - Optez pour la marque blanche maintenant</Title>

      <Grid container alignItems="center">
        <Grid item flex={1}>
          <List>
            <li>Tarification au projet</li>
            <li>Site web et applications mobiles à vos couleurs</li>
            <li>Base de données dédiée</li>
            <li>Envoi mail marque blanche (user@moncabinet.fr)</li>
          </List>
        </Grid>

        <Grid item>
          <Button fullWidth size="large" color="white" variant="contained" onClick={() => auth.setIsContactFormOpen(true)}>
            Nous consulter
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhiteLabelCTA;

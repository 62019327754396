import {useEffect, useState} from 'react';

import {Button} from '@mui/material';

import usePayment from '../../hooks/providers/usePayment';
import useProjects from '../../hooks/providers/useProjects';
import useWorkspaces from '../../hooks/providers/useWorkspaces';

const EnableOrDisableDemoButton = () => {
  const [isEnablingOrDisablingDemoSubmitting, setIsEnablingOrDisablingDemoSubmitting] = useState(false);
  const {isGuestMode, enableOrDisableDemo, isDemoActivated, currentReport} = useProjects();
  const {isTrial, isAdmin} = usePayment();
  const {isDefaultWorkspace} = useWorkspaces();

  // Business rule: demo button only appears if the following conditions are fulfilled:
  // 1. User must not be in "guest mode" (user does not own any projects, he's only invited by other drivn users)
  // 2. User either is in free trial OR is an admin
  // 3. User must be in DeFi Workspace
  const shouldDisplayButton = !isGuestMode && (isTrial || isAdmin) && isDefaultWorkspace;

  const handleClick = async () => {
    setIsEnablingOrDisablingDemoSubmitting(true);
    await enableOrDisableDemo(!isDemoActivated);
    setIsEnablingOrDisablingDemoSubmitting(false);
  };

  // This hook aims to automatically disable demo if it was activated when user trial is done (and if current user isn't admin)
  // It also fetches stripe products
  useEffect(() => {
    (async () => {
      if (!isTrial && isDemoActivated && !isAdmin) {
        await enableOrDisableDemo(!isDemoActivated);
      }
    })();
  }, [isAdmin, isTrial]);

  if (shouldDisplayButton) {
    return (
      <Button disabled={isEnablingOrDisablingDemoSubmitting} onClick={handleClick} sx={{textDecoration: 'underline'}} size="small" variant="text">
        {isDemoActivated ? 'Désactiver' : 'Activer'} la démo {currentReport.report_name}
      </Button>
    );
  }

  return null;
};

export default EnableOrDisableDemoButton;

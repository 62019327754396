import {useEffect, useState} from 'react';

import {TabContext} from '@mui/lab';
import {DialogActions, DialogContent, Grid} from '@mui/material';
import Box from '@mui/material/Box';

import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button';
import Checkbox from '../../form/Checkbox';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import StyledTab from '../../tabs/StyledTab';
import StyledTabs from '../../tabs/StyledTabs';
import SummaryDataSpreadsheet from './SummaryDataSpreadsheet';

const MODAL_TABS = {
  anonymized: 'anonymized',
  fullData: 'fullData'
};

const SummarySharedDataModal = () => {
  const {summariesLoading, shouldShareAnonymizedData, setShouldShareAnonymizedData, isSummaryShareDataModalOpen: isOpen, setIsSummaryShareDataModalOpen: setIsOpen} = useReportSummary();

  const [userHasCheckedDataIsFullyAnonymized, setUserHasCheckedDataIsFullyAnonymized] = useState(false);
  const [tabSelected, setTabSelected] = useState(MODAL_TABS.anonymized);

  const isAnonymizedTab = tabSelected === MODAL_TABS.anonymized;
  const buttonText = isAnonymizedTab ? 'Partager les données anonymisées' : 'Partager les données complètes';

  const handleTabChange = () => {
    setTabSelected(currentTab => (currentTab === MODAL_TABS.anonymized ? MODAL_TABS.fullData : MODAL_TABS.anonymized));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChoseDataTypeButtonClick = () => {
    setShouldShareAnonymizedData(isAnonymizedTab);
    closeModal();
  };

  const submitButtonDisabled = !userHasCheckedDataIsFullyAnonymized && !isAnonymizedTab;

  // This hook aims to re-set the current selected data type on modal open.
  useEffect(() => {
    if (!isOpen) {
      setTabSelected(shouldShareAnonymizedData ? MODAL_TABS.anonymized : MODAL_TABS.fullData);
    }
  }, [isOpen]);

  return (
    <BaseModal shouldMatchEmbedReportWidth maxWidth={false} withoutTitle onClose={closeModal} open={isOpen} canCloseOnBackdropClick={false}>
      <TabContext value={tabSelected}>
        <StyledTabs onChange={handleTabChange} scrollButtons={false} aria-label="Onglets créer ou importer un rapport">
          <StyledTab label="Données anonymisées" value={MODAL_TABS.anonymized} />
          <StyledTab label="Données complètes" value={MODAL_TABS.fullData} />
        </StyledTabs>
      </TabContext>

      <DialogContent sx={{px: 3, py: 2, mt: 2}}>{summariesLoading ? <Spinner isLoading text="Chargement des données" /> : <SummaryDataSpreadsheet isAnonymizedData={isAnonymizedTab} />}</DialogContent>
      <DialogActions
        sx={{
          flexDirection: 'column',
          pt: 1,
          width: {
            xs: '100%'
          }
        }}
      >
        {!isAnonymizedTab ? (
          <Checkbox
            label="J'ai vérifié l'absence de données personnelles dans le tableau"
            checked={userHasCheckedDataIsFullyAnonymized}
            onChange={e => setUserHasCheckedDataIsFullyAnonymized(e.target.checked)}
          />
        ) : null}
        <Grid container width="auto" mr={2} alignItems="center" flexWrap="nowrap">
          <Button onClick={handleChoseDataTypeButtonClick} disabled={submitButtonDisabled} variant="contained" color="secondary">
            {buttonText}
          </Button>
          <Box ml={1}>
            <Button
              onClick={() => {
                closeModal();
              }}
              variant="outlined"
              color="error"
            >
              Fermer
            </Button>
          </Box>
        </Grid>
      </DialogActions>
    </BaseModal>
  );
};

export default SummarySharedDataModal;

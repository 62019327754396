const SEND_DOCUMENT_REQUEST_HEADER_ROW = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'Date pièce', className: 'header-cell'},
    {type: 'header', text: 'Journal', className: 'header-cell'},
    {type: 'header', text: 'Libellé', className: 'header-cell'},
    {type: 'header', text: 'Montant', className: 'header-cell'},
    {type: 'header', text: ' ', className: 'header-cell'}
  ]
};

const SEND_DOCUMENT_REQUEST_COLUMNS_NAMES = {
  date: 'date',
  journal: 'journal',
  label: 'label',
  amount: 'amount',
  delete: 'delete'
};

const getDocumentRequestColumns = () => {
  const defaultColumnWidth = 150;
  const deleteColumnWidth = 50;

  return Object.keys(SEND_DOCUMENT_REQUEST_COLUMNS_NAMES).map(c => ({
    resizable: true,
    columnId: c,
    width: c === SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.delete ? deleteColumnWidth : defaultColumnWidth
  }));
};

export {SEND_DOCUMENT_REQUEST_HEADER_ROW, SEND_DOCUMENT_REQUEST_COLUMNS_NAMES, getDocumentRequestColumns};

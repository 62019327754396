/* eslint-disable react/jsx-no-duplicate-props */
import {useEffect, useRef, useState} from 'react';

import SendIcon from '@mui/icons-material/SendOutlined';
import {InputAdornment, TextField} from '@mui/material';
import {useWindowSize} from '@uidotdev/usehooks';
import {func, string} from 'prop-types';

import useChatbot from '../../../hooks/providers/useChatbot';

const ChatbotMessageInput = ({message, setMessage, sendMessage}) => {
  const {isTypingDisabled, isSendingMessage: isSending, scrollConversationToBottom} = useChatbot();
  const textFieldRef = useRef();
  const isValid = message.trim().length > 0;
  const [displayedRows, setDisplayedRows] = useState(1);
  const windowSize = useWindowSize();

  const canSend = !isTypingDisabled && isValid && !isSending;

  // This hook aims to track input's current number of displayed rows
  useEffect(() => {
    const calculateDisplayedRows = () => {
      const textField = textFieldRef.current;
      if (textField) {
        const {scrollHeight} = textField;
        const lineHeight = parseFloat(getComputedStyle(textField).lineHeight);
        const newDisplayedRows = Math.floor(scrollHeight / lineHeight) - 1;
        setDisplayedRows(newDisplayedRows);
      }
    };

    calculateDisplayedRows();
    window.addEventListener('input', calculateDisplayedRows);

    return () => {
      window.removeEventListener('input', calculateDisplayedRows);
    };
  }, [message, windowSize.width]);

  // This hook triggers when displayed rows changes, to force the input to always be at the very bottom of the page
  useEffect(() => {
    scrollConversationToBottom();
  }, [displayedRows]);

  const inputMarginHorizontal = 10 + 5 * (displayedRows / 10);

  return (
    <TextField
      ref={textFieldRef}
      multiline
      disabled={isTypingDisabled || isSending}
      color="primary"
      size="small"
      maxRows={6}
      inputProps={{
        style: {
          margin: `0 ${inputMarginHorizontal}`
        }
      }}
      InputProps={{
        sx: {
          borderRadius: 30 / (displayedRows + 2),
          fontSize: 13
        },
        endAdornment: (
          <InputAdornment aria-disabled={!canSend} aria-label="bouton envoi message" position="start" sx={{cursor: !canSend ? 'not-allowed' : 'pointer'}} onClick={!canSend ? null : sendMessage}>
            <SendIcon aria-label="icone envoi message" color={!canSend ? 'disabled' : 'primary'} />
          </InputAdornment>
        )
      }}
      value={message}
      onChange={e => setMessage(e.target.value)}
      fullWidth
      placeholder="Rédigez votre message..."
      onKeyDown={async e => {
        if (e.key === 'Enter' && !e.shiftKey && isValid) {
          e.preventDefault();
          await sendMessage();
        }
      }}
    />
  );
};

ChatbotMessageInput.defaultProps = {
  message: null
};

ChatbotMessageInput.propTypes = {
  message: string,
  setMessage: func.isRequired,
  sendMessage: func.isRequired
};

export default ChatbotMessageInput;

import {useEffect} from 'react';
import './nested-menu-item.css';

import {Divider, Menu} from '@mui/material';

import useProjects from '../../hooks/providers/useProjects';
import useUpload from '../../hooks/providers/useUpload';
import {projectShape} from '../../proptypes-shapes';
import ActualizeDataMenu from './single-project-item/actualize-data-button/ActualizeDataMenu';
import ProjectMenuCustomizeMappingButton from './single-project-item/cutomize-mapping-button/ProjectMenuCustomizeMappingButton';
import ProjectMenuDeleteButton from './single-project-item/delete-button/ProjectMenuDeleteButton';
import ProjectMenuImportDataButton from './single-project-item/import-data-button/ProjectMenuImportDataButton';
import ProjectMenuManageUsersButton from './single-project-item/manage-users-button/ProjectMenuManageUsersButton';
import ProjectMenuUpdateButton from './single-project-item/update-button/ProjectMenuUpdateButton';

const ProjectCardMenu = ({project}) => {
  const {isUploadFilesModalOpen} = useUpload();
  const {projectCardMenuAnchorEl, selectedProject, closeProjectMenu} = useProjects();
  const anchorEl = projectCardMenuAnchorEl;

  const open = Boolean(anchorEl && selectedProject?.siren === project.siren);

  useEffect(() => {
    if (!isUploadFilesModalOpen) {
      closeProjectMenu();
    }
  }, [isUploadFilesModalOpen]);

  return (
    <Menu
      id="project-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      onClose={closeProjectMenu}
    >
      <ProjectMenuUpdateButton />

      <ProjectMenuManageUsersButton />

      <ProjectMenuImportDataButton />

      {open && <ActualizeDataMenu isNested />}

      <ProjectMenuCustomizeMappingButton />

      <Divider sx={{my: 0.5}} />
      <ProjectMenuDeleteButton />
    </Menu>
  );
};

ProjectCardMenu.propTypes = {
  project: projectShape.isRequired
};

export default ProjectCardMenu;

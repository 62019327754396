import Bowser from 'bowser';

import {BREAKPOINTS} from '../const';

const isDemoProject = p => {
  const demoSirens = [...Array(9)].map((_, i) => `10000000${i + 1}`);
  return demoSirens.includes(p.siren);
};

const isMobile = () => {
  const parser = Bowser.getParser(navigator.userAgent);
  return parser.getPlatformType() === 'mobile';
};

const isResponsive = currentBreakpoint => {
  const isSmallScreen = currentBreakpoint === BREAKPOINTS.xs || currentBreakpoint === BREAKPOINTS.sm;
  return isMobile() || isSmallScreen;
};

const capitalizeWord = word => {
  const firstLetterCap = word.charAt(0).toUpperCase();
  const remainingLetters = word.slice(1);
  const capitalizedWord = firstLetterCap + remainingLetters;

  return capitalizedWord;
};

export {isDemoProject, isMobile, isResponsive, capitalizeWord};

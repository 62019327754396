import {Grid, Typography} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

import {CONNECTORS, DEFAULT_PROJECTS_SCHEMAS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import useTiimeProjects from '../../../hooks/providers/useTiimeProjects';
import acdLogo from '../../../img/acd-logo.png';
import cegidQuadraLogo from '../../../img/cegid-quadra.png';
import myUnisoftLogo from '../../../img/my-unisoft.png';
import pennylaneLogo from '../../../img/pennylane.png';
import tiimeLogo from '../../../img/tiime-logo.png';
import vectronLogo from '../../../img/vectron.png';
import ConnectorBox from './ConnectorBox';

const ConnectorsListTitle = muiStyled(Typography)(({theme}) => ({
  fontSize: 18,
  fontWeight: 500,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  textAlign: 'center'
}));

const Connectors = () => {
  const {selectedReportTab, connector, setConnector} = useProjects();
  const {checkTiimeToken, tiimeCompanies} = useTiimeProjects();

  return (
    <>
      <ConnectorsListTitle>Choix du connecteur</ConnectorsListTitle>
      <Grid container spacing={1} justifyContent="center">
        {selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.gestion && (
          <>
            <ConnectorBox name="myUnisoft" setConnector={() => setConnector(CONNECTORS.unisoft)} selected={connector === CONNECTORS.unisoft} logo={myUnisoftLogo} />
            <ConnectorBox
              name="pennylane"
              setConnector={() => setConnector(CONNECTORS.pennylane)}
              selected={connector === CONNECTORS.pennylane}
              logo={pennylaneLogo}
              hasBetaRibbon
              ribbonColor="success"
              ribbonText="Beta"
            />
            <ConnectorBox name="Cegid Quadra" setConnector={() => setConnector(CONNECTORS.cegidQuadra)} selected={connector === CONNECTORS.cegidQuadra} logo={cegidQuadraLogo} />
            <ConnectorBox name="Acd" setConnector={() => setConnector(CONNECTORS.acd)} selected={connector === CONNECTORS.acd} logo={acdLogo} />
            <ConnectorBox
              hasBetaRibbon
              ribbonColor="success"
              ribbonText="Beta"
              name="Tiime"
              setConnector={async () => {
                setConnector(CONNECTORS.tiime);
                if (tiimeCompanies.length === 0) {
                  await checkTiimeToken();
                }
              }}
              selected={connector === CONNECTORS.tiime}
              logo={tiimeLogo}
            />
          </>
        )}

        {selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.z && (
          <ConnectorBox name="vectron" setConnector={() => setConnector(CONNECTORS.vectron)} selected={connector === CONNECTORS.vectron} logo={vectronLogo} />
        )}

        {(selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.gestion || process.env.REACT_APP_DB_ENV === 'dev') && (
          <ConnectorBox name="manual" setConnector={() => setConnector(CONNECTORS.manual)} selected={connector === CONNECTORS.manual} text="Importer manuellement" />
        )}
      </Grid>
    </>
  );
};

export default Connectors;

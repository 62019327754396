import {useMemo, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {SPINNER_TYPES} from '../const';
import LoadingContext from '../contexts/LoadingContext';

const LoadingProvider = ({children}) => {
  const [spinnerType, setSpinnerType] = useState(SPINNER_TYPES.RSE);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [completedApiCalls, setCompletedApiCalls] = useState(0);
  const [totalNumberOfApiCalls, setTotalNumberOfApiCalls] = useState(0);

  const useMemoDeps = [globalLoading, setGlobalLoading, completedApiCalls, setCompletedApiCalls, totalNumberOfApiCalls, setTotalNumberOfApiCalls, spinnerType, setSpinnerType];

  const value = useMemo(
    () => ({
      setGlobalLoading,
      globalLoading,
      completedApiCalls,
      setCompletedApiCalls,
      totalNumberOfApiCalls,
      setTotalNumberOfApiCalls,
      spinnerType,
      setSpinnerType
    }),
    useMemoDeps
  );

  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};

LoadingProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default LoadingProvider;

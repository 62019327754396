import {TextField} from '@mui/material';
import {bool, func} from 'prop-types';

import useProjects from '../../hooks/providers/useProjects';
import {getHumanReadableSiren} from '../../utils';

const SirenTextField = ({disabled, onChange, onKeyDown}) => {
  const defaultOnKeyDown = async e => {
    e.stopPropagation(); // see : https://github.com/mui/material-ui/issues/19116
  };

  const {createProjectSirenError, siren, isSirenValid} = useProjects();

  const getErrorText = () => {
    if (siren && !isSirenValid) return 'Siren invalide';
    if (createProjectSirenError) return createProjectSirenError;
    return '';
  };

  return (
    <TextField
      disabled={disabled}
      required
      error={(siren !== '' && !isSirenValid) || createProjectSirenError}
      helperText={getErrorText()}
      inputProps={{maxLength: 11, minLength: 11}}
      size="small"
      value={getHumanReadableSiren(siren).trim()}
      label="Siren"
      color="primary"
      fullWidth
      sx={{mb: 2}}
      onChange={onChange}
      onKeyDown={onKeyDown || defaultOnKeyDown}
    />
  );
};

SirenTextField.defaultProps = {
  onKeyDown: null
};

SirenTextField.propTypes = {
  disabled: bool.isRequired,
  onChange: func.isRequired,
  onKeyDown: func
};

export default SirenTextField;

import AccountTreeIcon from '@mui/icons-material/AccountTree';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjectsCustomMappings from '../../../../hooks/providers/useProjectsCustomMappings';
import {projectShape} from '../../../../proptypes-shapes';
import ProjectActionButton from '../ProjectActionButton';
import ProjectMenuCustomizeMappingButton from './ProjectMenuCustomizeMappingButton';

const CustomizeProjectMappingButton = ({project}) => {
  const {setCustomizeMappingModalOpen} = useProjectsCustomMappings();
  const {openModal} = useProjectCrudModal({setModalOpen: setCustomizeMappingModalOpen, project});

  return <ProjectActionButton cardMarkup={<ProjectMenuCustomizeMappingButton />} listMarkup={<AccountTreeIcon sx={{cursor: 'pointer'}} fontSize="small" onClick={openModal} />} />;
};

CustomizeProjectMappingButton.defaultProps = {
  project: null
};

CustomizeProjectMappingButton.propTypes = {
  project: projectShape
};

export default CustomizeProjectMappingButton;

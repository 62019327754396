const TRANSCRIPT_WORKING_DAY_SPREADSHEET_HEADER_ROW = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'DATE', className: 'header-cell'},
    {type: 'header', text: 'DOSSIER', className: 'header-cell'},
    {type: 'header', text: 'ACTION', className: 'header-cell'},
    {type: 'header', text: 'MISSION', className: 'header-cell'},
    {type: 'header', text: 'TÂCHE', className: 'header-cell'},
    {type: 'header', text: 'TEMPS EN HEURES', className: 'header-cell'},
    {type: 'header', text: ' ', className: 'header-cell'}
  ]
};

const TRANSCRIPT_WORKING_DAY_COLUMNS = {
  date: {name: 'date', width: 100},
  folder: {name: 'folder', width: 250},
  action: {name: 'action', width: 300},
  mission: {name: 'mission', width: 125},
  task: {name: 'task', width: 300},
  duration: {name: 'duration', width: 175},
  delete: {name: 'delete', width: 50}
};

const getTranscriptWorkingDaySpreadsheetColumns = () => {
  return Object.values(TRANSCRIPT_WORKING_DAY_COLUMNS).map(c => ({
    columnId: c.name,
    width: c.width
  }));
};

export {TRANSCRIPT_WORKING_DAY_SPREADSHEET_HEADER_ROW, TRANSCRIPT_WORKING_DAY_COLUMNS, getTranscriptWorkingDaySpreadsheetColumns};

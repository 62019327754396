import {useEffect, useState} from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Grid, Typography, Backdrop, Paper} from '@mui/material';
import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import ErrorComponent from '../components/error/ErrorComponent';
import Button from '../components/form/buttons/Button';
import PageContainer from '../components/layout/PageContainer';
import Link from '../components/link/Link';
import ProductCard from '../components/payment/ProductCard';
import ProductsSlider from '../components/payment/ProductsSlider';
import UpdateSubscriptionModal from '../components/payment/UpdateSubscriptionModal';
import WhiteLabelCTA from '../components/payment/WhiteLabelCTA';
import Spinner from '../components/spinner/Spinner';
import PageTitle from '../components/typography/PageTitle';
import {APP_ROUTES} from '../const';
import useAuth from '../hooks/providers/useAuth';
import usePayment from '../hooks/providers/usePayment';
import useProjects from '../hooks/providers/useProjects';
import paymentMeans from '../img/payment-means.jpg';
import {getFrenchFormattedDate} from '../utils';

const PageSubtitle = muiStyled(Typography)(({theme}) => ({
  textAlign: 'center',
  font: 'normal normal bold 25px/25px InstrumentSans',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4)
}));

const MeansOfPaymentText = muiStyled(Typography)(() => ({
  textAlign: 'center',
  font: 'normal normal normal 16px/25px InstrumentSans'
}));

const PaymentMeansImage = styled.img`
  max-height: 25px;
`;

const Products = () => {
  const [selectedNumberOfProjects, setSelectedNumberOfProjects] = useState(null);
  const [loadingGetPaymentLink, setLoadingGetPaymentLink] = useState(false);
  const [loadingGetPaymentLinkHidden, setLoadingGetPaymentLinkHidden] = useState(true);
  const [errorGettingPaymentLink, setErrorGettingPaymentLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [updateSubscriptionConfirmationModalOpen, setUpdateSubscriptionConfirmationModalOpen] = useState(false);
  const [newProductSubscribed, setNewProductSubscribed] = useState(null);

  const auth = useAuth();
  const {isContactFormOpen, setIsContactFormOpen} = auth;
  const paymentContext = usePayment();
  const {projects, fetchProjects, isGuestMode} = useProjects();
  const location = useLocation();
  const navigate = useNavigate();

  const productsMatchingSelectedNumberOfProjects = paymentContext.products?.filter(p => parseInt(p.numberOfProjects, 10) === selectedNumberOfProjects);
  productsMatchingSelectedNumberOfProjects?.sort((a, b) => a.amount - b.amount);

  const productWithMaximumNumberOfProjects = paymentContext.products.reduce((prev, current) => {
    return parseInt(prev.numberOfProjects, 10) >= parseInt(current.numberOfProjects, 10) ? prev : current;
  }, 0); // Provide an initialValue to prevent reducing an empty array (see: https://stackoverflow.com/questions/23359173/javascript-reduce-an-empty-array)
  const customMaximumNumberOfProjects = `${productWithMaximumNumberOfProjects.numberOfProjects}+`;

  const getPaymentLinkUrl = async productPriceId => {
    setErrorGettingPaymentLink('');
    setLoadingGetPaymentLink(true);
    setLoadingGetPaymentLinkHidden(false);
    const baseUrl = window.location.origin;
    const customerEmail = auth.user.mail;
    const result = await paymentContext.getPaymentLink(productPriceId, customerEmail, baseUrl);
    setLoadingGetPaymentLink(false);
    setTimeout(() => setLoadingGetPaymentLinkHidden(true), 1000);

    if (result.status === 200) {
      window.location.href = result.data;
    } else {
      setErrorGettingPaymentLink(result.message);
    }
  };

  const openUpdateSubscriptionModal = product => {
    setUpdateSubscriptionConfirmationModalOpen(true);
    setNewProductSubscribed(product);
  };

  const closeUpdateSubscriptionModal = () => {
    setUpdateSubscriptionConfirmationModalOpen(false);
    setNewProductSubscribed(null);
  };

  const updateCurrentSubscription = async (subscriptionId, productPriceId) => {
    return paymentContext.updateSubscription(subscriptionId, productPriceId);
  };

  const getPageContent = () => {
    const formattedDate = paymentContext.productSubscribed ? getFrenchFormattedDate(paymentContext.productSubscribed?.current_period_end, 'long') : '';
    return (
      <>
        {paymentContext.isDowngradingSubscription && !updateSubscriptionConfirmationModalOpen && (
          <Backdrop sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}} open={paymentContext.isDowngradingSubscription && !isContactFormOpen}>
            <Paper sx={{p: 5, maxWidth: 600, m: 1}} elevation={3}>
              <Typography variant="h5" fontWeight="bold">
                Modification d'abonnement en cours
              </Typography>
              <Typography fontWeight="bold" mt={3}>
                Votre plan actuel est déja en cours de modification. Vous avez demandé une rétrogradation de votre formule actuelle vers la formule{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold'
                  }}
                >
                  {paymentContext.newProductAfterDowngrading}
                </span>
                .
              </Typography>
              <Typography fontWeight="bold" mt={3}>
                Vous pourrez de nouveau modifier votre abonnement lorsque la nouvelle période commencera, le{' '}
                <span
                  style={{
                    textDecoration: 'underline'
                  }}
                >
                  {formattedDate}
                </span>
              </Typography>
              <Grid container alignItems="flex-end" justifyContent="flex-end" mt={5}>
                <Button onClick={() => setIsContactFormOpen(true)} variant="outlined" size="large">
                  Contactez-nous
                </Button>
                <Link to={APP_ROUTES.profile}>
                  <Button variant="contained" color="secondary" size="large" sx={{ml: 2}}>
                    Retour à mon compte
                  </Button>
                </Link>
              </Grid>
            </Paper>
          </Backdrop>
        )}

        <PageSubtitle>Sélectionnez le nombre de projets</PageSubtitle>
        <Grid container justifyContent="center">
          <ProductsSlider products={paymentContext.products} selectedNumberOfProjects={selectedNumberOfProjects} setSelectedNumberOfProjects={setSelectedNumberOfProjects} />
        </Grid>
        <Grid mt={3} mb={5} container justifyContent="center" spacing={5}>
          {productsMatchingSelectedNumberOfProjects.map(p => {
            const isProductSubscribed = p.id === paymentContext.productSubscribed?.product_id;
            const hasAtLeastOneProductSubscribed = !!paymentContext.productSubscribed;
            return (
              <Grid key={p.name} item xs={12} sm={6} md={5}>
                <ProductCard
                  customMaximumNumberOfProjects={customMaximumNumberOfProjects}
                  hasAtLeastOneProductSubscribed={hasAtLeastOneProductSubscribed}
                  isProductSubscribed={isProductSubscribed}
                  product={p}
                  onClick={() => (hasAtLeastOneProductSubscribed ? openUpdateSubscriptionModal(p) : getPaymentLinkUrl(p.price_id))}
                />
              </Grid>
            );
          })}
          {selectedNumberOfProjects === customMaximumNumberOfProjects && (
            <Grid item>
              <ProductCard
                customMaximumNumberOfProjects={customMaximumNumberOfProjects}
                onClick={() => setIsContactFormOpen(true)}
                product={{
                  numberOfProjects: selectedNumberOfProjects
                }}
              />
            </Grid>
          )}
        </Grid>
        <Box mt={3}>
          <WhiteLabelCTA />
        </Box>
      </>
    );
  };

  useEffect(() => {
    (async () => {
      paymentContext.setGetUserSubscriptionsErrorMessage('');
      paymentContext.setGetProductsListErrorMessage('');
      setLoading(true);
      if (projects === null) {
        await fetchProjects();
      }
      // Business rule: if a user is invited at least on 1 project, he cannot see pricing, therefore subscription page.
      // Business rule: if a user belongs to a partner company, he cannot see pricing, therefore subscription page.
      if (isGuestMode || process.env.REACT_APP_IS_SUBSCRIPTION_PAGE_ACTIVATED === 'false') {
        navigate(APP_ROUTES.projects);
      }
      setLoading(false);
      setLoadingGetPaymentLink(false);
    })();
  }, []);

  useEffect(() => {
    // This allows to initialize the product slider
    // to the correct number of projects for the current user subscription
    if (paymentContext.productSubscribed) {
      const productSubscribed = paymentContext.products?.find(p => p.id === paymentContext.productSubscribed.product_id);
      setSelectedNumberOfProjects(parseInt(productSubscribed?.numberOfProjects, 10));
    }
  }, [paymentContext.productSubscribed]);

  useEffect(() => {
    setLoadingGetPaymentLink(false);
  }, [location.pathname]);

  if (loading) {
    return (
      <PageContainer>
        <Grid container flex={1} alignItems="center">
          <Spinner text="Chargement des formules" isLoading />
        </Grid>
      </PageContainer>
    );
  }

  if (errorGettingPaymentLink || paymentContext.getUserSubscriptionsErrorMessage || paymentContext.getProductsListErrorMessage) {
    return (
      <PageContainer>
        <ErrorComponent message={errorGettingPaymentLink || paymentContext.getUserSubscriptionsErrorMessage || paymentContext.getProductsListErrorMessage} href={APP_ROUTES.profile} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Grid container alignItems="flex-start" sx={{mb: 1}}>
        <Link to={APP_ROUTES.profile}>
          <Button size="small" startIcon={<ArrowBackIcon />} variant="text">
            Retour à mon compte
          </Button>
        </Link>
      </Grid>
      <Grid container alignItems="center" mb={4}>
        <Grid item flex={1}>
          <PageTitle>Les abonnements</PageTitle>
        </Grid>

        <Link to={APP_ROUTES.bills}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              mt: {xs: 1, sm: 0}
            }}
          >
            Mes factures
          </Button>
        </Link>
      </Grid>

      {loadingGetPaymentLink || !loadingGetPaymentLinkHidden ? <Spinner text="Redirection vers la page de paiement" isLoading /> : getPageContent()}

      <Grid mt={5} direction="column" container alignItems="center">
        <MeansOfPaymentText>Moyens de paiement pris en charge :</MeansOfPaymentText>
        <Grid item mt={1}>
          <PaymentMeansImage src={paymentMeans} alt="Moyens de paiements" />
        </Grid>
      </Grid>

      <UpdateSubscriptionModal
        isOpen={updateSubscriptionConfirmationModalOpen}
        onClose={closeUpdateSubscriptionModal}
        product={newProductSubscribed}
        updateSubscription={() => updateCurrentSubscription(paymentContext.productSubscribed.subscription_id, newProductSubscribed.price_id)}
      />
    </PageContainer>
  );
};

export default Products;

import {string} from 'prop-types';
import './index.css';

const Ribbon = ({text}) => {
  return (
    <div className="ribbon ribbon-top-left">
      <span>{text}</span>
    </div>
  );
};

Ribbon.propTypes = {
  text: string.isRequired
};
export default Ribbon;

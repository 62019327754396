import {useEffect, useState} from 'react';

import {useTheme, DialogActions, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Lottie from 'lottie-react';
import {bool, func} from 'prop-types';
import {Link} from 'react-router-dom';

import {APP_ROUTES} from '../../../const';
import useAuth from '../../../hooks/providers/useAuth';
import useProfile from '../../../hooks/providers/useProfile';
import useProjects from '../../../hooks/providers/useProjects';
import deleteAnimation from '../../../lotties/deleteAnimation.json';
import Button from '../../form/buttons/Button';
import BaseModal from '../../modal/BaseModal';

const DeleteProfileModal = ({deleteProfile, onClose, isOpen}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const auth = useAuth();
  const parentTheme = useTheme();
  const {projects, fetchProjects, isGuestMode} = useProjects();
  const {deleteError} = useProfile();

  const resetState = () => {
    setIsLoading(false);
    setIsDeleted(false);
    onClose();
  };

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      await deleteProfile();
      setIsDeleted(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          setIsLoading(true);
          await fetchProjects();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [isOpen]);

  const userCanDeleteAccount = isGuestMode || (projects || []).length < 1;

  const title = isDeleted ? 'Compte supprimé' : 'Supprimer votre compte';
  return (
    <BaseModal
      canCloseOnBackdropClick={false}
      title={title}
      maxWidth="sm"
      onClose={resetState}
      open={isOpen}
      sx={{
        '& .MuiDialogTitle-root': {
          color: parentTheme.palette.error.main
        }
      }}
    >
      {isDeleted && !deleteError ? (
        <Box p={3}>
          <Lottie style={{height: '160px', width: '160px', margin: '24px auto'}} loop={false} animationData={deleteAnimation} />
          <Typography>Votre compte a bien été supprimé !</Typography>
          <DialogActions>
            <Button variant="contained" size="large" color="secondary" onClick={auth.logout}>
              Retour à l'accueil
            </Button>
          </DialogActions>
        </Box>
      ) : (
        <>
          <Box p={3}>
            {userCanDeleteAccount ? (
              <Typography color="error">Voulez-vous vraiment supprimer définitivement votre compte ?</Typography>
            ) : (
              <Typography>
                Vous devez supprimer tous vos projets depuis la page{' '}
                <Link style={{color: process.env.REACT_APP_SECONDARY_COLOR}} to={APP_ROUTES.projects}>
                  Gérer mes projets
                </Link>{' '}
                avant de supprimer votre compte.
              </Typography>
            )}
          </Box>
          <DialogActions>
            <Button variant="outlined" size="large" color="primary" autoFocus onClick={resetState}>
              Annuler
            </Button>
            <Button disabled={!userCanDeleteAccount || isLoading} variant="contained" size="large" color="error" onClick={onConfirm}>
              Supprimer
            </Button>
          </DialogActions>
          <Box px={2} pb={2}>
            {deleteError && (
              <Typography
                sx={{
                  textAlign: 'center',
                  color: parentTheme.palette.error.main
                }}
              >
                {deleteError}
              </Typography>
            )}
          </Box>
        </>
      )}
    </BaseModal>
  );
};

DeleteProfileModal.defaultProps = {
  isOpen: false
};

DeleteProfileModal.propTypes = {
  onClose: func.isRequired,
  deleteProfile: func.isRequired,
  isOpen: bool
};

export default DeleteProfileModal;

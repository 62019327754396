import {Typography} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

// eslint-disable-next-line react/jsx-props-no-spreading
const PageTitle = muiStyled(props => <Typography variant="h1" {...props} />)(({theme}) => ({
  width: '100%',
  textAlign: 'left',
  fontFamily: 'SoehneBreitKraftig',
  fontSize: theme.typography.pxToRem(35),
  lineHeight: 1
}));

export default PageTitle;

import {DeleteForever} from '@mui/icons-material';
import {MenuItem, ListItemIcon} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';

const ProjectMenuDeleteButton = () => {
  const {setDeleteProjectModalOpen, selectedProject: project} = useProjects();
  const {openModal} = useProjectCrudModal({setModalOpen: setDeleteProjectModalOpen, project});

  const theme = useTheme();

  return (
    <MenuItem onClick={openModal}>
      <ListItemIcon>
        <DeleteForever fontSize="small" color="error" />
      </ListItemIcon>
      <Typography sx={{color: theme.palette.error.main}} variant="inherit">
        Supprimer le projet
      </Typography>
    </MenuItem>
  );
};

export default ProjectMenuDeleteButton;

import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import {bool, func} from 'prop-types';

import Switch from '../../switch/Switch';

const HiddenRowsToggler = ({toggle, checked, disabled}) => {
  return (
    <Grid container alignItems="center" spacing={0.5}>
      <Grid item>
        <Typography color={disabled ? 'text.disabled' : 'text.primary'} fontWeight={300} fontSize={13} lineHeight={1}>
          Afficher les lignes masquées
        </Typography>
      </Grid>
      <Grid item position="relative">
        <Switch disabled={disabled} onChange={toggle} checked={checked} />
      </Grid>
    </Grid>
  );
};

HiddenRowsToggler.propTypes = {
  toggle: func.isRequired,
  checked: bool.isRequired,
  disabled: bool.isRequired
};

export default HiddenRowsToggler;

import {MAXIMUM_NUMBER_OF_WORKSPACES} from '../../const';
import useProfile from '../../hooks/providers/useProfile';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import CreateReportOrWorkspaceButton from './CreateReportOrWorkspaceButton';

const CreateWorkspaceButton = () => {
  const {openCreateWorkspaceModal, workspaces} = useWorkspaces();
  const {profile} = useProfile();

  const findUserOwnedWorkspaces = () => {
    return workspaces.filter(workspace => workspace.workspace_users?.some(user => user.user_id === profile?.id && user.is_owner));
  };

  const numberOfWorkspacesOwnedByUser = findUserOwnedWorkspaces().length + 1; // We add 1 for DeFi WS
  const maximumNumberOfWorkspacesReached = numberOfWorkspacesOwnedByUser >= MAXIMUM_NUMBER_OF_WORKSPACES;
  const handleButtonClick = maximumNumberOfWorkspacesReached ? null : openCreateWorkspaceModal;

  const tooltip = maximumNumberOfWorkspacesReached ? "Vous avez atteint la limite d'espaces personnels que vous pouvez créer." : '';
  return <CreateReportOrWorkspaceButton maxLimitReached={maximumNumberOfWorkspacesReached} onClick={handleButtonClick} tooltipTitle={tooltip} label="Ajouter un espace" size="medium" />;
};

export default CreateWorkspaceButton;

import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 48px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 10px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 32px 48px;
  min-height: calc(100vh - 160px);
  @media screen and (max-width: 1200px) {
    padding: 24px 12px;
    margin: 48px 12px;
  }
`;
export default PageContainer;

import {Link} from 'react-router-dom';

import {APP_ROUTES} from '../../const';
import useBanner from '../../hooks/providers/useBanner';
import SubscriptionBanner from './SubscriptionBanner';

const ExpiredSubscriptionBanner = () => {
  const {shouldDisplayExpiredSubscriptionBanner} = useBanner();

  if (!shouldDisplayExpiredSubscriptionBanner) return null;

  return (
    <SubscriptionBanner title="Limite atteinte." severity="warning">
      Veuillez&nbsp;<Link to={APP_ROUTES.subscription}>mettre à niveau votre abonnement</Link>&nbsp;pour continuer à profiter des services de Drivn.
    </SubscriptionBanner>
  );
};

export default ExpiredSubscriptionBanner;

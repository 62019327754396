import React from 'react';

import {DeleteForeverOutlined} from '@mui/icons-material';
import {Grid, Avatar, DialogContentText, Typography, DialogActions, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import {bool, func} from 'prop-types';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../const';
import useProjects from '../../hooks/providers/useProjects';
import Button from '../form/buttons/Button';
import BaseModal from '../modal/BaseModal';
import Spinner from '../spinner/Spinner';

// eslint-disable-next-line complexity
const DeleteProjectModal = props => {
  const {onClose, open} = props;

  const projectsContext = useProjects();
  const {selectedProject: project} = projectsContext;
  const {siren} = project || {};
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasBeenDeleted, setHasBeenDeleted] = React.useState(false);

  const theme = useTheme();

  const deleteProject = async () => {
    setIsSubmitting(true);
    const result = await projectsContext?.deleteProject(project);
    setIsSubmitting(false);

    if (result.status === 200) {
      setHasBeenDeleted(true);
    }
  };

  const handleClose = async (event, reason, projectDeleted = false) => {
    if (projectDeleted) {
      const projectToDeleteIndex = projectsContext.projects.findIndex(
        p => p.siren === siren && (p.schema_name === projectsContext.selectedReportTab || p.report_id === projectsContext.selectedReportTab)
      );

      let newProjects = [...projectsContext.projects];
      newProjects.splice(projectToDeleteIndex, 1);

      if (projectsContext.selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.gestion) {
        // Business rule: DeFi Previ projects must be deleted automatically when associated DeFi Gestion project is deleted.
        const previProjectIndex = newProjects.findIndex(p => p.siren === siren && p.schema_name === DEFAULT_PROJECTS_SCHEMAS.previ);
        if (projectsContext.selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.gestion && previProjectIndex !== -1) {
          newProjects.splice(previProjectIndex, 1);
        }
        // Business rule : All associated projects in custom workspaces must be deleted after DeFi Gestion project deletion
        newProjects = newProjects.filter(p => {
          // if project has a different siren, we keep it
          if (p.siren !== siren) return true;
          // if project has the same siren and is not inside a custom workspace, we DO NOT keep it
          return Object.values(DEFAULT_PROJECTS_SCHEMAS).includes(p.schema_name);
        });
      }

      projectsContext.setProjects(newProjects);
    }

    onClose();
  };

  const isDeFiGestionProject = project.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion;
  const title = hasBeenDeleted ? 'Dossier supprimé' : 'Supprimer le projet';

  return (
    <BaseModal
      sx={{
        '& .MuiDialogContentText-root': {
          color: theme.palette.error.main
        }
      }}
      title={title}
      canCloseOnBackdropClick={false}
      onClose={handleClose}
      open={open}
    >
      <Box p={3}>
        {hasBeenDeleted && (
          <Grid container direction="column" alignItems="center">
            <Avatar sx={{p: 5, bgcolor: `${theme.palette.error.light}30`}}>
              <DeleteForeverOutlined sx={{fontSize: 60}} color="error" />
            </Avatar>
            <Typography sx={{mt: 5, mb: 1, fontSize: 15}} variant="inherit">
              Votre projet a bien été supprimé !
            </Typography>
          </Grid>
        )}
        {!isSubmitting && !hasBeenDeleted && <DialogContentText textAlign="center">Êtes-vous sûr de vouloir supprimer définitivement ce projet ?</DialogContentText>}
        {!isSubmitting && !hasBeenDeleted && isDeFiGestionProject && (
          <Typography mt={2} color="text.secondary" fontSize={13} fontWeight="bold" textAlign="center">
            Ce projet sera également supprimé de DeFi Prévi et de vos rapports personnalisés s'il y est associé.
          </Typography>
        )}
        {isSubmitting && (
          <>
            <Spinner text="Suppression du projet en cours" isLoading />
            <Typography color="text.secondary" fontSize={13} textAlign="center">
              Selon la taille de votre projet, sa suppression complète peut prendre plus d'une minute.
            </Typography>
          </>
        )}
      </Box>
      <DialogActions
        sx={{
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {hasBeenDeleted ? (
          <Button size="large" variant="contained" color="secondary" onClick={(event, reason) => handleClose(event, reason, true)}>
            Retour à mes projets
          </Button>
        ) : (
          <>
            {!isSubmitting && (
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Annuler
              </Button>
            )}
            <Button disabled={isSubmitting} variant="contained" color="error" onClick={deleteProject}>
              Supprimer
            </Button>
          </>
        )}
      </DialogActions>
      {projectsContext.deleteError && <Typography sx={{color: theme.palette.error.main}} />}
    </BaseModal>
  );
};

DeleteProjectModal.propTypes = {
  onClose: func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  open: bool.isRequired
};

export default DeleteProjectModal;

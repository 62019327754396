import {useState} from 'react';

import {Refresh} from '@mui/icons-material';
import {IconButton} from '@mui/material';

import useProjects from '../../../../hooks/providers/useProjects';
import {projectShape} from '../../../../proptypes-shapes';
import RotatingIcon from '../../../spinner/RotatingIcon';
import ProjectActionButton from '../ProjectActionButton';
import ActualizeDataMenu from './ActualizeDataMenu';

const ActualizeProjectDataButton = ({project}) => {
  const {setSelectedProject, projectsSirenCurrentlyRefreshing, isActualizeProjectMenuOpen, setIsActualizeProjectMenuOpen} = useProjects();
  const [menuForListViewAnchorEl, setMenuForListViewAnchorEl] = useState(null);

  const handleOpenMenu = e => {
    setSelectedProject(project);
    setIsActualizeProjectMenuOpen(project); // We store for which project the menu is open
    setMenuForListViewAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setIsActualizeProjectMenuOpen(null);
    setMenuForListViewAnchorEl(null);
    setSelectedProject(null);
  };

  const isProjectRefreshing = projectsSirenCurrentlyRefreshing.includes(project.siren);

  // Both card / list view have same markup for the actualize data button
  const markup = (
    <IconButton disabled={isProjectRefreshing} size="large" aria-label="Menu actualisation" sx={{p: 0, mr: 0.5}} onClick={handleOpenMenu}>
      <RotatingIcon isRotating={isProjectRefreshing} icon={Refresh} />
    </IconButton>
  );

  // We store the project for which menu is open in isActualizeProjectMenuOpen
  const isOpen = isActualizeProjectMenuOpen?.siren === project.siren;

  return (
    <>
      <ProjectActionButton cardMarkup={markup} listMarkup={markup} />
      <ActualizeDataMenu isNested={false} anchorEl={menuForListViewAnchorEl} isOpen={isOpen} onClose={handleCloseMenu} />
    </>
  );
};

ActualizeProjectDataButton.defaultProps = {
  project: null
};

ActualizeProjectDataButton.propTypes = {
  project: projectShape
};

export default ActualizeProjectDataButton;

/* eslint-disable class-methods-use-this */
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

class ToggleRowVisibilityCellTemplate {
  getCompatibleCell(uncertainCell) {
    return {...uncertainCell, text: ''};
  }

  isFocusable() {
    return false;
  }

  render(cell) {
    const {isVisible, onToggle} = cell;

    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <IconButton onClick={onToggle} size="small">
          {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </div>
    );
  }
}

export default ToggleRowVisibilityCellTemplate;

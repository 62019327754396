import {Typography} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {string} from 'prop-types';
import styled from 'styled-components';

import LogoSvg from '../img/company-logo';

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Logo = styled(LogoSvg)`
  max-height: 96px;
  margin-bottom: 16px;
`;

const MaintenanceTitle = muiStyled(Typography)(({theme}) => ({
  fontSize: 30,
  mt: 1,
  fontWeight: 400,
  color: theme.palette.grey[600],
  textAlign: 'center',
  padding: '0 8px'
}));

const MaintenanceSubtitle = muiStyled(Typography)(({theme}) => ({
  fontSize: 18,
  mt: 1,
  fontWeight: 400,
  color: theme.palette.grey[600],
  textAlign: 'center',
  padding: '0 8px'
}));

const LinkToCompanyWebsite = styled.a`
  margin-top: 20px;
  font-size: 14px;
  color: ${process.env.REACT_APP_MAIN_COLOR};
`;

const Maintenance = ({title, subtitle}) => {
  const {REACT_APP_MAINTENANCE_PAGE_URL_TO_COMPANY_WEBSITE, REACT_APP_HAS_CUSTOM_URL_TO_COMPANY_WEBSITE} = process.env;
  const humanizedWebsiteUrl = REACT_APP_MAINTENANCE_PAGE_URL_TO_COMPANY_WEBSITE.replace(/(^\w+:|^)\/\//, '')
    .replace(/^www\./, '')
    .split('/')[0];

  const sentence = REACT_APP_HAS_CUSTOM_URL_TO_COMPANY_WEBSITE === 'true' ? `Revenir sur ${humanizedWebsiteUrl}` : `Revenir sur le site de notre partenaire Drivn`;

  return (
    <Container>
      <Logo variant="dark" isSymbol />
      <MaintenanceTitle>{title}</MaintenanceTitle>
      <MaintenanceSubtitle>{subtitle}</MaintenanceSubtitle>
      <LinkToCompanyWebsite target="_blank" href={REACT_APP_MAINTENANCE_PAGE_URL_TO_COMPANY_WEBSITE}>
        {sentence}
      </LinkToCompanyWebsite>
    </Container>
  );
};

Maintenance.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired
};

export default Maintenance;

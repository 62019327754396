import {useState} from 'react';

import {Button, Dialog, DialogActions, DialogContent, FormControlLabel, DialogTitle, Divider, Typography} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import useReport from '../../hooks/providers/useReport';
import {deleteAllFiltersFromLocalStorage, saveUserChoiceConcerningFiltersToLocalStorage} from '../../utils';

const LoadReportFiltersModal = () => {
  const {isApplyPreviousFiltersModalOpen: isOpen, setIsApplyPreviousFiltersModalOpen, setShouldApplyPreviousFiltersStepCompleted} = useReport();
  const [doNotAskMeAgainChecked, setDoNotAskMeAgainChecked] = useState(false);

  const handleSubmit = userWantsToApplyFilters => {
    if (doNotAskMeAgainChecked) {
      saveUserChoiceConcerningFiltersToLocalStorage(userWantsToApplyFilters);
    }

    if (userWantsToApplyFilters) {
      setShouldApplyPreviousFiltersStepCompleted(true);
      setIsApplyPreviousFiltersModalOpen(false);
    } else {
      deleteAllFiltersFromLocalStorage();
      setShouldApplyPreviousFiltersStepCompleted(true);
      setIsApplyPreviousFiltersModalOpen(false);
    }
    setShouldApplyPreviousFiltersStepCompleted(true);
  };

  return (
    <Dialog maxWidth="sm" open={isOpen}>
      <DialogTitle>Filtres sauvegardés</DialogTitle>
      <Divider sx={{my: 0.5}} />
      <DialogContent>
        <Typography sx={{mb: 1}}>Nous avons conservé les filtres utilisés lors de précédente visite.</Typography>
        <Typography>Souhaitez-vous les réutiliser ?</Typography>
        <FormControlLabel
          sx={{mt: 1}}
          label={<Typography sx={{fontSize: 13}}>Ne plus me demander</Typography>}
          control={<Checkbox size="small" color="info" onChange={ev => setDoNotAskMeAgainChecked(ev.target.checked)} />}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={() => handleSubmit(false)}>
          Réinitialiser
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleSubmit(true)}>
          Appliquer les filtres
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadReportFiltersModal;

import {useState} from 'react';

import {FolderOpen} from '@mui/icons-material';
import {useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import {number, string} from 'prop-types';

const ProjectLogo = ({urlLogo, size}) => {
  const [projectImageFound, setProjectImageFound] = useState(false);
  const [projectImageLoaded, setProjectImageLoaded] = useState(false);

  const theme = useTheme();
  const thumbnailBackgroundColor = `${theme.palette.primary.main}30`;

  const imageSize = urlLogo ? size + 8 : size;

  return (
    <Box
      sx={{
        padding: urlLogo ? 0.5 : 1,
        borderRadius: 2,
        background: thumbnailBackgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: imageSize,
        height: imageSize
      }}
    >
      {(projectImageFound || !projectImageLoaded) && urlLogo && (
        <img
          alt={urlLogo}
          src={urlLogo}
          onError={() => {
            setProjectImageFound(false);
            setProjectImageLoaded(true);
          }}
          onLoad={() => {
            setProjectImageLoaded(true);
            setProjectImageFound(true);
          }}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain'
          }}
        />
      )}
      {((!projectImageFound && projectImageLoaded) || !urlLogo) && <FolderOpen sx={{fontSize: size}} color="primary" />}
    </Box>
  );
};

ProjectLogo.defaultProps = {
  urlLogo: null
};

ProjectLogo.propTypes = {
  urlLogo: string,
  size: number.isRequired
};

export default ProjectLogo;

import {bool, elementType} from 'prop-types';

const RotatingIcon = ({isRotating, icon: IconComponent}) => {
  const rotatingStyles = isRotating
    ? {
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(0deg)'
          },
          '100%': {
            transform: 'rotate(360deg)'
          }
        }
      }
    : {};

  return (
    <IconComponent
      color={isRotating ? 'default' : 'primary'}
      sx={{
        ...rotatingStyles
      }}
    />
  );
};

RotatingIcon.propTypes = {
  isRotating: bool.isRequired,
  icon: elementType.isRequired
};

export default RotatingIcon;

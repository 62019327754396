import ErrorIcon from '@mui/icons-material/Error';
import {Grid, Typography} from '@mui/material';
import {func, string} from 'prop-types';
import {useNavigate} from 'react-router-dom';

import Button from '../form/buttons/Button';

const ErrorComponent = ({message, href, onClick, buttonText}) => {
  const navigate = useNavigate();
  return (
    <Grid container justifyContent="center" alignItems="center" flex={1} direction="column" rowSpacing={3}>
      <ErrorIcon sx={{fontSize: 70}} color="error" />
      <Typography fontWeight="bold" my={3}>
        {message}
      </Typography>
      <Button size="large" variant="contained" color="secondary" onClick={() => (href ? navigate(href) : onClick())}>
        {buttonText}
      </Button>
    </Grid>
  );
};

ErrorComponent.defaultProps = {
  onClick: null,
  href: '',
  buttonText: 'Retour'
};

ErrorComponent.propTypes = {
  message: string.isRequired,
  href: string,
  onClick: func,
  buttonText: string
};

export default ErrorComponent;

import {oneOfType, node, func, string} from 'prop-types';
import styled from 'styled-components';

import Spinner from '../../spinner/Spinner';

const loadingTextStyle = {
  fontFamily: 'InstrumentSansBold',
  fontSize: 30
};

const ReportSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -webkit-transition: 0.5s ease all;
`;

const ReportSpinner = ({children, text}) => {
  return (
    <ReportSpinnerContainer>
      <Spinner textStyle={loadingTextStyle} text={text} isLoading />
      {children}
    </ReportSpinnerContainer>
  );
};

ReportSpinner.propTypes = {
  children: oneOfType([node, func]).isRequired,
  text: string.isRequired
};

export default ReportSpinner;

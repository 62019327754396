import {useMemo} from 'react';

import {oneOfType, node, func} from 'prop-types';

import PwaContext from '../contexts/PwaContext';
import useIosInstallPrompt from '../hooks/pwa/useIosPwaInstallPrompt';
import useWebInstallPrompt from '../hooks/pwa/usePwaWebInstallPrompt';

const PwaProvider = ({children}) => {
  const [iosInstallPrompt, handleIosInstallDecline] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDecline, handleWebInstallAccept] = useWebInstallPrompt();

  const useMemoDeps = [iosInstallPrompt, handleIosInstallDecline, webInstallPrompt, handleWebInstallDecline, handleWebInstallAccept];

  const value = useMemo(
    () => ({
      iosInstallPrompt,
      handleIosInstallDecline,
      webInstallPrompt,
      handleWebInstallDecline,
      handleWebInstallAccept
    }),
    useMemoDeps
  );

  return <PwaContext.Provider value={value}>{children}</PwaContext.Provider>;
};

PwaProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default PwaProvider;

/* eslint-disable class-methods-use-this */

class UnfocusableCell {
  getCompatibleCell(uncertainCell) {
    return {...uncertainCell};
  }

  isFocusable() {
    return false;
  }

  render(cell) {
    return <div className={cell.className}>{cell.text}</div>;
  }
}

export default UnfocusableCell;

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {MenuItem, ListItemIcon} from '@mui/material';
import Typography from '@mui/material/Typography';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';
import useProjectsCustomMappings from '../../../../hooks/providers/useProjectsCustomMappings';

const ProjectMenuCustomizeMappingButton = () => {
  const {selectedProject: project} = useProjects();
  const {setCustomizeMappingModalOpen} = useProjectsCustomMappings();
  const {openModal} = useProjectCrudModal({setModalOpen: setCustomizeMappingModalOpen, project});

  return (
    <MenuItem onClick={openModal}>
      <ListItemIcon>
        <AccountTreeIcon fontSize="small" color="primary" />
      </ListItemIcon>
      <Typography variant="inherit">Modifier l'affectation des comptes</Typography>
    </MenuItem>
  );
};

export default ProjectMenuCustomizeMappingButton;

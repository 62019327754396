import Papa from 'papaparse';

import {REGULAR_EXPRESSIONS} from '../const';

const getOldestDate = datesArray => {
  // Sort the dates in ascending order
  datesArray.sort((a, b) => {
    return a.localeCompare(b);
  });
  // Return the oldest date (the first element in the array)
  return datesArray[0];
};

const getManualConnectorFilename = async (file, siren) => {
  try {
    const content = await file.text();
    const records = Papa.parse(content, {header: true}).data;

    // This regex aims to perform minimal tests on the filename output format:
    // 9 digits for the SIREN
    // followed by "_"
    // followed by a valid date (checks if day is between 1 and 31, month between 1 and 12) at format YYYYMMDD
    // followed by ".txt" string at the end
    const regex = REGULAR_EXPRESSIONS.fecFileName;

    const dates = records.map(record => record.EcritureDate || record.ecrituredate || null);
    const oldestDate = getOldestDate(dates.filter(item => item !== null));

    const year = oldestDate.substring(0, 4);
    const month = oldestDate.substring(4, 6);
    const day = oldestDate.substring(6, 8);
    const formattedDate = `${year}${month}${day}`;

    const filename = `${siren}_${formattedDate}.txt`;
    const isFilenameValid = regex.test(filename);
    // eslint-disable-next-line no-throw-literal
    if (!isFilenameValid) throw `File may contain an error as generated filename is invalid : ${filename}`;
    return filename;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in getManualConnectorFilename:', error);
    throw error;
  }
};

const getFileB64 = async file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export {getManualConnectorFilename, getFileB64};

import CropSquareIcon from '@mui/icons-material/CropSquare';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Grid from '@mui/material/Grid';

import {PROJECTS_VIEW_TYPES} from '../../const';
import useProjects from '../../hooks/providers/useProjects';

const ProjectsViewTypeToggler = () => {
  const {viewType, setViewType} = useProjects();

  const toggleViewType = viewTypeClicked => {
    if (viewTypeClicked !== viewType) {
      const newViewType = viewType === PROJECTS_VIEW_TYPES.card ? PROJECTS_VIEW_TYPES.list : PROJECTS_VIEW_TYPES.card;
      setViewType(newViewType);
    }
  };

  return (
    <Grid container alignItems="center">
      <CropSquareIcon sx={{cursor: 'pointer', fontSize: 26}} color={viewType === PROJECTS_VIEW_TYPES.card ? 'secondary' : 'primary'} onClick={() => toggleViewType(PROJECTS_VIEW_TYPES.card)} />
      <FormatListBulletedIcon
        sx={{cursor: 'pointer', fontSize: 26, ml: 0.5}}
        color={viewType === PROJECTS_VIEW_TYPES.list ? 'secondary' : 'primary'}
        onClick={() => toggleViewType(PROJECTS_VIEW_TYPES.list)}
      />
    </Grid>
  );
};

export default ProjectsViewTypeToggler;

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
import {LogLevel} from '@azure/msal-browser';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// URL used for initiating authorization request
// We stored it in env files because this url is different depending on current client application running (drivn, zenfirst ...)
const authorityUrl = process.env.REACT_APP_AZURE_B2C_AUTHORITY_URL;
const signUpSignInPolicyName = `B2C_1_${process.env.REACT_APP_COMPANY_POLICY}`;

// Client Id (Application Id) of the AAD app.
export const clientId = '8eac0a54-2b50-49f3-ade0-149e40f33dc3';

// Scope for securing access token
// We put the clientId in the scope because otherwise we have an empty string as accessToken.
// example SO thread on this issue : https://stackoverflow.com/questions/65641421/i-cant-get-accesstoken-via-requiretokensilent-azure-msal-browser
// issue: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2315
// proposed solution: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2315#issuecomment-773407358
export const scopeBase = ['openid', 'profile', clientId];

export const b2cPolicies = {
  names: {
    signUpSignIn: signUpSignInPolicyName
  },
  authorities: {
    signUpSignIn: {
      authority: authorityUrl
    }
  },
  authorityDomain: 'drivnb2c.b2clogin.com'
};
const loggerCallback = (level, message, containsPii) => {
  if (containsPii) {
    return;
  }

  switch (level) {
    case LogLevel.Error:
      // eslint-disable-next-line no-console
      console.error(message);
      return;
    case LogLevel.Verbose:
      // eslint-disable-next-line no-console
      console.debug(message);
      return;
    case LogLevel.Warning:
      // eslint-disable-next-line no-console
      console.warn(message);
      break;
    default:
      // eslint-disable-next-line no-console
      console.info(message);
      break;
  }
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    knownAuthorities: [b2cPolicies.authorityDomain]
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  system: {
    loggerOptions: {
      loggerCallback
    }
  }
};

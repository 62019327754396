import {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import * as Sentry from '@sentry/react';
import {useWindowSize} from '@uidotdev/usehooks';
import {models} from 'powerbi-client';
import {useLocation, useParams} from 'react-router-dom';
import styled from 'styled-components';

import EmbedReport from '../components/embed/EmbedReport';
import {REPORT_URL_FORMATTED_IDS, EMBED_REPORT_DEFAULT_SETTINGS as defaultSettings, CHATBOT_SIDEBAR_WIDTH} from '../const';
import useChatbot from '../hooks/providers/useChatbot';
import useProjects from '../hooks/providers/useProjects';
import useReport from '../hooks/providers/useReport';
import {getCompanyReportTheme, getReportAssociatedModule, shouldUpdateToken} from '../utils';

const PageContainer = muiStyled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const EmbedContainer = styled.div`
  max-height: ${props => props.hidden && 0};
  overflow: ${props => props.hidden && 'hidden'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: transparent;
  height: ${props => `calc(100vh - 64px - ${props.bannerHeight}px)`};
`;

// eslint-disable-next-line complexity
const Report = () => {
  const {tokenExpiration, setTokenExpiration, selectedReport, loadReports, reports, embedToken, setSelectedProject, reportSelectedPage} = useReport();
  const {isChatbotSidebarOpen} = useChatbot();
  const {projects} = useProjects();

  const params = useParams();
  const windowSize = useWindowSize();
  const location = useLocation();

  const projectSiren = location.state ? location.state.projectSiren : localStorage.getItem('projectSiren');
  const reportId = location.state ? location.state.reportId : localStorage.getItem('reportId');

  useEffect(() => {
    if (reportId) {
      localStorage.setItem('reportId', reportId);
    }
    if (projectSiren) {
      localStorage.setItem('projectSiren', projectSiren);
    }
  }, [location.state]);

  const desktopAspectRatio = 4 / 3;

  const [report, setReport] = useState();
  const [reportSettings, setReportSettings] = useState(defaultSettings);
  const [pageBeforeReload, setPageBeforeReload] = useState(null);

  const getReportName = () => {
    const reportName = REPORT_URL_FORMATTED_IDS.find(item => item.urlFormattedReportName === params.reportName)?.reportName;
    return reportName;
  };

  const reportName = getReportName();
  const module = getReportAssociatedModule(reportName);

  const clearReportSelectedFromLocalStorage = () => {
    localStorage.removeItem('reportId');
    localStorage.removeItem('projectSiren');
  };

  // This hook aims to set selected project when arriving on /reports/:reportId
  useEffect(() => {
    if (projectSiren && projects !== null) {
      const project = projects.find(p => p.siren === projectSiren && p.schema_name === module);
      setSelectedProject(project);
    }
  }, [projectSiren, projects]);

  // This hook aims to handle responsive rendering of report.
  useEffect(() => {
    (async () => {
      const widthAvailable = isChatbotSidebarOpen ? windowSize.width - CHATBOT_SIDEBAR_WIDTH : windowSize.width;
      const currentAspectRatio = widthAvailable / windowSize.height;

      let newSettings;
      if (currentAspectRatio < desktopAspectRatio) {
        newSettings = {
          ...defaultSettings,
          layoutType: models.LayoutType.MobilePortrait
        };
      } else {
        newSettings = {
          ...defaultSettings,
          layoutType: models.LayoutType.Master
        };
      }
      const breakpointHasChanged = newSettings.layoutType !== reportSettings?.layoutType;

      if (breakpointHasChanged) {
        try {
          setPageBeforeReload(reportSelectedPage);
          setReportSettings(newSettings);
          selectedReport?.updateSettings(newSettings);
          selectedReport?.reload();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          Sentry.captureException(e);
        }
      }
    })();
  }, [windowSize, selectedReport, isChatbotSidebarOpen]);

  // This hook aims to set selected report on component mount OR when reportId change
  useEffect(() => {
    if (reportId && reports) {
      const rep = reports.find(r => r.report_id === reportId);
      setReport(rep);
    }
  }, [reportId, reports]);

  // This hook aims to clear selected report on page change
  useEffect(() => {
    if (!location.pathname.startsWith('/reports')) {
      setReport(null);
      setPageBeforeReload(null);
      clearReportSelectedFromLocalStorage();
    }
  }, [location.pathname]);

  // eslint-disable-next-line consistent-return
  const updateToken = async () => {
    if (selectedReport) {
      const result = await loadReports();
      if (result.success) {
        const {tokenData: newAccessToken} = result;
        setTokenExpiration(newAccessToken.expiration);
        return selectedReport.setAccessToken(newAccessToken.token);
      }
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (report) {
      const INTERVAL_TIME = 10000;
      const interval = setInterval(async () => {
        // eslint-disable-next-line no-console
        console.warn('Checking token validity, called every 10 seconds');
        const shouldUpdate = await shouldUpdateToken(tokenExpiration);
        if (shouldUpdate) {
          // eslint-disable-next-line no-console
          console.warn('Updating report access token');
          await updateToken();
        }
      }, INTERVAL_TIME);

      return () => clearInterval(interval);
    }
  }, [selectedReport, tokenExpiration]);

  const bannerHeight = document.getElementsByClassName('MuiAlert-filledSuccess')[0]?.clientHeight || 0;
  const reportTheme = getCompanyReportTheme();

  const commonReportConfigProps = {
    theme: {themeJson: reportTheme},
    filters: null,
    type: 'report',
    id: report ? report.report_id : undefined,
    embedUrl: report ? report.embed_url : undefined,
    accessToken: report ? embedToken : undefined,
    tokenType: models.TokenType.Embed
  };

  return (
    <PageContainer>
      <EmbedContainer hidden={!location.pathname.startsWith('/reports')} bannerHeight={bannerHeight}>
        {reportSettings && reportSettings.layoutType === models.LayoutType.MobilePortrait && (
          <EmbedReport
            module={module}
            lastPageBeforeReload={pageBeforeReload}
            reportConfig={{
              ...commonReportConfigProps,
              permissions: models.Permissions.ReadWrite,
              settings: {
                ...defaultSettings,
                layoutType: models.LayoutType.MobilePortrait
              }
            }}
          />
        )}

        {reportSettings && reportSettings.layoutType === models.LayoutType.Master && (
          <EmbedReport
            module={module}
            lastPageBeforeReload={pageBeforeReload}
            reportConfig={{
              ...commonReportConfigProps,
              permissions: models.Permissions.ReadWrite,
              settings: {
                ...defaultSettings
              }
            }}
          />
        )}
      </EmbedContainer>
    </PageContainer>
  );
};

export default Report;

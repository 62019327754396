import {useEffect} from 'react';

import {Grid, MenuItem, TextField, Typography} from '@mui/material';
import {bool, func} from 'prop-types';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import {getHumanReadableSiren, getTruncatedString} from '../../../utils';
import Button from '../../form/buttons/Button';
import Spinner from '../../spinner/Spinner';

const DefiPreviForm = ({handleSubmit, isSubmitting}) => {
  const {isLoading, setSiren, siren, projects} = useProjects();
  const defiGestionProjects = projects.filter(p => p.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion);
  const defiPreviSirens = projects.filter(p => p.schema_name === DEFAULT_PROJECTS_SCHEMAS.previ).map(p => p.siren);
  const currentUserProjects = defiGestionProjects?.filter(p => p.is_owner && !defiPreviSirens.includes(p.siren));

  useEffect(() => {
    setSiren('');
  }, []);

  return (
    <>
      <Typography sx={{fontSize: 14, mb: 3, fontWeight: 'bold'}}>Pour démarrer un nouveau projet avec DeFi Prévi, sélectionnez un projet DeFi Gestion existant dans la liste ci-dessous :</Typography>

      {isLoading ? (
        <Spinner text="Chargement des projets DeFi Gestion" isLoading={isLoading && !isSubmitting} />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10}>
            <TextField
              size="small"
              sx={{mb: 2}}
              onChange={e => setSiren(e.target.value)}
              fullWidth
              disabled={isSubmitting}
              id="outlined-select-project"
              select
              label="Choisissez un projet ..."
              defaultValue=""
            >
              {currentUserProjects.length === 0 && (
                <MenuItem disabled value={null}>
                  Pas de projet
                </MenuItem>
              )}
              {currentUserProjects.map(project => (
                <MenuItem key={project.siren} value={project.siren}>
                  {getTruncatedString(project.project_name)} ({getHumanReadableSiren(project.siren)})
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Button disabled={!siren || isSubmitting} color="primary" variant="contained" onClick={handleSubmit}>
              Ajouter
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

DefiPreviForm.propTypes = {
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired
};

export default DefiPreviForm;

const CUSTOM_MAPPINGS_COLUMNS_NAMES = {
  compteLib: {
    name: 'comptelib',
    defaultWidth: 250
  },
  mappingSig: {
    name: 'mapping_sig',
    defaultWidth: 250
  },
  mappingName: {
    name: 'mapping_name',
    defaultWidth: 250
  },
  mappingSubname: {
    name: 'mapping_subname',
    defaultWidth: 250
  },
  delete: {
    name: 'delete',
    defaultWidth: 50
  }
};

const getCustomMappingsColumns = () => {
  return Object.values(CUSTOM_MAPPINGS_COLUMNS_NAMES).map(c => ({
    resizable: true,
    columnId: c.name,
    width: c.defaultWidth
  }));
};

const CUSTOM_MAPPINGS_HEADER_ROW = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'Libellé du compte', className: 'header-cell'},
    {type: 'header', text: 'SIG', className: 'header-cell'},
    {type: 'header', text: 'Catégorie', className: 'header-cell'},
    {type: 'header', text: 'Sous-catégorie', className: 'header-cell'},
    {type: 'header', text: '', className: 'header-cell'}
  ]
};

export {CUSTOM_MAPPINGS_HEADER_ROW, CUSTOM_MAPPINGS_COLUMNS_NAMES, getCustomMappingsColumns};

import {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import useProfile from '../../../hooks/providers/useProfile';
import useReport from '../../../hooks/providers/useReport';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import EditableReportSaveAsModal from '../EditableReportSaveAsModal';
import EditReportButton from './EditReportButton';
import SaveReportButton from './SaveReportButton';

const EditableReportButtons = () => {
  const {isReportEditable, isDesktopLayout, selectedReport} = useReport();
  const {workspaces, getWorkspaces, isUserOwnerOfReport} = useWorkspaces();
  const {profile} = useProfile();

  const [owner, setOwner] = useState(false);

  const shouldDisplayButton = isReportEditable && isDesktopLayout;

  useEffect(() => {
    (async () => {
      if (workspaces.length === 1) {
        await getWorkspaces();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (workspaces.length > 1) {
        const isOwner = isUserOwnerOfReport(profile?.id, selectedReport.config.id);
        setOwner(isOwner);
      }
    })();
  }, [workspaces.length]);

  if (!shouldDisplayButton || !owner) return null;

  return (
    <Box sx={{position: 'fixed', bottom: 6}}>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item>
          <EditReportButton />
        </Grid>
        <Grid item>
          <SaveReportButton />
        </Grid>
      </Grid>
      <EditableReportSaveAsModal />
    </Box>
  );
};

export default EditableReportButtons;

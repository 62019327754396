import {useState} from 'react';

import {DialogActions, DialogContent, MenuItem, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import {bool, func} from 'prop-types';

import {MONTHS, DEFAULT_PROJECTS_SCHEMAS, REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../const';
import useProjects from '../../hooks/providers/useProjects';
import Button from '../form/buttons/Button';
import ErrorMessage from '../form/ErrorMessage';
import BaseModal from '../modal/BaseModal';
import Spinner from '../spinner/Spinner';

// eslint-disable-next-line complexity
const UpdateProjectModal = ({onClose, open}) => {
  const {updateProject, selectedReportTab, updateError, selectedProject: project} = useProjects();

  const [name, setName] = useState(project.project_name);
  const [month, setMonth] = useState(project.first_fiscal_month?.toString());
  const [logo, setLogo] = useState(project.url_logo);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    const data = {
      siren: project.siren,
      logo,
      name,
      month
    };

    setIsSubmitting(true);
    const result = await updateProject(data);
    setIsSubmitting(false);

    if (result.status === 200) {
      onClose();
    }
  };

  const hasNameError = name !== '' && !REGULAR_EXPRESSIONS.label.test(name);
  const getNameInputErrorText = () => {
    if (!name) return REGULAR_EXPRESSIONS_ERROR_TEXTS.mandatoryField;
    if (hasNameError) return REGULAR_EXPRESSIONS_ERROR_TEXTS.label;
    return '';
  };

  const sirenInputLabel = selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.gestion ? 'Siren' : 'Siren + site id';
  const hasLogoError = logo && !REGULAR_EXPRESSIONS.url.test(logo);

  const canSubmit = !isSubmitting && name && !hasLogoError && !hasNameError;

  return (
    <BaseModal onClose={onClose} open={open} title="Paramètres du projet">
      <DialogContent>
        <TextField size="small" disabled value={project.siren} label={sirenInputLabel} color="primary" fullWidth />
        <TextField
          size="small"
          error={!name || hasNameError}
          helperText={getNameInputErrorText()}
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
          onKeyDown={e => e.stopPropagation()} // see : https://github.com/mui/material-ui/issues/19116
          label="Nom du projet"
          placeholder="Nom du projet ..."
          color="primary"
          fullWidth
          sx={{my: 3}}
        />

        {selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.gestion && (
          <TextField size="small" onChange={e => setMonth(e.target.value)} fullWidth select label="Premier mois fiscal" value={month}>
            {MONTHS.map(m => (
              <MenuItem key={m.id} value={m.id}>
                {m.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          size="small"
          type="url"
          value={logo}
          error={hasLogoError}
          helperText={hasLogoError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.url : ''}
          onChange={event => {
            setLogo(event.target.value);
          }}
          onKeyDown={e => e.stopPropagation()} // see : https://github.com/mui/material-ui/issues/19116
          label="Url du logo"
          color="primary"
          fullWidth
          placeholder="https://..."
          sx={{marginTop: 3}}
        />

        <Box mt={3}>
          <Spinner isLoading={isSubmitting} />
        </Box>
      </DialogContent>
      <DialogActions mt={0} sx={{flexDirection: 'column'}}>
        <Button size="large" disabled={!canSubmit} fullWidth variant="contained" color="secondary" onClick={handleSubmit}>
          Sauvegarder
        </Button>
        {updateError !== '' && <ErrorMessage message={updateError} />}
      </DialogActions>
    </BaseModal>
  );
};

UpdateProjectModal.propTypes = {
  onClose: func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  open: bool.isRequired
};

export default UpdateProjectModal;

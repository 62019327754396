import defaultAnimationfile from '../lotties/spinner/drivnSpinnerAnimation.json';

const getCompanySpinnerAnimation = () => {
  const filename = `${process.env.REACT_APP_COMPANY_NAME}SpinnerAnimation.json`;
  try {
    const animationFile = require(`../lotties/spinner/${filename}`); // eslint-disable-line global-require
    return animationFile;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Could not find lottie animation file ${filename} in company-spinner.js`, e);
    return defaultAnimationfile;
  }
};

export default getCompanySpinnerAnimation;

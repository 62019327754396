import Box from '@mui/material/Box';

import {POWER_BI_PAGES, SPREADSHEET_TARGETS} from '../../const';
import useReport from '../../hooks/providers/useReport';
import DefiPreviButtons from './report-buttons/EmbedReportDefiPreviButtons';
import CreateOrUpdateBudgetDialog from './spreadsheet/CreateOrUpdateBudgetDialog';
import DeleteBudgetDialog from './spreadsheet/DeleteBudgetDialog';
import SpreadsheetModal from './spreadsheet/SpreadsheetModal';

const DefiPreviButtonsAndModals = () => {
  const {selectedProject, reportSelectedPage} = useReport();

  if (selectedProject) {
    return (
      <Box sx={{position: 'fixed', bottom: {xs: 4, sm: 10}}}>
        <DefiPreviButtons />
        <SpreadsheetModal target={reportSelectedPage?.name === POWER_BI_PAGES.defiPreviReportBudget.id ? SPREADSHEET_TARGETS.BUDGET : SPREADSHEET_TARGETS.FORECAST} />
        <CreateOrUpdateBudgetDialog />
        <DeleteBudgetDialog />
      </Box>
    );
  }
  return null;
};

export default DefiPreviButtonsAndModals;

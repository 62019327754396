import {useState} from 'react';

import {Print as PrintIcon} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import {bool} from 'prop-types';
import {useLocation} from 'react-router-dom';
import {SpinnerCircular} from 'spinners-react';

import useReport from '../../hooks/providers/useReport';
import HeaderStyledIconButton from './HeaderStyledIconButton';

const HeaderPrintReportButton = ({isMobile = false}) => {
  const [isPrinting, setIsPrinting] = useState(false);

  const {selectedReport} = useReport();
  const location = useLocation();

  const print = report => report.print();

  const handlePrintIconClick = async () => {
    if (!isPrinting) {
      try {
        setIsPrinting(true);
        await print(selectedReport);
        setIsPrinting(false);
      } catch (e) {
        setIsPrinting(false);
      }
    }
  };

  if (!location?.pathname?.startsWith('/reports')) {
    return null;
  }

  if (isMobile) {
    return (
      <MenuItem onClick={handlePrintIconClick}>
        <ListItemIcon>
          <PrintIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Imprimer</ListItemText>
      </MenuItem>
    );
  }

  return (
    <HeaderStyledIconButton onClick={handlePrintIconClick}>
      <Tooltip title={isPrinting ? 'Impression en cours...' : 'Imprimer en PDF'}>{isPrinting ? <SpinnerCircular color="#fff" size={32} /> : <PrintIcon />}</Tooltip>
    </HeaderStyledIconButton>
  );
};

HeaderPrintReportButton.defaultProps = {
  isMobile: false
};

HeaderPrintReportButton.propTypes = {
  isMobile: bool
};

export default HeaderPrintReportButton;

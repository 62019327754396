import {models} from 'powerbi-client';

import {
  POWER_BI_PAGES,
  POWER_BI_REPORT_BOOKMARKS,
  POWER_BI_REPORT_FILTERS,
  POWER_BI_REPORTS,
  POWER_BI_SLICERS,
  DEFAULT_PROJECTS_SCHEMAS,
  VISUALS_TYPES_USER_CAN_SHARE_WITH_COPILOT,
  POWER_BI_VISUALS,
  POWER_BI_PROJECT_SLICER_NAME
} from '../const';
import {generateUniqueIdFromProjectName, getFrenchFormattedDate} from './data-formatting';

const createReportBasicFilter = (table, column, value) => {
  return {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table,
      column
    },
    operator: 'In',
    values: [value],
    filterType: models.FilterType.Basic
  };
};

const createReportRangeFilter = (table, column, values) => {
  const {min, max} = values;
  return {
    $schema: 'http://powerbi.com/product/schema#advanced',
    target: {
      table,
      column
    },
    logicalOperator: 'And',
    conditions: [
      {operator: 'GreaterThanOrEqual', value: min},
      {operator: 'LessThanOrEqual', value: max}
    ],
    filterType: models.FilterType.Advanced
  };
};

const deleteAllFiltersFromLocalStorage = () => {
  const projectId = localStorage.getItem('project');
  const projectFiltersSaved = Object.keys(localStorage).filter(key => key.includes(projectId));
  projectFiltersSaved.forEach(filterId => localStorage.removeItem(filterId));
};

const saveFilterToLocalStorage = (eventData, filterChanged) => {
  const projectId = localStorage.getItem('project');
  let defaultFilterSlicer = POWER_BI_REPORT_FILTERS.filter(f => f.title === filterChanged.title);

  // Sometimes, two slicers have the exact same title, so we have to compare title AND name of slicer
  defaultFilterSlicer = defaultFilterSlicer.length > 1 ? POWER_BI_REPORT_FILTERS.find(f => f.name === filterChanged.name) : defaultFilterSlicer[0];

  const localStorageItemName = `${eventData.report.id}.${projectId}.${defaultFilterSlicer.name}`;
  if (eventData.dataPoints.length > 0) {
    let valueSelected = eventData.dataPoints[0].identity[0].equals;
    if (valueSelected instanceof Date) {
      valueSelected = getFrenchFormattedDate(valueSelected);
    }
    localStorage.setItem(localStorageItemName, valueSelected);
  } else {
    localStorage.removeItem(localStorageItemName);
  }
};

const saveCurrentProjectToLocalStorage = data => {
  const projectName = data.dataPoints[0].identity[0].equals;
  localStorage.setItem('project', generateUniqueIdFromProjectName(projectName));
};

const setProjectFilterSlicer = async (report, projectName, module) => {
  const targetTable = module === DEFAULT_PROJECTS_SCHEMAS.gestion || module === DEFAULT_PROJECTS_SCHEMAS.previ ? 'siren_parameters' : 'z siren_parameters';
  const filter = createReportBasicFilter(targetTable, 'project_name', projectName);

  const page = await report.getActivePage();
  const visuals = await page.getVisuals();
  const projectFilterSlicer = visuals.find(f => f.title === POWER_BI_PROJECT_SLICER_NAME);
  await projectFilterSlicer.setSlicerState({
    filters: [filter]
  });
};

const initializeFiltersFromLocalStorage = async (report, visuals) => {
  const projectId = localStorage.getItem('project');

  // eslint-disable-next-line no-restricted-syntax
  for (const reportFilter of POWER_BI_REPORT_FILTERS) {
    const localStorageFilterKey = `${report.config.id}.${projectId}.${reportFilter.name}`;
    const filterSavedValue = localStorage.getItem(localStorageFilterKey);
    const filterSlicer = visuals.filter(visual => visual.name === reportFilter.name)[0];

    if (filterSavedValue && filterSlicer) {
      const powerBiFilter = createReportBasicFilter(reportFilter.table, reportFilter.column, filterSavedValue);
      // eslint-disable-next-line no-await-in-loop
      await filterSlicer.setSlicerState({
        filters: [powerBiFilter]
      });
    }
  }
};

const resetReportFilters = async report => {
  try {
    const pages = await report.getPages();
    const homepage = pages.filter(p => p.displayName === POWER_BI_PAGES.reportHome.title)[0];
    const homepageVisuals = await homepage.getVisuals();

    // eslint-disable-next-line no-restricted-syntax
    for (const reportFilter of POWER_BI_REPORT_FILTERS) {
      const filterSlicer = homepageVisuals.filter(visual => visual.name === reportFilter.name)[0];
      // Slicer f8c4f35048580c801620 is the project slicer. We do not want to set it to null.
      if (filterSlicer && filterSlicer.name !== 'f8c4f35048580c801620') {
        // eslint-disable-next-line no-await-in-loop
        await filterSlicer.setSlicerState({
          filters: []
        });
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

const saveUserChoiceConcerningFiltersToLocalStorage = (userWantsToSaveFilters = false) => {
  const projectId = localStorage.getItem('project');
  const localStorageKey = `saveFilters.${projectId}`;
  localStorage.setItem(localStorageKey, userWantsToSaveFilters);
};

const userWantsToSaveFilters = () => {
  const projectId = localStorage.getItem('project');
  const localStorageKey = `saveFilters.${projectId}`;
  return localStorage.getItem(localStorageKey);
};

const closeAllFiltersBookmarks = async (report, bookmarks) => {
  const closeFiltersBookmarks = bookmarks.find(b => b.name === POWER_BI_REPORT_BOOKMARKS.openOrCloseFilters.name)?.children.filter(c => c.displayName.includes('popdown'));
  return Promise.all(closeFiltersBookmarks.map(b => report.bookmarksManager.apply(b.name)));
};

const getCompanyReportTheme = () => {
  const {
    REACT_APP_COMPANY_NAME,
    REACT_APP_REPORT_COLOR_1,
    REACT_APP_REPORT_COLOR_2,
    REACT_APP_REPORT_COLOR_3,
    REACT_APP_REPORT_COLOR_4,
    REACT_APP_REPORT_COLOR_5,
    REACT_APP_REPORT_COLOR_6,
    REACT_APP_REPORT_COLOR_7,
    REACT_APP_REPORT_COLOR_8
  } = process.env;

  const themeJson = {
    name: `${REACT_APP_COMPANY_NAME} theme`,
    dataColors: [
      REACT_APP_REPORT_COLOR_1,
      REACT_APP_REPORT_COLOR_2,
      REACT_APP_REPORT_COLOR_3,
      REACT_APP_REPORT_COLOR_4,
      REACT_APP_REPORT_COLOR_5,
      REACT_APP_REPORT_COLOR_6,
      REACT_APP_REPORT_COLOR_7,
      REACT_APP_REPORT_COLOR_8
    ]
  };

  return themeJson;
};

const applyDefiPreviForecastTreshold = async (currentPage, currentBookmark, data) => {
  try {
    const slicerId = currentBookmark === POWER_BI_REPORT_BOOKMARKS.impositionTabDefiPrevi.name ? POWER_BI_SLICERS.thresholdImposition.id : POWER_BI_SLICERS.thresholdTreasury.id;

    const thresholdSlicer = await currentPage.getVisualByName(slicerId);

    const table = 'forecast_slicer_series';
    const column = 'series_values';
    const values = currentBookmark === POWER_BI_REPORT_BOOKMARKS.treasuryTabDefiPrevi.name ? data.filter(items => items.is_cash) : data.filter(items => !items.is_cash);
    const min = values.find(item => !item.is_positive).ecart_type;
    const max = values.find(item => item.is_positive).ecart_type;

    const filter = createReportRangeFilter(table, column, {min: -min, max});

    await thresholdSlicer.setSlicerState({
      filters: [filter]
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

const getCurrentSelectedBudgetScenario = async report => {
  const currentPage = await report.getActivePage();
  const selectedScenarioVisual = await currentPage.getVisualByName(POWER_BI_SLICERS.chooseBudget.id);
  const selectedScenarioSlicerState = await selectedScenarioVisual.getSlicerState();
  return selectedScenarioSlicerState.filters[0].values[0];
};

const getReportIframeHeight = (windowHeight, bannerHeight, hasButtons) => {
  const headerHeight = 64;
  const buttonHeight = hasButtons ? 37 : 0;

  return windowHeight - headerHeight - bannerHeight - buttonHeight;
};

const getReportsIdsHavingUploadButton = reports => {
  return reports
    .filter(report => {
      const r = POWER_BI_REPORTS.find(item => item.name === report.report_name);
      return r?.module === DEFAULT_PROJECTS_SCHEMAS.gestion;
    })
    .map(r => r.report_id);
};

const getReportAssociatedModule = reportName => {
  const report = POWER_BI_REPORTS.find(item => item.name === reportName);

  return report?.module;
};

const getAllVisualsOfReport = async report => {
  const pages = await report.getPages();
  const promises = pages.map(p => p.getVisuals());
  const allVisuals = await Promise.all(promises);
  return allVisuals.flat();
};

const getVisualsUserCanSendThroughChatbot = async (report, reportSchema) => {
  const visuals = await getAllVisualsOfReport(report);
  const allowedVisualsByTypes = visuals.filter(v => VISUALS_TYPES_USER_CAN_SHARE_WITH_COPILOT.includes(v.type));

  // When inside a custom report (not a DeFi one), user can choose any visuals.
  const isCustomReport = !Object.values(DEFAULT_PROJECTS_SCHEMAS).includes(reportSchema);
  if (isCustomReport) {
    return allowedVisualsByTypes;
  }
  // We filter mobile visuals, user cannot select them (for now, only done by title)
  const desktopVisuals = allowedVisualsByTypes.filter(v => !v.title.toLowerCase().includes('mobile'));
  // We filter visuals that don't have a title
  const visualsWithTitle = desktopVisuals.filter(v => v.title !== '');
  // Business rule : for now we filter visuals named "Matrice"
  const visualsWithoutMatrixes = visualsWithTitle.filter(v => v.title !== 'Matrice');

  return visualsWithoutMatrixes;
};

const shouldUpdateToken = async tokenExpiration => {
  const MINUTES_BEFORE_EXPIRATION = 2;
  const currentTime = Date.now();
  const expiration = typeof tokenExpiration === 'number' ? tokenExpiration : Date.parse(tokenExpiration);

  const timeUntilExpiration = expiration - currentTime;
  const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000; // We consider refreshing token 2 minutes before its expiration

  return timeUntilExpiration <= timeToUpdate;
};

const extractThemeStringFromSlicer = slicerValue => {
  if (Array.isArray(slicerValue)) {
    return slicerValue[0].value;
  }
  const match = slicerValue.match(/\[(.*?)\]/);
  return match ? match[1] : null;
};

const getSelectedTabInChargesPage = async page => {
  const selectedChargesTabSlicer = await page.getVisualByName(POWER_BI_SLICERS.chargesTabs.id);
  const selectedChargesTabValue = (await selectedChargesTabSlicer.getSlicerState()).filters[0].values[0];
  const theme = extractThemeStringFromSlicer(selectedChargesTabValue);
  return theme;
};

function powerBiCsvToJson(csvData) {
  const lines = csvData.trim().split('\n');
  const headers = lines[0].split(',');

  const jsonArray = lines.slice(1).map(line => {
    const values = line.split(',');
    const obj = {};
    headers.forEach((header, index) => {
      let value = values[index].trim();
      if (header.trim() === 'Montant') {
        value = parseFloat(value);
      }
      obj[header.trim()] = value;
    });
    return obj;
  });

  return jsonArray;
}

const getAccountantExpectedElementsVisualJsonData = async page => {
  try {
    const journalVisual = await page.getVisualByName(POWER_BI_VISUALS.accountantExpectedElements.name);
    if (journalVisual) {
      const {data} = await journalVisual.exportData(models.ExportDataType.Summarized);
      const jsonFormattedData = powerBiCsvToJson(data);
      return jsonFormattedData;
    }
    return [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error({error});
    return [];
  }
};

export {
  createReportBasicFilter,
  saveFilterToLocalStorage,
  deleteAllFiltersFromLocalStorage,
  saveCurrentProjectToLocalStorage,
  initializeFiltersFromLocalStorage,
  saveUserChoiceConcerningFiltersToLocalStorage,
  userWantsToSaveFilters,
  resetReportFilters,
  closeAllFiltersBookmarks,
  getCompanyReportTheme,
  applyDefiPreviForecastTreshold,
  setProjectFilterSlicer,
  getCurrentSelectedBudgetScenario,
  getReportIframeHeight,
  getReportsIdsHavingUploadButton,
  getReportAssociatedModule,
  getVisualsUserCanSendThroughChatbot,
  shouldUpdateToken,
  getSelectedTabInChargesPage,
  getAccountantExpectedElementsVisualJsonData
};

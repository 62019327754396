import {Search} from '@mui/icons-material';
import {InputAdornment, TextField} from '@mui/material';
import {func, shape, string} from 'prop-types';

const SearchBar = ({placeholder, value, setValue, sx}) => {
  return (
    <TextField
      color="primary"
      size="small"
      InputProps={{
        sx: {
          borderRadius: 30,
          ...sx
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search color="primary" />
          </InputAdornment>
        )
      }}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      placeholder={placeholder}
      onKeyDown={e => e.stopPropagation()}
    />
  );
};

SearchBar.defaultProps = {
  value: null,
  sx: {}
};

SearchBar.propTypes = {
  placeholder: string.isRequired,
  value: string,
  setValue: func.isRequired,
  sx: shape({})
};

export default SearchBar;

import {useEffect, useRef, useState} from 'react';

import RecordRTC, {StereoAudioRecorder} from 'recordrtc';

import {MAXIMUM_TRANSCRIPT_AUDIO_FILE_SIZE, TRANSCRIPT_AUDIO_FILE_SIZE_WARNING_MESSAGE} from '../../const';

const useAudioRecorder = (mimeType = 'audio/mpeg') => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedUrl, setRecordedUrl] = useState('');
  const [base64Data, setBase64Data] = useState('');
  const [userHasDeclinedMicrophonePermission, setUserHasDeclinedMicrophonePermission] = useState(false);

  const [audioWillSoonExceedMaximumSize, setAudioWillSoonExceedMaximumSize] = useState(false);
  const [audioExceedsMaximumSize, setAudioExceedsMaximumSize] = useState(false);
  const [currentRecordingSize, setCurrentRecordingSize] = useState(0);
  const [stream, setStream] = useState(null);

  const mediaStream = useRef(null);
  const recorder = useRef(null);

  const bytesToMegaBytes = bytes => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };

  const convertBlobToBase64 = blob => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64WithoutPrefix = reader.result.split('base64,')[1];
      setBase64Data(base64WithoutPrefix);
    };
    reader.readAsDataURL(blob);
  };

  const resetRecording = () => {
    setUserHasDeclinedMicrophonePermission(false);
    setAudioWillSoonExceedMaximumSize(false);
    setAudioExceedsMaximumSize(false);
    setCurrentRecordingSize(0);
    setRecordedUrl('');
    setBase64Data('');
  };

  const setMediaStream = async () => {
    const s = await navigator.mediaDevices.getUserMedia({audio: true});
    setStream(s);
  };

  const startRecording = async () => {
    try {
      resetRecording();

      setIsRecording(true);
      mediaStream.current = stream;
      recorder.current = new RecordRTC(stream, {
        type: 'audio',
        recorderType: StereoAudioRecorder,
        mimeType,
        timeSlice: 500,
        ondataavailable: blob => {
          setCurrentRecordingSize(prevSize => prevSize + blob.size);
        }
      });

      recorder.current.startRecording();
    } catch (error) {
      setIsRecording(false);
      // eslint-disable-next-line no-console
      console.error('Microphone error:', error);
      if (error.name === 'NotAllowedError') {
        setUserHasDeclinedMicrophonePermission(true);
      }
    }
  };

  const stopRecording = (shouldSaveRecord = true) => {
    if (recorder.current && recorder.current.getState() === 'recording') {
      recorder.current.stopRecording(() => {
        if (shouldSaveRecord) {
          const recordedBlob = recorder.current.getBlob();
          const url = URL.createObjectURL(recordedBlob);
          setRecordedUrl(url);
          convertBlobToBase64(recordedBlob);
        }
      });
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach(track => {
        track.stop();
      });
      setMediaStream();
    }
    setIsRecording(false);
  };

  // TODO if this hook is going to be re-used, we will delocate this logic inside appropriate component
  useEffect(() => {
    const estimatedFileSizeInMegaBytes = bytesToMegaBytes(currentRecordingSize);
    if (estimatedFileSizeInMegaBytes > MAXIMUM_TRANSCRIPT_AUDIO_FILE_SIZE) {
      setAudioExceedsMaximumSize(true);
      setAudioWillSoonExceedMaximumSize(false);
      stopRecording();
    } else if (estimatedFileSizeInMegaBytes > TRANSCRIPT_AUDIO_FILE_SIZE_WARNING_MESSAGE) {
      setAudioWillSoonExceedMaximumSize(true);
    }
  }, [currentRecordingSize]);

  return {
    isRecording,
    recordedUrl,
    base64Data,
    setBase64Data,
    startRecording,
    stopRecording,
    userHasDeclinedMicrophonePermission,
    audioWillSoonExceedMaximumSize,
    audioExceedsMaximumSize,
    estimatedFileSize: bytesToMegaBytes(currentRecordingSize),
    setMediaStream
  };
};

export default useAudioRecorder;

import {useEffect} from 'react';

import Grid from '@mui/material/Grid';

import {BREAKPOINTS, POWER_BI_PAGES} from '../../../const';
import useBreakpoint from '../../../hooks/dom/useBreakpoint';
import useReport from '../../../hooks/providers/useReport';
import {isDemoProject} from '../../../utils';
import Button from '../../form/buttons/Button';
import SharedButton from '../../form/buttons/SharedButton';

// eslint-disable-next-line complexity
const EmbedReportDefiPreviButtons = () => {
  const {
    setReportDataSuccessfullyUpdated,
    reportDataSuccessfullyUpdated,
    setIsCreateOrUpdateBudgetFormOpen,
    setIsCreateBudgetForm,
    selectedBudget: budgetName,
    selectedProject: project,
    isSpreadsheetModalOpen,
    setIsSpreadsheetModalOpen,
    reportSelectedPage
  } = useReport();

  const shouldDisplay = reportSelectedPage.name !== POWER_BI_PAGES.defiPreviReportTreasury.id;
  const editBudgetButtonDisabled = budgetName === null;
  const isBudgetForecastPage = reportSelectedPage.name === POWER_BI_PAGES.defiPreviReportBudget.id;
  const isHistoryForecastPage = reportSelectedPage.name === POWER_BI_PAGES.defiPreviReportHome.id;
  const areButtonsVisible = isBudgetForecastPage || isHistoryForecastPage;
  const currentBreakpoint = useBreakpoint();

  const openOrCloseSpreadsheet = () => {
    setIsSpreadsheetModalOpen(!isSpreadsheetModalOpen);
  };

  // When reportDataSuccessfullyUpdated , submit button is disabled and shows a success text.
  // We want to reset the button to the default state after 3 seconds.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (reportDataSuccessfullyUpdated) {
      const INTERVAL_TIME = 3000;
      const interval = setInterval(async () => {
        setReportDataSuccessfullyUpdated(false);
      }, INTERVAL_TIME);

      return () => clearInterval(interval);
    }
  }, [reportDataSuccessfullyUpdated]);

  const rightButtonLongText = budgetName ? `Modifier ${budgetName}` : 'Modifier un budget';
  const rightSharedButtonText = currentBreakpoint === BREAKPOINTS.xs ? 'Modifier' : rightButtonLongText;
  const leftSharedButtonText = currentBreakpoint === BREAKPOINTS.xs ? 'Créer' : 'Créer un budget';

  if (shouldDisplay && areButtonsVisible) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          {isBudgetForecastPage && (
            <SharedButton
              leftButtonText={leftSharedButtonText}
              rightButtonText={rightSharedButtonText}
              leftButtonProps={{
                color: 'lightPrimary',
                variant: 'contained',
                onClick: () => {
                  setIsCreateOrUpdateBudgetFormOpen(true);
                  setIsCreateBudgetForm(true);
                }
              }}
              rightButtonProps={{
                disabled: editBudgetButtonDisabled,
                onClick: openOrCloseSpreadsheet,
                color: 'success',
                variant: 'contained',
                hasTooltip: editBudgetButtonDisabled,
                tooltipText: 'Veuillez sélectionner un budget.'
              }}
            />
          )}

          {isHistoryForecastPage && (project.is_owner || isDemoProject(project)) && (
            <Button onClick={openOrCloseSpreadsheet} variant="contained" color="success">
              {currentBreakpoint === BREAKPOINTS.lg ? "Modifier les données d'ajustements" : 'Modifier les données'}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default EmbedReportDefiPreviButtons;

import {Group} from '@mui/icons-material';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';
import {projectShape} from '../../../../proptypes-shapes';
import ProjectActionButton from '../ProjectActionButton';
import ProjectMenuManageUsersButton from './ProjectMenuManageUsersButton';

const ManageProjectUsersButton = ({project}) => {
  const {setManageProjectUsersModalOpen} = useProjects();
  const {openModal} = useProjectCrudModal({setModalOpen: setManageProjectUsersModalOpen, project});

  return <ProjectActionButton cardMarkup={<ProjectMenuManageUsersButton />} listMarkup={<Group sx={{cursor: 'pointer'}} fontSize="small" color="primary" onClick={openModal} />} />;
};

ManageProjectUsersButton.defaultProps = {
  project: null
};

ManageProjectUsersButton.propTypes = {
  project: projectShape
};

export default ManageProjectUsersButton;

import useProjects from '../providers/useProjects';

const useProjectCrudModal = ({setModalOpen, project}) => {
  const {setSelectedProject} = useProjects();

  const openModal = () => {
    setModalOpen(true);
    setSelectedProject(project);
  };

  return {openModal};
};

export default useProjectCrudModal;

import {bool} from 'prop-types';
import {useNavigate} from 'react-router-dom';

import useProjects from '../../hooks/providers/useProjects';
import useReport from '../../hooks/providers/useReport';
import Button from '../form/buttons/Button';

const SeeCustomReportButton = ({isCustomReport, isNoticeReport}) => {
  const navigate = useNavigate();
  const {currentReport, selectedReportTab} = useProjects();
  const {embedToken, reports} = useReport();

  const report = reports.find(r => r.report_id === selectedReportTab);
  const isDisabled = !embedToken || (!report && isCustomReport && !isNoticeReport);
  const hasTooltip = !report && isCustomReport && !isNoticeReport;
  const tooltipText = "Aucun Rôle fourni à l'import de ce rapport. Veuillez supprimer et réimporter le rapport en nommant le Rôle associé aux rapport.";

  const handleClick = () => {
    const urlToReport = `/reports/${currentReport.report_name}`;
    localStorage.setItem('reportId', currentReport.report_id);

    navigate(urlToReport);
  };

  const urlToReport = `/reports/${currentReport.report_name}`;

  return (
    <Button
      hasTooltip={hasTooltip}
      tooltip={hasTooltip && tooltipText}
      onClick={() => navigate(urlToReport, {state: {reportId: currentReport.report_id}})}
      sx={{margin: '0 auto', display: 'block'}}
      size="large"
      disabled={isDisabled}
      variant="contained"
      color="secondary"
    >
      Consulter {isCustomReport ? 'le rapport' : 'la notice'}
    </Button>
  );
};

SeeCustomReportButton.defaultProps = {
  isNoticeReport: false
};

SeeCustomReportButton.propTypes = {
  isCustomReport: bool.isRequired,
  isNoticeReport: bool
};

export default SeeCustomReportButton;

import VisibilityIcon from '@mui/icons-material/Visibility';
import {Tooltip} from '@mui/material';
import {bool} from 'prop-types';

const InvitedOnProjectIcon = ({isOwner}) => {
  return !isOwner ? (
    <Tooltip title="Vous êtes invité sur ce projet. Celui-ci ne compte pas dans le nombre de projets créé">
      <VisibilityIcon aria-label="icone invité" color="primary" />
    </Tooltip>
  ) : null;
};

InvitedOnProjectIcon.propTypes = {
  isOwner: bool.isRequired
};

export default InvitedOnProjectIcon;

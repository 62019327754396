import {string} from 'prop-types';

import useAuth from '../../hooks/providers/useAuth';
import Button from '../form/buttons/Button';

const OpenContactFormButton = ({text}) => {
  const {setIsContactFormOpen} = useAuth();

  return (
    <Button sx={{textDecoration: 'underline', fontSize: 14}} size="small" variant="text" onClick={() => setIsContactFormOpen(true)}>
      {text}
    </Button>
  );
};

OpenContactFormButton.propTypes = {
  text: string.isRequired
};

export default OpenContactFormButton;

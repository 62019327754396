import {Typography} from '@mui/material';
import styled from 'styled-components';

import useProjects from '../../../hooks/providers/useProjects';
import PasswordInputRevealable from '../../form/PasswordInputRevealable';
import InputLabel from './InputLabel';

const List = styled.ul`
  font-size: 13px;
  padding: 0 40px;
  margin-bottom: 24px;
`;

const ListItem = styled.li`
  list-style-type: disc;
  margin-top: 2px;
`;

const UnisoftConnectorForm = () => {
  const {createProjectApiKeyError, setCreateProjectApiKeyError, apiKey, setApiKey, createProjectSirenError} = useProjects();

  return (
    <>
      <Typography sx={{fontSize: 13, mb: 1, fontWeight: 'bold'}}>Pour récupérer votre clé d'API, procédez aux étapes suivantes :</Typography>
      <List>
        <ListItem>Dans votre votre société MyUnisoft, allez dans paramètres > Tenues > Connecteurs</ListItem>
        <ListItem>
          Sélectionnez le connecteur <span style={{fontWeight: 'bold'}}>Drivn</span>
        </ListItem>
        <ListItem>Cliquez sur "Générer"</ListItem>
      </List>
      <InputLabel>Copiez la clé d'API générée et collez-là ci-dessous :</InputLabel>

      <PasswordInputRevealable
        required
        error={createProjectApiKeyError || createProjectSirenError !== ''}
        helperText={createProjectApiKeyError || createProjectSirenError}
        size="small"
        value={apiKey}
        color="primary"
        label="Clé d'api"
        fullWidth
        onChange={event => {
          setApiKey(event.target.value);
          setCreateProjectApiKeyError('');
        }}
      />
    </>
  );
};

UnisoftConnectorForm.propTypes = {};

export default UnisoftConnectorForm;

import Box from '@mui/material/Box';

import usePwa from '../../../hooks/providers/usePwa';
import {capitalizeWord} from '../../../utils';
import Button from '../../form/buttons/Button';

const AndroidPwaPrompt = () => {
  const {handleWebInstallAccept: onAccept} = usePwa();
  const companyName = capitalizeWord(process.env.REACT_APP_HUMANIZED_NAME);

  return (
    <Box px={1} pb={2}>
      <Button variant="contained" color="primary" size="large" sx={{width: '50vw'}} onClick={onAccept}>
        Installer {companyName}
      </Button>
    </Box>
  );
};

export default AndroidPwaPrompt;

import styled from 'styled-components';

const HeaderStyledIconButton = styled.div`
  padding: 4px;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  background: #ffffff15;
  border-radius: 50%;
  cursor: pointer;
  color: white;

  svg {
    font-size: 20px;
  }

  :hover svg {
    opacity: 0.7;
  }

  :active {
    opacity: 1;
  }
`;

export default HeaderStyledIconButton;

import {useEffect} from 'react';

import {oneOfType, node, func, number} from 'prop-types';

import useAuth from '../hooks/providers/useAuth';
import useLoading from '../hooks/providers/useLoading';

const RequireNecessaryData = ({numberOfApiCallsToMake = null, children}) => {
  const {setGlobalLoading, setTotalNumberOfApiCalls} = useLoading();
  const auth = useAuth();

  useEffect(() => {
    if (numberOfApiCallsToMake) {
      setTotalNumberOfApiCalls(numberOfApiCallsToMake);
    }
    setGlobalLoading(false);
  }, []);

  useEffect(() => {
    if (auth.loggedIntoBadPartnerErrorMessage) {
      setGlobalLoading(false);
      // This will be automatically handled by React Router and display root ErrorComponent
      throw new Response('Logged into bad partner', {status: 401});
    }
  }, [auth.loggedIntoBadPartnerErrorMessage]);

  return children;
};

RequireNecessaryData.defaultProps = {
  numberOfApiCallsToMake: null
};

RequireNecessaryData.propTypes = {
  numberOfApiCallsToMake: number,
  children: oneOfType([node, func]).isRequired
};

export default RequireNecessaryData;

import {Typography} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

const InputLabel = muiStyled(Typography)(({theme}) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  fontWeight: 'bold',
  fontSize: 12
}));

export default InputLabel;

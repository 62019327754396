import {useEffect, useState} from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import {Grid, IconButton} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {APP_ROUTES, BREAKPOINTS} from '../../const';
import useBreakpoint from '../../hooks/dom/useBreakpoint';
import useReport from '../../hooks/providers/useReport';
import LogoSvg from '../../img/company-logo';
import {makeHumanFriendlyUrl} from '../../utils';
import Select from '../form/Select';
import HeaderDesktopIcons from './HeaderDesktopIcons';
import HeaderMobileIconsMenu from './HeaderMobileIconsMenu';
import UserComponent from './UserComponent';

const LogoContainer = styled.div`
  svg {
    min-height: 50px;
    max-height: 64px;
  }

  top: 0px;
  left: 40px;
  display: flex;

  @media screen and (max-width: 900px) {
    left: 12px;
  }
`;

const SelectContainer = styled.div`
  left: 100px;
  height: 64px;
  width: 250px;
  box-sizing: border-box;
  padding-top: 13px;
  padding-bottom: 13px;
  justify-self: flex-start;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 20px;

  @media screen and (max-width: 900px) {
    padding: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: ${process.env.REACT_APP_MAIN_COLOR};
`;

const SelectAndIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: 100%;

  @media screen and (max-width: 360px) {
    max-width: 100px;
  }

  @media screen and (min-width: 360px) and (max-width: 400px) {
    max-width: 160px;
  }

  @media screen and (min-width: 400px) and (max-width: 600px) {
    max-width: 200px;
  }
`;

const MobileMenuContainer = styled.div`
  border-right: solid 1px rgba(255, 255, 255, 0.1);
  padding: 0 10px;
`;

const AppHeader = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [anchorElMobileMenu, setAnchorElMobileMenu] = useState(null);
  const [reportOptions, setReportOptions] = useState([]);

  const navigate = useNavigate();

  const {reports, selectedReport} = useReport();

  const currentBreakpoint = useBreakpoint();

  const isSmallScreen = currentBreakpoint === BREAKPOINTS.xs || currentBreakpoint === BREAKPOINTS.sm;

  useEffect(() => {
    setReportOptions(reports?.map(rep => ({value: rep.report_id, label: rep.report_name})) || []);
  }, [reports]);

  const navigateToReport = (reportName, reportId) => {
    const url = makeHumanFriendlyUrl(APP_ROUTES.report(reportName));
    navigate(url, {
      state: {
        reportId
      }
    });
  };

  const getReportValue = report => (report ? reportOptions.find(rep => rep.value === report.config.id) : null);

  return (
    <HeaderContainer>
      <Header>
        {isSmallScreen && (
          <>
            <HeaderMobileIconsMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} anchorEl={anchorElMobileMenu} />
            <MobileMenuContainer>
              <IconButton
                aria-label="menu"
                sx={{background: '#ffffff15'}}
                onClick={e => {
                  setIsMobileMenuOpen(true);
                  setAnchorElMobileMenu(e.currentTarget);
                }}
              >
                <MenuIcon color="white" sx={{fontSize: 20}} />
              </IconButton>
            </MobileMenuContainer>
          </>
        )}

        <Grid container alignItems="center" flex={1} flexWrap="nowrap">
          <LogoContainer>
            <Link to={APP_ROUTES.home}>
              <LogoSvg isSymbol />
            </Link>
          </LogoContainer>
          <SelectAndIconsContainer>
            <SelectContainer>
              <Select isDisabled={reports.length === 0} options={reportOptions} value={getReportValue(selectedReport)} onChange={({label, value}) => navigateToReport(label, value)} />
            </SelectContainer>

            {!isSmallScreen && <HeaderDesktopIcons />}
          </SelectAndIconsContainer>
        </Grid>

        <UserComponent />
      </Header>
    </HeaderContainer>
  );
};

export default AppHeader;

import {Grid, Typography} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {bool, func, number, oneOfType, shape, string} from 'prop-types';
import styled from 'styled-components';

import Button from '../form/buttons/Button';
import Ribbon from '../ribbon/Ribbon';

const ProductTitle = muiStyled(Typography)(({theme}) => ({
  font: 'normal normal bold 26px/29px InstrumentSans',
  textAlign: 'center',
  color: '#FFFFFF',
  marginBottom: theme.spacing(3)
  // padding: `0 ${theme.spacing(4)}`
}));

const ProductPrice = muiStyled(Typography)(() => ({
  color: '#FFFFFF',
  font: 'normal normal normal 50px/69px SoehneBreitKraftig',
  lineHeight: 1,
  textAlign: 'center'
}));

const ProductPriceDetails = styled.span`
  font-size: 22px;
  font-weight: bold;
  vertical-align: text-top;
  margin-left: -10px;
  line-height: 2;
`;

const ProductDescription = muiStyled(Typography)(({theme}) => ({
  textAlign: 'center',
  font: 'normal normal normal 17px/29px InstrumentSans',
  color: '#FFFFFF',
  marginBottom: theme.spacing(3),
  lineHeight: 1.5,
  margin: ` ${theme.spacing(2)} auto ${theme.spacing(3)} auto`,
  maxWidth: 300
}));

const MultiLineText = ({text}) => {
  return text.split(', ').map((item, i) => (
    <span style={{display: 'block'}} key={i}>
      {item}
    </span>
  ));
};

const ProductCard = ({product, isProductSubscribed, onClick, hasAtLeastOneProductSubscribed, customMaximumNumberOfProjects}) => {
  const {interval: periodicity, amount: price, name, numberOfProjects, description} = product;
  const periodicityWord = periodicity === 'month' ? 'mois' : 'an';
  const backgroundColor = periodicity === 'month' ? `${process.env.REACT_APP_MAIN_COLOR}80` : process.env.REACT_APP_MAIN_COLOR;

  const getButtonText = () => {
    if (isProductSubscribed) {
      return 'Abonnement actuel';
    }

    if (numberOfProjects !== customMaximumNumberOfProjects) {
      return hasAtLeastOneProductSubscribed ? 'Mettre à jour' : 'Choisir';
    }

    return 'Contactez-nous';
  };

  return (
    <Grid container wrap="wrap" direction="column" justifyContent="center" py={4} px={5} sx={{position: 'relative', background: backgroundColor, borderRadius: 10}}>
      {periodicity === 'year' && !isProductSubscribed && <Ribbon text="2 mois offerts" />}
      {numberOfProjects !== customMaximumNumberOfProjects ? (
        <>
          <ProductTitle>{name}</ProductTitle>
          <ProductPrice>
            {price} <ProductPriceDetails>€ht/{periodicityWord}</ProductPriceDetails>
          </ProductPrice>
          <ProductDescription>
            <MultiLineText text={description} />
          </ProductDescription>
        </>
      ) : (
        <ProductDescription sx={{fontSize: 30, fontWeight: 'bold', mb: 4}}>Besoin de plus de projets ?</ProductDescription>
      )}

      <Button disabled={isProductSubscribed} size="large" color="white" variant={isProductSubscribed ? 'outlined' : 'contained'} onClick={onClick}>
        {getButtonText()}
      </Button>
    </Grid>
  );
};

ProductCard.defaultProps = {
  isProductSubscribed: false,
  hasAtLeastOneProductSubscribed: false
};

ProductCard.propTypes = {
  customMaximumNumberOfProjects: string.isRequired,
  onClick: func.isRequired,
  isProductSubscribed: bool,
  hasAtLeastOneProductSubscribed: bool,
  product: shape({
    id: string,
    price_id: string,
    name: string,
    amount: number,
    interval: string,
    numberOfProjects: oneOfType([string, number]),
    description: string
  }).isRequired
};

export default ProductCard;

import {func, node, oneOfType} from 'prop-types';

import useError from '../hooks/providers/useError';
import Maintenance from '../pages/Maintenance';

const ErrorBoundary = ({children}) => {
  const {error} = useError();

  if (error) {
    return <Maintenance title="Application en maintenance" subtitle="Nous vous remercions pour votre patience" />;
  }

  return children;
};

ErrorBoundary.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ErrorBoundary;

import {Group} from '@mui/icons-material';
import {MenuItem, ListItemIcon} from '@mui/material';
import Typography from '@mui/material/Typography';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';

const ProjectMenuManageUsersButton = () => {
  const {setManageProjectUsersModalOpen, selectedProject: project} = useProjects();
  const {openModal} = useProjectCrudModal({setModalOpen: setManageProjectUsersModalOpen, project});

  return (
    <MenuItem onClick={openModal}>
      <ListItemIcon>
        <Group fontSize="small" color="primary" />
      </ListItemIcon>
      <Typography variant="inherit">Gérer les utilisateurs</Typography>
    </MenuItem>
  );
};

export default ProjectMenuManageUsersButton;

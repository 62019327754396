import {TIIME_AUTHORIZE_URL, TIIME_CLIENT_ID, TIIME_OAUTH_CALLBACK_REDIRECT_URI} from '../const';

const generateRandomStateString = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

const saveTiimeRequestStateToLocalStorage = state => {
  localStorage.setItem('tiime-login-request-state', state);
};

const getTiimeRequestStateFromLocalStorage = () => {
  return localStorage.getItem('tiime-login-request-state');
};

const generateTiimeLoginRedirectUrl = () => {
  const scope = 'accounting:read offline_access';
  const audience = 'https://api.tiime.fr/';
  const responseType = 'code';

  const state = generateRandomStateString();
  saveTiimeRequestStateToLocalStorage(state);

  // prettier-ignore
  const url =
        `${TIIME_AUTHORIZE_URL}` +
        `?scope=${scope}` +
        `&client_id=${TIIME_CLIENT_ID}` +
        `&audience=${audience}` +
        `&response_type=${responseType}` +
        `&state=${state}` +
        `&redirect_uri=${TIIME_OAUTH_CALLBACK_REDIRECT_URI}`;

  return url;
};

export {getTiimeRequestStateFromLocalStorage, generateTiimeLoginRedirectUrl};

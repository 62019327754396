import {RuleFolder} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import Grid from '@mui/material/Grid';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../../../const';
import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';
import {projectShape} from '../../../../proptypes-shapes';
import ProjectActionButton from '../ProjectActionButton';
import ProjectMenuUpdateButton from './ProjectMenuUpdateButton';

const UpdateProjectButton = ({project}) => {
  const {setUpdateProjectModalOpen, selectedReportTab} = useProjects();
  const {openModal} = useProjectCrudModal({setModalOpen: setUpdateProjectModalOpen, project});

  const tooltipSentence = "Vous pouvez modifier les paramètres du projet depuis l'onglet DeFi Gestion";

  return (
    <ProjectActionButton
      cardMarkup={<ProjectMenuUpdateButton />}
      listMarkup={
        selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.previ ? (
          <Grid item sx={{cursor: 'help'}}>
            <Tooltip title={tooltipSentence}>
              <RuleFolder fontSize="small" color="disabled" />
            </Tooltip>
          </Grid>
        ) : (
          <RuleFolder sx={{cursor: 'pointer'}} fontSize="small" color="primary" onClick={openModal} />
        )
      }
    />
  );
};

UpdateProjectButton.defaultProps = {
  project: null
};

UpdateProjectButton.propTypes = {
  project: projectShape
};

export default UpdateProjectButton;

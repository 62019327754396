import {QuestionMark, RuleFolder} from '@mui/icons-material';
import {MenuItem, ListItemIcon, Tooltip, Avatar} from '@mui/material';
import Grid from '@mui/material/Grid';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../../../const';
import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';

const ProjectMenuUpdateButton = () => {
  const {setUpdateProjectModalOpen, selectedReportTab, selectedProject: project} = useProjects();
  const {openModal} = useProjectCrudModal({setModalOpen: setUpdateProjectModalOpen, project});

  const theme = useTheme();
  const tooltipSentence = "Vous pouvez modifier les paramètres du projet depuis l'onglet DeFi Gestion";

  return (
    <Grid container alignItems="center">
      <MenuItem sx={{flex: 1}} disabled={selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.previ} onClick={openModal}>
        <ListItemIcon color={theme.palette.primary.main}>
          <RuleFolder fontSize="small" color="primary" />
        </ListItemIcon>
        <Typography variant="inherit">Paramètres du projet</Typography>
      </MenuItem>
      {selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.previ && (
        <Grid item sx={{mr: 1, cursor: 'help'}}>
          <Tooltip title={tooltipSentence}>
            <Avatar sx={{width: 20, height: 20, background: theme.palette.primary.main}}>
              <QuestionMark sx={{width: 12, height: 12, color: '#fff'}} />
            </Avatar>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectMenuUpdateButton;

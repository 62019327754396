import {useEffect, useState} from 'react';

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

import {EMBED_EDITABLE_REPORT_SETTINGS, EMBED_REPORT_DEFAULT_SETTINGS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import Button from '../../form/buttons/Button';

const EditReportButton = () => {
  const {isReportInEditionMode, setIsSaveReportModalOpen, selectedReport, setIsReportInEditionMode} = useReport();
  const [isSwitching, setIsSwitching] = useState(false);

  const handleClick = async () => {
    setIsSwitching(true);

    if (isReportInEditionMode) {
      setIsSaveReportModalOpen(true);
    } else {
      await selectedReport.switchMode('edit');
      await selectedReport.updateSettings(EMBED_EDITABLE_REPORT_SETTINGS);
      setIsReportInEditionMode(true);
    }
    setIsSwitching(false);
  };

  useEffect(() => {
    return () => {
      selectedReport.switchMode('view');
      selectedReport.updateSettings(EMBED_REPORT_DEFAULT_SETTINGS);
    };
  }, []);

  return (
    <Button disabled={isSwitching} onClick={handleClick} variant="contained" color="primary" startIcon={isReportInEditionMode ? <EditIcon /> : <EditOffIcon />}>
      {isReportInEditionMode ? 'Désactiver le mode édition' : 'Activer le mode édition'}
    </Button>
  );
};

export default EditReportButton;

import {getInternationalStandardDateFormat} from '../data-formatting';

const mappingCellTypeAndPropertyToSet = {
  frenchNumber: 'value',
  number: 'value',
  text: 'text',
  tooltipTextCell: 'text',
  dropdown: 'selectedValue',
  date: 'date',
  frenchDate: 'date'
};

const taxRatesDropdownOptions = [
  {label: '0 %', value: '0 %'},
  {label: '5.5 %', value: '5.5 %'},
  {label: '10 %', value: '10 %'},
  {label: '20 %', value: '20 %'}
];

const MOVEMENT_TYPES = ['Ventes', 'Achats', 'Charges de fonctionnement', 'Charges de personnel', 'Autres'];

const movementTypesDropdownOptions = MOVEMENT_TYPES.map(type => ({
  label: type,
  value: type
}));

const FORECAST_COLUMNS_NAMES = {
  date: 'date',
  label: 'label',
  amount: 'amount',
  txTVA: 'txTVA',
  typeMouvement: 'typeMouvement'
};

const FORECAST_COLUMNS_DEFAULT_WIDTHS = {
  date: 150,
  label: 150,
  amount: 150,
  txTVA: 150,
  typeMouvement: 250
};

// This is used to convert VTA tax written in human readable string (like : '20 %', '5.5 %' ...) to valid float format for backend (0.2 , 0.05 ...)
const convertHumanReadableTaxRateToFloat = humanizedTaxRate => {
  const withoutSpacesTaxRate = humanizedTaxRate.trim();
  const match = withoutSpacesTaxRate.match(/^(\d+(\.\d+)?)\s*%$/);
  if (match) {
    return parseFloat(match[1]) / 100;
  }
  return NaN;
};

const getForecastColumns = () => {
  return Object.keys(FORECAST_COLUMNS_NAMES).map(c => ({
    resizable: true,
    columnId: c,
    width: FORECAST_COLUMNS_DEFAULT_WIDTHS[c]
  }));
};

const FORECAST_HEADER_ROW = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'Date', className: 'header-cell'},
    {type: 'header', text: 'Libellé', className: 'header-cell'},
    {type: 'header', text: 'Montant TTC', className: 'header-cell'},
    {type: 'header', text: 'Taux de TVA', className: 'header-cell'},
    {type: 'header', text: 'Type de mouvement', className: 'header-cell'}
  ]
};

const convertFloatTaxRateToHumanReadable = rate => {
  const parsedRate = parseFloat(rate);
  if (typeof parsedRate === 'number' && !Number.isNaN(rate) && rate >= 0 && rate <= 1) {
    const formattedRate = `${(rate * 100).toFixed(1).replace(/\.0$/, '')} %`;
    return formattedRate;
  }
  return rate;
};

const removeEntriesInvalidProperties = baseData => {
  // eslint-disable-next-line no-param-reassign
  return baseData.map(({line, ...entry}) => entry);
};

const formatSpreadsheetDataForUpdate = (baseData, currentData, budget = null) => {
  let formattedData = [...baseData];
  const allEntries = budget ? baseData.filter(e => e.budget_name === budget) : baseData;

  const updatedEntries = currentData.filter(entry => {
    const baseDataEntry = allEntries.find(e => e.line === entry.line && (budget ? e.budget_name === budget : true));
    return JSON.stringify(entry) !== JSON.stringify(baseDataEntry);
  });

  updatedEntries.forEach(entry => {
    const updatedEntry = {...entry};
    const baseEntryIndex = formattedData.findIndex(item => item.line === entry.line && (budget ? item.budget_name === budget : true));
    if (baseEntryIndex !== -1) {
      // Forecast spreadsheet specific data (!budget means we are formatting data for forecast spreadsheet)
      if (entry.txTVA !== formattedData[baseEntryIndex].txTVA && !budget) {
        // VAT rates are stored as human-readable strings in spreadsheet (eg: 5.5 %) and must be converted to a float (eg: 0.055) before sent to backend
        updatedEntry.txTVA = convertHumanReadableTaxRateToFloat(entry.txTVA).toString();
        updatedEntry.amount = entry.amount.toString();
      }
      if (entry.date !== formattedData[baseEntryIndex].date) {
        updatedEntry.date = getInternationalStandardDateFormat(entry.date);
      }
      formattedData[baseEntryIndex] = updatedEntry; // existing entry updated
    } else {
      // Forecast spreadsheet specific data (!budget means we are formatting data for forecast spreadsheet)
      if (!budget) {
        updatedEntry.txTVA = entry.txTVA.toString();
        updatedEntry.amount = entry.amount.toString();
        updatedEntry.date = getInternationalStandardDateFormat(entry.date);
      }
      formattedData.push(updatedEntry); // new entry added
    }
  });

  if (currentData.length < baseData.length) {
    formattedData = formattedData.filter(entry => {
      const entryHasBeenDeleted = currentData.find(item => item.line === entry.line) === undefined;
      return !entryHasBeenDeleted;
    });
  }

  const dataWithoutInvalidBackendProperties = removeEntriesInvalidProperties(formattedData);
  return dataWithoutInvalidBackendProperties;
};

export {
  mappingCellTypeAndPropertyToSet,
  convertFloatTaxRateToHumanReadable,
  getForecastColumns,
  FORECAST_HEADER_ROW,
  taxRatesDropdownOptions,
  FORECAST_COLUMNS_NAMES,
  MOVEMENT_TYPES,
  movementTypesDropdownOptions,
  formatSpreadsheetDataForUpdate
};

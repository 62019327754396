import React, {useState} from 'react';

import DotsIcon from '@mui/icons-material/MoreVertOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import {Grid, List, ListItem, Paper, Tooltip, Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import muiStyled from '@mui/material/styles/styled';
import {func} from 'prop-types';
import {Link} from 'react-router-dom';

import {APP_ROUTES, CONVERSATIONS_TIME_PERIODS_TRANSLATIONS, MAXIMUM_NUMBER_OF_CHABOT_THREADS} from '../../const';
import useChatbot from '../../hooks/providers/useChatbot';
import usePayment from '../../hooks/providers/usePayment';
import {groupThreadsByTime} from '../../utils';
import Button from '../form/buttons/Button';
import SearchBar from '../form/SearchBar';
import Spinner from '../spinner/Spinner';
import ChatbotSingleThreadMenu from './ChabotSingleThreadMenu';
import DeleteThreadModal from './DeleteThreadModal';

const Container = muiStyled(Paper)(() => ({
  position: 'sticky',
  top: 0,
  overflow: 'scroll',
  marginRight: 4,
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));

const ToolbarContainer = muiStyled(Paper)(() => ({
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 9999999,
  maxWidth: 300 - 16,
  padding: '16px 8px'
}));

const TalksList = ({closeDrawer}) => {
  const {
    threads,
    setShouldFetchThreadMessages,
    setCurrentThreadId,
    currentThreadId,
    isThreadLoading,
    setThreadMessages,
    setSelectedAssistant,
    chatbotDataLoading,
    isDeleteThreadModalOpen,
    setIsDeleteThreadModalOpen,
    setThreadIdToDelete,
    setFileIds,
    setChatbotSidebarOpen
  } = useChatbot();
  const {isTrial} = usePayment();
  const theme = useTheme();
  const [isThreadMenuOpen, setIsThreadMenuOpen] = useState(false);
  const [threadMenuAnchorEl, setThreadMenuAnchorEl] = useState(null);
  const [threadNameSearched, setThreadNameSearched] = useState('');

  const switchConversation = id => {
    setCurrentThreadId(id);
    setShouldFetchThreadMessages(true);
    closeDrawer();
    setFileIds([]);
  };

  const openThreadMenu = (e, threadId) => {
    e.stopPropagation();
    setThreadMenuAnchorEl(e.currentTarget);
    setIsThreadMenuOpen(true);
    setThreadIdToDelete(threadId);
  };

  const closeThreadMenu = () => {
    setThreadMenuAnchorEl(null);
    setIsThreadMenuOpen(false);
  };

  const openNewConversation = () => {
    setThreadMessages([]);
    setSelectedAssistant(null);
    setCurrentThreadId(null);
    closeDrawer();
  };

  const filteredThreads = threads.filter(f => f.thread_name.toLowerCase().includes(threadNameSearched.toLowerCase()));
  const groupedThreads = groupThreadsByTime(filteredThreads.sort((a, b) => b.creation_time - a.creation_time));

  const userHasReachedMaximumNumberOfConversations = isTrial && threads.length >= MAXIMUM_NUMBER_OF_CHABOT_THREADS;
  const newConversationButtonDisabled = isThreadLoading || userHasReachedMaximumNumberOfConversations || chatbotDataLoading;

  return (
    <Container elevation={1}>
      <ToolbarContainer elevation={1}>
        <Tooltip
          enterTouchDelay={0}
          title={
            userHasReachedMaximumNumberOfConversations ? (
              <Box pt={0.5} pb={1}>
                <Typography fontSize={11} color="#FFF" mb={1}>
                  Vous ne pouvez créer que {MAXIMUM_NUMBER_OF_CHABOT_THREADS} conversations en période d'essai.
                </Typography>
                <Link
                  to={APP_ROUTES.subscription}
                  onClick={() => {
                    closeDrawer();
                    setChatbotSidebarOpen(false);
                  }}
                >
                  Sélectionner un abonnement
                </Link>
                &nbsp;pour plus de conversations.
              </Box>
            ) : null
          }
        >
          <span>
            <Button
              disabled={newConversationButtonDisabled}
              sx={{mb: 1.5}}
              fullWidth
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<RateReviewOutlinedIcon />}
              onClick={openNewConversation}
            >
              Nouvelle discussion
            </Button>
          </span>
        </Tooltip>

        <SearchBar placeholder="Recherchez une conversation ..." value={threadNameSearched} setValue={setThreadNameSearched} sx={{padding: '0 14px !important', fontSize: 13}} />
      </ToolbarContainer>

      {chatbotDataLoading && (
        <Grid container justifyContent="center" alignItems="center" flex={1}>
          <Spinner textStyle={{fontSize: 15}} text="Chargement des conversations" size={96} isLoading />
        </Grid>
      )}

      <List dense sx={{maxWidth: 300}}>
        {filteredThreads.length === 0 && !chatbotDataLoading && (
          <ListItem>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                fontFamily: 'InstrumentSansBold'
              }}
            >
              Aucune conversation ne correspond à vos critères de recherche
            </ListItemText>
          </ListItem>
        )}
        {Object.entries(groupedThreads).map(([timePeriod, conversations]) => (
          <React.Fragment key={timePeriod}>
            {conversations.length > 0 && !chatbotDataLoading && (
              <Box mb={1.5}>
                <Typography ml={2} fontSize={16} variant="h6" color="primary" fontFamily="SoehneBreitKraftig">
                  {CONVERSATIONS_TIME_PERIODS_TRANSLATIONS[timePeriod]}
                </Typography>
                {conversations.map(t => {
                  const isCurrentConversation = t.thread_id === currentThreadId;
                  const textColor = isCurrentConversation ? '#FFF' : 'text.primary';
                  const backgroundColor = isCurrentConversation ? theme.palette.primary.main : '#FFF';

                  return (
                    <Tooltip key={t.thread_id} placement="right-start" arrow title={t.thread_name}>
                      <ListItem
                        disabled={isThreadLoading && currentThreadId !== t.thread_id}
                        key={t.thread_id}
                        onClick={() => (isThreadLoading ? null : switchConversation(t.thread_id))}
                        sx={{
                          backgroundColor,
                          pl: 2,
                          pr: 1,
                          py: 0.5
                        }}
                      >
                        <ListItemText
                          sx={{cursor: 'pointer'}}
                          primary={t.thread_name}
                          primaryTypographyProps={{
                            noWrap: true,
                            fontSize: 14,
                            color: textColor,
                            fontFamily: isCurrentConversation ? 'InstrumentSansBold' : 'InstrumentSans',
                            style: {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: 220
                            }
                          }}
                        />
                        <ListItemIcon sx={{justifyContent: 'flex-end', cursor: 'pointer'}}>
                          <DotsIcon color={isCurrentConversation ? 'white' : 'primary'} onClick={e => openThreadMenu(e, t.thread_id)} />
                        </ListItemIcon>
                      </ListItem>
                    </Tooltip>
                  );
                })}
              </Box>
            )}
          </React.Fragment>
        ))}
      </List>

      <DeleteThreadModal
        open={isDeleteThreadModalOpen}
        onClose={() => {
          setIsDeleteThreadModalOpen(false);
          closeThreadMenu();
        }}
      />
      <ChatbotSingleThreadMenu onClose={closeThreadMenu} anchorEl={threadMenuAnchorEl} isOpen={isThreadMenuOpen} />
    </Container>
  );
};

TalksList.propTypes = {
  closeDrawer: func.isRequired
};

export default TalksList;

import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import {string} from 'prop-types';

import {PROJECTS_VIEW_TYPES} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import {getHumanReadableSiren, isProjectDemo} from '../../../utils';

const SpaceBetweenTitleAndUsersList = muiStyled(Box)(({theme}) => ({
  height: theme.spacing(2)
}));

const ProjectSiren = muiStyled(Typography)(({theme}) => ({
  fontSize: 12,
  marginBottom: 1,
  color: theme.palette.grey['400']
}));

const Name = muiStyled(Typography)(() => ({
  fontSize: 18
}));

const ProjectName = ({projectName, siren}) => {
  const {viewType} = useProjects();
  const isListViewType = viewType === PROJECTS_VIEW_TYPES.list;

  return (
    <Box flex={isListViewType ? 1 : 0}>
      <Name
        component="div"
        variant="h5"
        sx={{
          lineHeight: isListViewType ? 1 : 1.1
        }}
      >
        {projectName}
      </Name>
      {!isProjectDemo(siren) && (
        <ProjectSiren
          component="div"
          variant="h6"
          sx={{
            lineHeight: isListViewType ? 1 : 'default',
            marginTop: isListViewType ? 1 : 0,
            marginBottom: isListViewType ? 0 : 1
          }}
        >
          {getHumanReadableSiren(siren)}
        </ProjectSiren>
      )}
      {isProjectDemo(siren) && !isListViewType && <SpaceBetweenTitleAndUsersList />}
    </Box>
  );
};

ProjectName.propTypes = {
  projectName: string.isRequired,
  siren: string.isRequired
};

export default ProjectName;

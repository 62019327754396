import {useState, useMemo} from 'react';

import {oneOfType, node, func} from 'prop-types';

import UploadContext from '../contexts/UploadContext';

const UploadProvider = ({children}) => {
  const [isUploadFilesModalOpen, setIsUploadFilesModalOpen] = useState(false);
  const [projectSelected, setProjectSelected] = useState(null);
  const value = useMemo(
    () => ({
      isUploadFilesModalOpen,
      setIsUploadFilesModalOpen,
      projectSelected,
      setProjectSelected
    }),
    [isUploadFilesModalOpen, setIsUploadFilesModalOpen, projectSelected, setProjectSelected]
  );
  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};
UploadProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};
export default UploadProvider;

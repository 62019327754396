import {REGULAR_EXPRESSIONS} from '../const';

const generateUsernameBasedOnEmail = email => {
  const emailBase = email.split('@')[0]; // We want to generate username only with email part before @
  const humanizedEmail = emailBase.replaceAll('.', ' ').replaceAll('_', ' '); // Replace all underscore and dots with spaces
  const splittedEmail = humanizedEmail.split(' ');

  // We take each "word" of splitted email and capitalize it
  // Then we join all words with a space. That's the generated username.
  const username = splittedEmail
    .map(part => {
      const firstLetterCapitalized = part.substring(0, 1).toUpperCase();
      const restOfWord = part.substring(1).toLowerCase();
      return firstLetterCapitalized + restOfWord;
    })
    .join(' ');

  return username;
};

const isPhoneNumberValid = phone => {
  return REGULAR_EXPRESSIONS.phone.test(phone);
};

export {generateUsernameBasedOnEmail, isPhoneNumberValid};

import {TextField, Typography} from '@mui/material';
import {bool} from 'prop-types';

import useProjects from '../../../hooks/providers/useProjects';
import PasswordInputRevealable from '../../form/PasswordInputRevealable';
import SirenTextField from '../../form/SirenTextField';
import InputLabel from './InputLabel';

const PennylaneConnectorForm = ({isSubmitting}) => {
  const {createProjectApiKeyError, setCreateProjectApiKeyError, handleSirenChange, projectCreated, username, setUsername, password, setPassword} = useProjects();

  return (
    <>
      <SirenTextField disabled={projectCreated} onChange={handleSirenChange} />
      {!isSubmitting && (
        <>
          <Typography sx={{fontSize: 13, mb: 1, fontWeight: 'bold'}}>Comment récupérer mon nom d'utilisateur* et mon mot de passe* sur Pennylane ?</Typography>
          <Typography sx={{fontSize: 13, mb: 1}}>Méthode 1 : Demandez à votre expert-comptable de vous les fournir si il gère votre compte</Typography>
          <Typography sx={{fontSize: 13, mb: 1}}>Méthode 2 : Allez dans Paramètres > Connectivité > Partage de données > Power BI</Typography>
          <InputLabel>Copiez et collez le nom d'utilisateur et le mot de passe ci-dessous :</InputLabel>
        </>
      )}
      <TextField
        error={createProjectApiKeyError !== ''}
        required
        size="small"
        value={username}
        label="Nom d'utilisateur"
        color="primary"
        fullWidth
        sx={{mt: 1, mb: 2}}
        onChange={e => {
          setUsername(e.target.value);
          setCreateProjectApiKeyError('');
        }}
      />
      <PasswordInputRevealable
        required
        error={createProjectApiKeyError !== ''}
        helperText={createProjectApiKeyError}
        size="small"
        value={password}
        color="primary"
        label="Mot de passe"
        fullWidth
        onChange={e => {
          setPassword(e.target.value);
          setCreateProjectApiKeyError('');
        }}
      />
      <Typography sx={{fontSize: 13, mt: 1}}>*il ne s'agit pas des identifiants avec lesquels vous vous connectez à Pennylane</Typography>
    </>
  );
};

PennylaneConnectorForm.propTypes = {
  isSubmitting: bool.isRequired
};

export default PennylaneConnectorForm;

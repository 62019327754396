import EventAvailableIcon from '@mui/icons-material/EventAvailableOutlined';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import {Menu, useTheme} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import {bool, element, func, node, object, oneOf, oneOfType} from 'prop-types';

import useReport from '../../../hooks/providers/useReport';

const SpreadsheetMenu = ({isOpen, onClose, anchorEl}) => {
  const theme = useTheme();
  const {setIsCreateOrUpdateBudgetFormOpen, setIsCreateBudgetForm, setIsDeleteBudgetDialogOpen} = useReport();

  return (
    <Menu
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <MenuItem disabled>
        <ListItemIcon sx={{color: theme.palette.primary.main}}>
          <SettingsIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Modifier les paramètres du budget</ListItemText>
      </MenuItem>

      <MenuItem
        onClick={() => {
          setIsCreateOrUpdateBudgetFormOpen(true);
          setIsCreateBudgetForm(false);
        }}
      >
        <ListItemIcon sx={{color: theme.palette.primary.main}}>
          <EventAvailableIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Ajouter une année</ListItemText>
      </MenuItem>

      <MenuItem onClick={() => setIsDeleteBudgetDialogOpen(true)}>
        <ListItemIcon sx={{color: theme.palette.primary.main}}>
          <FolderDeleteOutlinedIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Supprimer un budget</ListItemText>
      </MenuItem>

      <MenuItem disabled>
        <ListItemIcon sx={{color: theme.palette.primary.main}}>
          <DownloadIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Télécharger en Microsoft Excel</ListItemText>
      </MenuItem>

      <MenuItem disabled>
        <ListItemIcon sx={{color: theme.palette.primary.main}}>
          <DownloadIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Télécharger en PDF</ListItemText>
      </MenuItem>
    </Menu>
  );
};

SpreadsheetMenu.defaultProps = {
  anchorEl: null
};

SpreadsheetMenu.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  anchorEl: oneOfType([object, element, node, oneOf([null])])
};

export default SpreadsheetMenu;

import {useEffect, useState} from 'react';

import {useTheme} from '@mui/material';
import {useWindowSize} from '@uidotdev/usehooks';

import {BREAKPOINTS} from '../../const';

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(BREAKPOINTS.xs);
  const windowSize = useWindowSize();
  const theme = useTheme();

  const getCurrentBreakpoint = width => {
    const {xs, sm, md, lg, xl} = theme.breakpoints.values;

    if (width >= xs && width <= sm) return BREAKPOINTS.xs;
    if (width >= sm && width <= md) return BREAKPOINTS.sm;
    if (width >= md && width <= lg) return BREAKPOINTS.md;
    if (width >= lg && width <= xl) return BREAKPOINTS.lg;
    return BREAKPOINTS.xl;
  };

  useEffect(() => {
    const currentBreakpoint = getCurrentBreakpoint(windowSize.width);
    setBreakpoint(currentBreakpoint);
  }, [windowSize]);

  return breakpoint;
};

export default useBreakpoint;

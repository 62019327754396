import {useEffect, useState} from 'react';

import {TextField, DialogContent, DialogActions} from '@mui/material';
import 'dayjs/locale/fr';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../const';
import useReport from '../../hooks/providers/useReport';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import Button from '../form/buttons/Button';
import BaseModal from '../modal/BaseModal';

const EditableReportSaveAsModal = () => {
  const {setIsEditableReportSaveAsModalOpen, isEditableReportSaveAsModalOpen: isOpen, selectedReport, saveAsReport} = useReport();
  const {selectedWorkspaceId, selectedWorkspace, getWorkspaces} = useWorkspaces();
  const [reportName, setReportName] = useState('');

  const resetValues = () => {
    setReportName('');
  };

  const closeModal = () => {
    setIsEditableReportSaveAsModalOpen(false);
  };

  const handleSubmit = async () => {
    await selectedReport.saveAs({
      name: reportName,
      targetWorkspaceId: selectedWorkspaceId
    });
    const isReportSaved = await selectedReport.isSaved();
    if (isReportSaved) {
      await saveAsReport(selectedWorkspaceId, selectedWorkspace.schema_name, reportName);
      await getWorkspaces();
    }
    closeModal();
  };

  useEffect(() => {
    resetValues();
  }, [isOpen]);

  const hasLabelError = reportName !== '' && !REGULAR_EXPRESSIONS.labelWithMinimumChars(4).test(reportName);
  const submitButtonDisabled = hasLabelError || !reportName;

  return (
    <BaseModal
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
      }}
      onClose={closeModal}
      open={isOpen}
      title="Enregistrer le rapport sous"
    >
      <DialogContent>
        <TextField
          sx={{mb: 2, mt: 3}}
          required
          value={reportName}
          onChange={event => {
            setReportName(event.target.value);
          }}
          reportName="Nom du rapport"
          placeholder="Mon rapport 123 ..."
          color="primary"
          fullWidth
          error={hasLabelError}
          helperText={hasLabelError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.labelWithMinimumChars(4) : ''}
        />
      </DialogContent>

      <DialogActions sx={{justifyContent: 'center', mt: 1}}>
        <Button size="large" variant="outlined" color="primary" onClick={closeModal}>
          Fermer
        </Button>
        <Button size="large" variant="contained" color="secondary" disabled={submitButtonDisabled} onClick={handleSubmit}>
          Sauvegarder
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

export default EditableReportSaveAsModal;

import {useEffect, useState} from 'react';

import {TextField, Box, DialogContent, DialogActions} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import {getPossibleBudgetYears} from '../../../utils';
import Button from '../../form/buttons/Button';
import ErrorMessage from '../../form/ErrorMessage';
import FormSelect from '../../form/MuiSelect';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';

// eslint-disable-next-line complexity
const CreateOrUpdateBudgetDialog = () => {
  const {
    isCreateBudgetForm: isCreation,
    createOrUpdateBudget,
    createBudgetError,
    setCreateBudgetError,
    setIsCreateOrUpdateBudgetFormOpen,
    isCreateOrUpdateBudgetFormOpen: isOpen,
    isCreatingOrUpdatingBudget,
    selectedBudget: editedBudgetName,
    selectedBudgetYears,
    alreadyTakenBudgetNames,
    selectedProject: project
  } = useReport();

  const [budgetName, setBudgetName] = useState('');
  const [year, setYear] = useState('');
  const [referenceYear, setReferenceYear] = useState('');

  const alreadyExistingBudgetYears = isCreation ? [] : selectedBudgetYears;
  const possibleBudgetYears = getPossibleBudgetYears(alreadyExistingBudgetYears);

  const title = isCreation ? 'Créer un budget' : 'Ajouter une année';
  const spinnerText = isCreation ? 'Création du budget' : "Ajout de l'année";
  const submitButtonText = isCreation ? 'Créer le budget' : "Ajouter l'année";
  const budgetNameInputLabel = isCreation ? 'Renseignez le nom du budget à créer...' : 'Nom du budget';

  const budgetRegex = REGULAR_EXPRESSIONS.labelWithMinimumChars(4);
  const budgetNameAlreadyTaken = !!(isCreation && alreadyTakenBudgetNames.includes(budgetName));
  const hasLabelError = budgetName !== '' && !budgetRegex.test(budgetName);
  const canSubmit = budgetName && year && referenceYear && !hasLabelError && !budgetNameAlreadyTaken;

  const getBudgetNameErrorText = () => {
    if (hasLabelError) {
      return REGULAR_EXPRESSIONS_ERROR_TEXTS.labelWithMinimumChars(4);
    }
    if (budgetNameAlreadyTaken) {
      return 'Ce nom de budget est déjà utilisé';
    }
    return '';
  };

  useEffect(() => {
    setCreateBudgetError('');
    // If we are updating an existing budget, we have to automatically set the budget name
    if (!isCreation) {
      setBudgetName(editedBudgetName);
    }

    // Clean budget name on modal close
    if (!isOpen) {
      setBudgetName('');
    }
  }, [isOpen, isCreation]);

  return (
    <BaseModal onClose={() => setIsCreateOrUpdateBudgetFormOpen(false)} open={isOpen} title={title}>
      <DialogContent>
        <Box mt={1} mb={2}>
          <TextField
            disabled={!isCreation}
            sx={{mb: 2}}
            value={budgetName}
            onChange={e => setBudgetName(e.target.value)}
            fullWidth
            label={budgetNameInputLabel}
            defaultValue=""
            error={hasLabelError || budgetNameAlreadyTaken}
            helperText={getBudgetNameErrorText()}
          />

          <FormSelect
            sx={{width: '100%', mb: 2}}
            label="Sélectionner une année à budgéter"
            labelId="year-label"
            value={year}
            onChange={e => {
              setYear(e.target.value);
            }}
            items={possibleBudgetYears.map(y => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          />

          <FormSelect
            sx={{width: '100%', mb: 2}}
            label="Sélectionner une année de référence"
            labelId="reference-year-label"
            value={referenceYear}
            onChange={e => {
              setReferenceYear(e.target.value);
            }}
            items={project?.fy_list?.map(({fy}) => (
              <MenuItem key={fy} value={fy}>
                {fy}
              </MenuItem>
            ))}
          />
        </Box>
      </DialogContent>
      {isCreatingOrUpdatingBudget && <Spinner text={spinnerText} isLoading />}

      <DialogActions sx={{justifyContent: 'center'}}>
        <Button
          disabled={isCreatingOrUpdatingBudget || !canSubmit}
          variant="contained"
          color="primary"
          onClick={() =>
            createOrUpdateBudget({
              budgetName: budgetName.trim(),
              budgetYear: year,
              budgetReferenceYear: referenceYear,
              siren: project?.siren
            })
          }
        >
          {submitButtonText}
        </Button>
      </DialogActions>
      {createBudgetError !== '' && (
        <Box px={3} pb={2}>
          <ErrorMessage message={createBudgetError} />
        </Box>
      )}
    </BaseModal>
  );
};

export default CreateOrUpdateBudgetDialog;

/* eslint-disable react/jsx-props-no-spreading */
import CheckIcon from '@mui/icons-material/Check';
import {Switch, styled} from '@mui/material';
import {bool, func} from 'prop-types';

const IOSSwitch = styled(props => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({theme}) => ({
  width: 33,
  height: 18,
  padding: 0,
  overflow: 'visible',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    color: process.env.REACT_APP_MAIN_COLOR,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        height: 18,
        backgroundColor: process.env.REACT_APP_MAIN_COLOR,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: process.env.REACT_APP_MAIN_COLOR,
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    marginTop: 2,
    marginLeft: 1,
    width: 10,
    height: 10
  },
  '& .MuiSwitch-track': {
    height: 15,
    borderRadius: 33 / 2,
    border: `2px solid ${process.env.REACT_APP_MAIN_COLOR}`,
    backgroundColor: 'white',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const CustomSwitch = props => {
  const {onChange, checked, disabled} = props;

  return (
    <div onClick={disabled ? null : onChange} onKeyUp={onChange} role="button" tabIndex={0} style={{cursor: disabled ? 'not-allowed' : 'pointer'}}>
      <IOSSwitch {...props} disabled={disabled} />
      {/* eslint-disable-next-line react/prop-types */}
      {checked && (
        <CheckIcon
          sx={{
            color: 'white !important',
            position: 'absolute',
            left: 6,
            top: 7,
            fontSize: 12,
            fontWeight: 'bold'
          }}
        />
      )}
    </div>
  );
};

CustomSwitch.defaultProps = {
  disabled: false
};

CustomSwitch.propTypes = {
  onChange: func.isRequired,
  checked: bool.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: bool
};

export default CustomSwitch;

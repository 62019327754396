import AddFolderIcon from '@mui/icons-material/CreateNewFolderOutlined';
import {Box, Grid} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {func, string} from 'prop-types';

import {BREAKPOINTS, DEFAULT_PROJECTS_SCHEMAS} from '../../const';
import useBreakpoint from '../../hooks/dom/useBreakpoint';
import usePayment from '../../hooks/providers/usePayment';
import useProjects from '../../hooks/providers/useProjects';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import Button from '../form/buttons/Button';
import SearchBar from '../form/SearchBar';
import EnableOrDisableDemoButton from './EnableOrDisableDemoButton';
import ProjectsCounter from './ProjectsCounter';
import ProjectsViewTypeToggler from './ProjectsViewTypeToggler';

const CreateButtonContainer = muiStyled(Box)(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    borderLeftWidth: 0,
    padding: '0 4px 0 0'
  },
  borderLeftWidth: 1,
  borderRightWidth: 1,
  borderColor: '#DCE0EA',
  borderStyle: 'solid',
  padding: '0 10px'
}));

// eslint-disable-next-line complexity
const ProjectToolBar = ({searchedProjectName, setSearchedProjectName, handleCreateProjectButtonClick}) => {
  const {isGuestMode, isLoading, selectedReportTab} = useProjects();
  const {isDefaultWorkspace} = useWorkspaces();
  const {isAdmin} = usePayment();
  const breakpoint = useBreakpoint();
  // Business rule : if user is invited on a project, he cannot create any new projects.
  // Exception : if demo is activated -user is considered invited on demo projects- he can create projects.
  // Exception : current user is admin and should be able to perform all actions in app.
  const shouldDisplayCreateProjectButton = !isGuestMode || isAdmin;

  const createProjectButtonText = !isDefaultWorkspace || selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.previ ? 'Ajouter un projet' : 'Créer un nouveau projet';

  return (
    <Grid flexWrap="wrap" container mt={2} spacing={breakpoint === BREAKPOINTS.xs ? 0.5 : 2} alignItems="center" justifyContent="center">
      <Grid item xs minWidth={breakpoint === BREAKPOINTS.xs ? '100%' : 350} sx={{maxWidth: {xs: 455, md: 'inherit'}}}>
        <SearchBar placeholder="Recherchez un projet ..." value={searchedProjectName} setValue={setSearchedProjectName} />
      </Grid>
      <Grid item sm="auto" md="auto">
        <Grid container alignItems="center" mt={breakpoint === BREAKPOINTS.xs ? 1.5 : 0}>
          <Grid item sx={{display: {xs: 'none', sm: 'flex'}, mr: 1}}>
            <ProjectsViewTypeToggler />
          </Grid>

          <Grid item>
            <Grid container flexDirection="column" alignItems="center">
              {shouldDisplayCreateProjectButton && !isLoading && (
                <CreateButtonContainer>
                  <Button onClick={handleCreateProjectButtonClick} size={breakpoint === BREAKPOINTS.xs ? 'small' : 'large'} variant="contained" color="secondary" startIcon={<AddFolderIcon />}>
                    {createProjectButtonText}
                  </Button>
                </CreateButtonContainer>
              )}

              <EnableOrDisableDemoButton />
            </Grid>
          </Grid>

          <Grid item ml={{xs: 0.5, sm: 2}}>
            {!isGuestMode && <ProjectsCounter />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ProjectToolBar.propTypes = {
  searchedProjectName: string.isRequired,
  setSearchedProjectName: func.isRequired,
  handleCreateProjectButtonClick: func.isRequired
};

export default ProjectToolBar;

import {useEffect} from 'react';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import {useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const env = process.env.REACT_APP_DB_ENV;

const TRACE_URLS = ['https://*.azurestaticapps.net', 'https://*.drivn.fr'];

const tracePatterns = TRACE_URLS.map(url => {
  const regexPattern = url.replace(/\./g, '\\.').replace(/\*/g, '.*');

  return new RegExp(`^https://${regexPattern}`);
});

const CONSOLE_LOG_LEVELS_CAPTURED = ['log', 'error'];

Sentry.init({
  environment: env,
  dsn: 'https://6ac6f3e05fe8be7dbf04cfafbd31eea6@o4508119548166144.ingest.de.sentry.io/4508126275108944',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: CONSOLE_LOG_LEVELS_CAPTURED
    })
  ],
  attachStacktrace: true, // This allows to attach stack traces event for non-exception events (eg: logs)
  tracesSampleRate: 1.0,
  tracePropagationTargets: tracePatterns,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  initialScope: {
    tags: {client: process.env.REACT_APP_COMPANY_NAME}
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();

import {Grid} from '@mui/material';

import useChatbot from '../../hooks/providers/useChatbot';
import AssistantPictureTooltip from './AssistantPictureTooltip';
import ConversationEventSubtitle from './ConversationEventSubtitle';

const SwitchAssistantWidget = () => {
  const {assistants, setSelectedAssistant, selectedAssistant, scrollConversationToBottom} = useChatbot();
  const nonSelectedAssistants = assistants.filter(a => a.id !== selectedAssistant.id);

  return (
    <Grid container flexDirection="column" mt={3} justifyContent="center">
      <Grid container justifyContent="center" alignItems="center" mb={1}>
        <ConversationEventSubtitle text="Changer d'assistant" />
      </Grid>
      <Grid container spacing={1} justifyContent="center">
        {nonSelectedAssistants.map(a => (
          <Grid
            item
            key={a.id}
            onClick={() => {
              setSelectedAssistant(a);
              setTimeout(() => scrollConversationToBottom('instant'), 10);
            }}
          >
            <AssistantPictureTooltip assistant={a} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default SwitchAssistantWidget;

import Box from '@mui/material/Box';

import AccountantDocumentRequestButton from './AccountantDocumentRequestButton/AccountantDocumentRequestButton';
import CustomizeMappingButton from './CustomizeMappingButton';
import ReadAudioButton from './ReadAudioButton/ReadAudioButton';
import UpdateSummaryButton from './UpdateSummaryButton';

const EmbedReportDefiGestionButtons = () => {
  return (
    <>
      <Box sx={{position: 'fixed', bottom: {xs: 4, sm: 10}}}>
        <UpdateSummaryButton />
        <AccountantDocumentRequestButton />
        <CustomizeMappingButton />
      </Box>
      <ReadAudioButton />
    </>
  );
};

export default EmbedReportDefiGestionButtons;

import WarningIcon from '@mui/icons-material/Warning';
import {createTheme, useTheme, ThemeProvider, Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@mui/material';
import {bool, func} from 'prop-types';
import {Link} from 'react-router-dom';

import {APP_ROUTES} from '../../const';
import Button from '../form/buttons/Button';

const createDialogTheme = parentTheme => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette)
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            padding: 1
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            textAlign: 'center',
            padding: '24px'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            margin: '12px auto'
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 800,
            textAlign: 'center',
            fontSize: 18
          }
        }
      }
    }
  });
};

const MaximumNumberOfProjectsModal = ({onClose, isOpen}) => {
  const parentTheme = useTheme();
  const dialogTheme = createDialogTheme(parentTheme);

  const resetState = () => {
    onClose();
  };

  return (
    <ThemeProvider theme={dialogTheme}>
      <Dialog maxWidth="sm" onClose={resetState} open={isOpen} fullWidth>
        <>
          <DialogTitle>Limite de projets atteinte</DialogTitle>
          <DialogContent dividers>
            <WarningIcon sx={{fontSize: 120, mb: 3}} color="warning" />
            <Typography fontWeight="bold">Vous avez atteint la limite de projets que vous pouvez créer.</Typography>
            <br />
            <p>
              Pour pouvoir créer plus de projets, vous devez{' '}
              <Link style={{color: process.env.REACT_APP_SECONDARY_COLOR}} to={APP_ROUTES.profile}>
                sélectionner un abonnement
              </Link>{' '}
              .
            </p>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" size="large" color="inherit" autoFocus onClick={resetState}>
              Fermer
            </Button>
            <Link to={APP_ROUTES.profile} style={{textDecoration: 'none'}}>
              <Button sx={{ml: 2}} variant="contained" size="large" color="secondary" autoFocus onClick={resetState}>
                Je veux créer plus de projets
              </Button>
            </Link>
          </DialogActions>
        </>
      </Dialog>
    </ThemeProvider>
  );
};

MaximumNumberOfProjectsModal.defaultProps = {
  isOpen: false
};

MaximumNumberOfProjectsModal.propTypes = {
  onClose: func.isRequired,
  isOpen: bool
};

export default MaximumNumberOfProjectsModal;

import {MoreVert} from '@mui/icons-material';
import {IconButton, CardActions, ListItem} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import {useTheme} from '@mui/material/styles';
import muiStyled from '@mui/material/styles/styled';
import {bool} from 'prop-types';
import styled from 'styled-components';

import {CONNECTORS, CONNECTORS_WITH_REFRESH, DEFAULT_PROJECTS_SCHEMAS, PROJECTS_VIEW_TYPES} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import {projectShape} from '../../../proptypes-shapes';
import ProjectCardMenu from '../ProjectCardMenu';
import ActualizeProjectDataButton from './actualize-data-button/ActualizeProjectDataButton';
import CustomizeProjectMappingButton from './cutomize-mapping-button/CustomizeProjectMappingButton';
import DeleteProjectButton from './delete-button/DeleteProjectButton';
import ImportProjectDataButton from './import-data-button/ImportProjectDataButton';
import InvitedOnProjectIcon from './InvitedOnProjectIcon';
import ManageProjectUsersButton from './manage-users-button/ManageProjectUsersButton';
import ProjectLogo from './ProjectLogo';
import ProjectName from './ProjectName';
import SeeReportButton from './SeeReportButton';
import UpdateProjectButton from './update-button/UpdateProjectButton';
import UsersAvatarsList from './UsersAvatarsList';

const CardWrapper = muiStyled(Card)(({theme}) => ({
  width: '100%',
  padding: theme.spacing(2),
  paddingBottom: 0,
  borderRadius: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around'
}));

const CardBody = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  minHeight: 75
}));

const CardFooter = muiStyled(CardActions)(({theme}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: 0,
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1)
}));

const CardContent = muiStyled(MuiCardContent)(() => ({
  padding: '0 0 0 12px !important',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
}));

const MenuContainer = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end'
}));

const Separator = styled.span`
  color: lightgrey;
  margin: 0 16px;
  border-left: 1px solid lightgrey;
  height: 24px;
`;

const ListItemWrapper = muiStyled(ListItem)(() => ({
  minHeight: 50,
  display: 'flex',
  boxShadow: '0px 0px 5px #00000029',
  mb: 1.5,
  alignItems: 'center',
  borderRadius: '5px'
}));

const ListContent = muiStyled(Box)(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const HiddenMdDownContainer = muiStyled(Box)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  display: 'none'
}));

const HiddenMdUpContainer = muiStyled(Box)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none'
  },
  display: 'flex'
}));

// eslint-disable-next-line complexity
const Project = ({project, disabled = false}) => {
  const {project_name: projectName, url_logo: urlLogo, is_owner: isCreator, siren, users} = project;
  const theme = useTheme();
  const {projectCardMenuAnchorEl, setProjectCardMenuAnchorEl, selectedProject, setSelectedProject, viewType, selectedReportTab} = useProjects();
  const open = viewType === PROJECTS_VIEW_TYPES.card ? Boolean(projectCardMenuAnchorEl && selectedProject?.siren === project.siren) : selectedProject?.siren === project.siren;
  const shouldDisplayUploadIconManualProject = project.connector?.toUpperCase() === CONNECTORS.manual && selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.gestion;
  const shouldDisplayRefreshIcon = CONNECTORS_WITH_REFRESH.includes(project.connector?.toUpperCase());

  const cardBackgroundColor = open ? `${theme.palette.primary.main}15` : '#fff';
  const cardBorder = open ? `1px solid ${theme.palette.primary.main}80` : 0;

  const openProjectMenu = event => {
    setProjectCardMenuAnchorEl(event.currentTarget);
    setSelectedProject(project);
  };

  const renderMenuButton = () => {
    return (
      <MenuContainer>
        <IconButton
          disabled={disabled}
          aria-controls={open ? 'project-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={openProjectMenu}
          size="large"
          aria-label="Options"
          sx={{p: 0}}
        >
          <MoreVert color="primary" />
        </IconButton>
        <ProjectCardMenu project={project} />
      </MenuContainer>
    );
  };

  if (viewType === PROJECTS_VIEW_TYPES.card) {
    return (
      <CardWrapper
        elevation={4}
        sx={{
          filter: disabled ? 'grayscale(1)' : '',
          background: cardBackgroundColor,
          border: cardBorder
        }}
      >
        <CardBody>
          <ProjectLogo size={50} urlLogo={urlLogo} />
          <CardContent>
            <ProjectName projectName={projectName} siren={siren} />

            {isCreator && <UsersAvatarsList users={users} />}
          </CardContent>

          {isCreator && shouldDisplayRefreshIcon && <ActualizeProjectDataButton project={project} />}
          {isCreator && renderMenuButton()}
          <InvitedOnProjectIcon isOwner={project.is_owner} />
        </CardBody>

        <CardFooter>
          <SeeReportButton project={project} disabled={disabled} />
        </CardFooter>
      </CardWrapper>
    );
  }

  return (
    <ListItemWrapper
      sx={{
        background: cardBackgroundColor,
        border: cardBorder,
        mt: 1
      }}
    >
      <ProjectLogo size={32} urlLogo={urlLogo} />
      <Separator />

      <ListContent>
        <ProjectName projectName={projectName} siren={siren} />
        <Separator />

        {isCreator && (
          <HiddenMdDownContainer>
            <UsersAvatarsList users={users} />
            <Separator />
          </HiddenMdDownContainer>
        )}
      </ListContent>

      {isCreator && (
        <>
          <HiddenMdDownContainer>
            <UpdateProjectButton project={project} />

            <Separator />
            <ManageProjectUsersButton project={project} />
            <Separator />

            {shouldDisplayUploadIconManualProject && (
              <>
                <ImportProjectDataButton project={project} />
                <Separator />
              </>
            )}
          </HiddenMdDownContainer>

          {shouldDisplayRefreshIcon && (
            <>
              <ActualizeProjectDataButton project={project} />
              <Separator />
            </>
          )}

          <HiddenMdDownContainer>
            <CustomizeProjectMappingButton project={project} />
            <Separator />
          </HiddenMdDownContainer>

          <HiddenMdDownContainer>
            <DeleteProjectButton project={project} />
            <Separator />
          </HiddenMdDownContainer>
        </>
      )}

      <SeeReportButton project={project} disabled={disabled} />

      <HiddenMdUpContainer>
        {isCreator && (
          <>
            <Separator />
            {renderMenuButton()}
          </>
        )}
      </HiddenMdUpContainer>

      {!project.is_owner && <Separator />}
      <InvitedOnProjectIcon isOwner={project.is_owner} />
    </ListItemWrapper>
  );
};

Project.propTypes = {
  disabled: bool.isRequired,
  project: projectShape.isRequired
};

export default Project;

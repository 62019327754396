import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import {bool} from 'prop-types';

import useTranscript from '../../../hooks/providers/useTranscript';
import Button from '../../form/buttons/Button';
import TranscriptWorkingDaySpreadsheet from '../TranscriptWorkingDaySpreadsheet';

const RecordResult = ({isSubmitting}) => {
  const {transcriptionData, setTranscriptionData, getReportClientsForSpreadsheet} = useTranscript();

  const deleteRow = rowId => {
    setTranscriptionData(currentData => [...currentData.filter(entry => entry.id !== rowId)]);
  };

  const addNewRow = () => {
    const ids = transcriptionData.map(e => e.id);
    const maxRowId = transcriptionData.length > 0 ? Math.max(...ids) : 0;
    const newRowId = maxRowId + 1;

    const clients = getReportClientsForSpreadsheet();

    const newEntry = {
      id: newRowId,
      date: new Date(),
      folderOptions: clients,
      folder: '',
      action: '',
      // mission: missions,
      mission: '',
      task: '',
      duration: 0
    };
    setTranscriptionData(previousData => [...previousData, newEntry]);
  };

  if (isSubmitting || !transcriptionData || (transcriptionData && transcriptionData.length === 0)) return null;

  return (
    <Box mt={2}>
      <TranscriptWorkingDaySpreadsheet deleteRow={deleteRow} updateData={setTranscriptionData} data={transcriptionData} />
      <Button onClick={addNewRow} sx={{margin: '0 auto', display: 'flex'}} startIcon={<AddIcon />} size="small" variant="outlined" color="secondary">
        Ajouter une mission
      </Button>
    </Box>
  );
};

RecordResult.propTypes = {
  isSubmitting: bool.isRequired
};

export default RecordResult;

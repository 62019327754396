import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {bool} from 'prop-types';
import {useNavigate} from 'react-router-dom';

import {APP_ROUTES, REPORT_LABELS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import useReport from '../../../hooks/providers/useReport';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import {projectShape} from '../../../proptypes-shapes';
import {makeHumanFriendlyUrl} from '../../../utils';
import Button from '../../form/buttons/Button';

const SeeReportButton = ({project, disabled}) => {
  const {selectedReportTab, pennylaneDataIntegrationInProgress, pennylaneDataIntegrationSiren} = useProjects();
  const {isDefaultWorkspace} = useWorkspaces();
  const {reports, embedToken} = useReport();

  const navigate = useNavigate();

  const getReportData = () => {
    let report;
    if (isDefaultWorkspace) {
      const name = REPORT_LABELS[selectedReportTab];
      const rep = reports.find(r => r.report_name === name);
      if (rep) {
        report = {
          id: rep?.report_id,
          name
        };
      }
    } else {
      const rep = reports.find(r => r.report_id === selectedReportTab);
      if (rep) {
        report = {
          id: rep?.report_id,
          name: rep?.report_name
        };
      }
    }
    return report;
  };

  const report = getReportData();

  const getReportUrl = () => {
    const reportName = report?.name;
    return makeHumanFriendlyUrl(APP_ROUTES.report(reportName));
  };

  const handleClick = () => {
    const url = getReportUrl();
    localStorage.setItem('reportId', report.id);
    // TODO replace that too
    localStorage.setItem('projectSiren', project.siren);
    navigate(url);
  };

  return (
    <Button
      hasTooltip={!report}
      tooltip={!report && 'Rapport bientôt disponible. Veuillez actualiser la page dans quelques minutes.'}
      disabled={!embedToken || reports.length === 0 || !report || disabled || (pennylaneDataIntegrationInProgress && pennylaneDataIntegrationSiren === project.siren)}
      endIcon={<ArrowForwardIcon sx={{marginTop: '1px'}} />}
      size="small"
      variant="contained"
      color="secondary"
      sx={{fontSize: 13}}
      onClick={() => {
        navigate(getReportUrl(), {
          state: {
            reportId: report.id,
            projectSiren: project.siren
          }
        });
      }}
    >
      Voir le rapport
    </Button>
  );
};

SeeReportButton.propTypes = {
  project: projectShape.isRequired,
  disabled: bool.isRequired
};

export default SeeReportButton;

import {Typography} from '@mui/material';

import useChatbot from '../../../hooks/providers/useChatbot';
import ReportSpinner from './ReportSpinner';

const ExportDataLoadingSpinner = () => {
  const {isNavigatingToOtherReportPageToExportData} = useChatbot();

  if (isNavigatingToOtherReportPageToExportData) {
    return (
      <ReportSpinner text="Récupération des données en cours">
        <Typography color="text.secondary" fontSize={13} textAlign="center">
          Veuillez patientez, cette opération peut prendre quelques secondes
        </Typography>
      </ReportSpinner>
    );
  }

  return null;
};

export default ExportDataLoadingSpinner;

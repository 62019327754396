import {TextField, Typography} from '@mui/material';
import {func, string} from 'prop-types';
import styled from 'styled-components';

import useProjects from '../../../hooks/providers/useProjects';
import acdServerExample from '../../../img/acd-server-example.png';
import PasswordInputRevealable from '../../form/PasswordInputRevealable';
import SirenTextField from '../../form/SirenTextField';

const List = styled.ul`
  font-size: 13px;
  padding: 0 40px;
  margin-bottom: 24px;
`;

const ListItem = styled.li`
  list-style-type: disc;
  margin-top: 2px;
`;

const AcdServerExampleImage = styled.img`
  max-width: 100%;
  height: 40px;
`;

const AcdConnectorForm = ({serverISuite, setServerISuite}) => {
  const {createError, createProjectApiKeyError, handleSirenChange, projectCreated, username, setUsername, password, setPassword} = useProjects();

  return (
    <>
      <SirenTextField disabled={projectCreated} onChange={handleSirenChange} />
      <TextField
        error={(createError || createProjectApiKeyError) !== ''}
        required
        size="small"
        value={serverISuite}
        label="Serveur iSuite"
        color="primary"
        fullWidth
        sx={{mb: 1}}
        onChange={e => {
          setServerISuite(e.target.value);
        }}
      />
      <Typography sx={{fontSize: 13, mr: 2, fontWeight: 'bold'}}>Pour récupérer votre serveur iSuite </Typography>
      <List>
        <ListItem>Allez sur votre page de connexion iSuite</ListItem>
        <ListItem>Cliquez sur la barre d'URL. A titre d'exemple, l'adresse URL ressemblera à cela :</ListItem>
        <AcdServerExampleImage src={acdServerExample} alt="" />
        <ListItem>Copier et coller le nom du serveur ("MonServeuriSuite" dans l'exemple) dans le champs ci-dessus.</ListItem>
      </List>

      <TextField
        error={(createError || createProjectApiKeyError) !== ''}
        required
        size="small"
        value={username}
        label="Identifiant ACD"
        color="primary"
        fullWidth
        sx={{mb: 1}}
        onChange={e => {
          setUsername(e.target.value);
        }}
      />
      <PasswordInputRevealable
        required
        error={(createError || createProjectApiKeyError) !== ''}
        helperText={createProjectApiKeyError}
        size="small"
        value={password}
        color="primary"
        label="Mot de passe ACD"
        fullWidth
        onChange={e => {
          setPassword(e.target.value);
        }}
      />
    </>
  );
};

AcdConnectorForm.propTypes = {
  serverISuite: string.isRequired,
  setServerISuite: func.isRequired
};

export default AcdConnectorForm;

import {memo, useEffect, useState} from 'react';

import {arrayOf, func, number, shape, string} from 'prop-types';

import {getDocumentRequestColumns, SEND_DOCUMENT_REQUEST_COLUMNS_NAMES, SEND_DOCUMENT_REQUEST_HEADER_ROW as HEADER_ROW} from '../../utils';
import BaseReactGrid from '../spreadsheets/BaseReactGrid';
import DeleteCellTemplate from '../spreadsheets/cell-templates/DeleteCellTemplate';
import TooltipTextCellTemplate from '../spreadsheets/cell-templates/TooltipTextCellTemplate';

const formatDataToAccountingCompliantFormat = spreadsheetData => {
  const accountingCompliantFormattedData = spreadsheetData.map(entry => ({
    'Date pièce': entry.date,
    Journal: entry.journal,
    Montant: entry.amount,
    Libellé: entry.label
  }));
  return accountingCompliantFormattedData;
};

const DocumentRequestSpreadsheet = ({data, updateData}) => {
  const [formattedData, setFormattedData] = useState([]);
  const [rows, setRows] = useState([]);

  const deleteRow = rowId => {
    setFormattedData(currentData => {
      const updatedData = [...currentData.filter(entry => entry.id !== rowId)];
      const accountingCompliantFormattedData = formatDataToAccountingCompliantFormat(updatedData);
      updateData(accountingCompliantFormattedData);
      return updatedData;
    });
  };

  const getRows = accoutingData => {
    const r = [];

    accoutingData.forEach(entry => {
      r.push({
        rowId: entry.id,
        height: 40,
        cells: [
          {nonEditable: true, type: 'text', text: entry.date},
          {nonEditable: true, type: 'text', text: entry.journal},
          {
            type: 'tooltipTextCell',
            tooltipContent: 'Double-cliquez pour modifier le libellé',
            text: entry.label
          },
          {nonEditable: true, type: 'frenchNumber', value: entry.amount},
          {type: 'deleteRow', onDelete: () => deleteRow(entry.id)}
        ]
      });
    });
    return [HEADER_ROW, ...r];
  };

  const applyChangesToEntries = (changes, previousData) => {
    const updatedData = [...previousData];

    changes.forEach(change => {
      const {rowId, columnId} = change;

      const oldValue = change.previousCell.text;
      const newValue = change.newCell.text;

      if (newValue !== oldValue) {
        const itemToUpdateIndex = updatedData.findIndex(item => item.id === rowId);
        const updatedItem = updatedData[itemToUpdateIndex];
        updatedItem[columnId] = newValue;
        updatedData[itemToUpdateIndex] = updatedItem;
      }
    });

    return [...updatedData];
  };

  const handleChanges = changes => {
    setFormattedData(previousData => {
      const updatedData = applyChangesToEntries(changes, previousData);
      // reformat data to an accounting-compliant format before sending to API
      const accountingCompliantFormattedData = formatDataToAccountingCompliantFormat(updatedData);
      updateData(accountingCompliantFormattedData);
      return updatedData;
    });
  };

  // Initial formatting / setting spreadsheet data from props raw data to a cleaner / more usable format
  useEffect(() => {
    const dataAfterFormatting = data.map((entry, index) => ({
      date: entry['Date pièce'],
      journal: entry.Journal,
      amount: parseFloat(entry.Montant),
      label: entry['Libellé'],
      id: index
    }));
    setFormattedData(dataAfterFormatting);
  }, []);

  // Re-painting rows on every formattedData change
  useEffect(() => {
    const newRows = getRows(formattedData);
    setRows(newRows);
  }, [formattedData]);

  if (formattedData.length === 0) return null;

  const columns = getDocumentRequestColumns();

  return (
    <BaseReactGrid
      columnIdThatShouldTakeAvailableSpace={SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.label}
      onCellsChanged={handleChanges}
      rows={rows}
      columns={columns}
      customCellTemplates={{
        deleteRow: new DeleteCellTemplate(),
        tooltipTextCell: new TooltipTextCellTemplate()
      }}
    />
  );
};

DocumentRequestSpreadsheet.propTypes = {
  data: arrayOf(
    shape({
      'Date pièce': string.isRequired,
      Journal: string.isRequired,
      Montant: number.isRequired,
      Libellé: string.isRequired
    })
  ).isRequired,
  updateData: func.isRequired
};

export default memo(DocumentRequestSpreadsheet);

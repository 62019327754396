import {QuestionMark} from '@mui/icons-material';
import {Avatar, Tooltip, Typography, useTheme} from '@mui/material';
import {string} from 'prop-types';

const TitleWithTooltip = ({title, tooltip}) => {
  const theme = useTheme();
  return (
    <>
      <Typography sx={{fontSize: 14, fontWeight: 'bold', marginRight: 1}} component="div" variant="p">
        {title}
      </Typography>
      <Tooltip title={tooltip}>
        <Avatar sx={{width: 20, height: 20, background: theme.palette.primary.main}}>
          <QuestionMark sx={{width: 12, height: 12, color: '#fff'}} />
        </Avatar>
      </Tooltip>
    </>
  );
};

TitleWithTooltip.propTypes = {
  title: string.isRequired,
  tooltip: string.isRequired
};

export default TitleWithTooltip;

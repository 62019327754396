import {TextField, Typography} from '@mui/material';
import {func, string} from 'prop-types';

import useProjects from '../../../hooks/providers/useProjects';
import PasswordInputRevealable from '../../form/PasswordInputRevealable';
import SirenTextField from '../../form/SirenTextField';
import InputLabel from './InputLabel';

const VectronConnectorForm = ({siteId, setSiteId}) => {
  const {createProjectApiKeyError, setCreateProjectApiKeyError, handleSirenChange, projectCreated, apiKey, setApiKey} = useProjects();

  return (
    <>
      <SirenTextField disabled={projectCreated} onChange={handleSirenChange} />
      <Typography sx={{fontSize: 13, mb: 1, fontWeight: 'bold'}}>Pour récupérer le site id et votre clé d'API, veuillez-vous rendre dans votre compte myVectron</Typography>

      <TextField
        error={createProjectApiKeyError !== ''}
        required
        size="small"
        value={siteId}
        label="Site id"
        color="primary"
        fullWidth
        sx={{mt: 1, mb: 2}}
        onChange={e => {
          setSiteId(e.target.value);
        }}
      />

      <InputLabel>Copiez la clé d'API générée et collez-là ci-dessous :</InputLabel>
      <PasswordInputRevealable
        error={createProjectApiKeyError !== ''}
        helperText={createProjectApiKeyError}
        required
        size="small"
        value={apiKey}
        color="primary"
        fullWidth
        placeholder="Clé d'api"
        onChange={event => {
          setApiKey(event.target.value);
          setCreateProjectApiKeyError('');
        }}
      />
    </>
  );
};

VectronConnectorForm.propTypes = {
  siteId: string.isRequired,
  setSiteId: func.isRequired
};

export default VectronConnectorForm;

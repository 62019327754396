import MenuItem from '@mui/material/MenuItem';
import {func, string} from 'prop-types';

import FormSelect from '../../form/MuiSelect';

const InputVAT = ({value, onChange}) => {
  return (
    <FormSelect
      sx={{width: '50%'}}
      label="Taux de TVA"
      labelId="vat-input-label"
      value={value}
      onChange={onChange}
      items={[
        <MenuItem key={0} value={0}>
          0%
        </MenuItem>,
        <MenuItem key={1} value={0.055}>
          5.5%
        </MenuItem>,
        <MenuItem key={2} value={0.1}>
          10%
        </MenuItem>,
        <MenuItem key={3} value={0.2}>
          20%
        </MenuItem>
      ]}
    />
  );
};

InputVAT.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired
};

export default InputVAT;

import {useState} from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import {createTheme, Grid, IconButton, TableContainer, TableFooter, ThemeProvider, Tooltip, useTheme} from '@mui/material';
import Paper from '@mui/material/Paper';
import muiStyled from '@mui/material/styles/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes, {arrayOf, number, shape, string} from 'prop-types';
import styled from 'styled-components';

import {getFrenchFormattedDate} from '../../utils';

const createTableTheme = parentTheme => {
  return createTheme({
    ...parentTheme,
    components: {
      MuiTablePagination: {
        styleOverrides: {
          spacer: {
            display: 'none !important'
          },
          toolbar: {
            paddingRight: '24px !important'
          }
        }
      }
    }
  });
};

const TablePaginationActions = props => {
  const {count, page, rowsPerPage, onPageChange} = props;
  const numberOfPages = Math.ceil(count / rowsPerPage);

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handlePageNumberClick = (event, pageIndex) => {
    onPageChange(event, pageIndex);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item onClick={handleBackButtonClick} sx={{minWidth: 50}}>
        {page > 0 && <NextOrPreviousPageText>Précédent</NextOrPreviousPageText>}
      </Grid>

      <Grid item>
        {Array.from(Array(numberOfPages), (e, i) => (
          <PageNumber active={i === page} key={i + 1} onClick={ev => handlePageNumberClick(ev, i)}>
            {i + 1}
          </PageNumber>
        ))}
      </Grid>

      <Grid item onClick={handleNextButtonClick} sx={{minWidth: 55}}>
        {page < numberOfPages - 1 && <NextOrPreviousPageText>Suivant</NextOrPreviousPageText>}
      </Grid>
    </Grid>
  );
};

const PageNumber = styled.span`
  font-size: ${props => (props.active ? '18px' : '14px')};
  font-family: InstrumentSans;
  font-weight: bold;
  margin-right: 12px;
  color: ${props => (props.active ? '#90A8FC' : process.env.REACT_APP_MAIN_COLOR)};
  cursor: pointer;
`;

const NextOrPreviousPageText = styled.p`
  text-align: center;
  font: normal normal bold 16px/69px InstrumentSans;
  color: ${process.env.REACT_APP_MAIN_COLOR};
  cursor: pointer;
`;

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const TitleTableCell = muiStyled(TableCell)(() => ({
  textAlign: 'center',
  font: 'normal normal bold 18px/69px InstrumentSans',
  textTransform: 'uppercase'
}));

const DataTableCell = muiStyled(TableCell)(({theme}) => ({
  textAlign: 'center',
  font: 'normal normal normal 14px/29px InstrumentSans',
  color: theme.typography.color.dark
}));

const BillsTable = ({bills}) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const rowsPerPage = 8;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bills.length) : 0;

  const parentTheme = useTheme();
  const tableTheme = createTableTheme(parentTheme);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <ThemeProvider theme={tableTheme}>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 750}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TitleTableCell>Date</TitleTableCell>
              <TitleTableCell align="right">Type</TitleTableCell>
              <TitleTableCell align="right">Formules</TitleTableCell>
              <TitleTableCell align="right">Montant</TitleTableCell>
              <TitleTableCell align="right">Action</TitleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 ? (bills || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : bills).map((row, i) => (
              <TableRow
                key={row.invoice_pdf}
                sx={{
                  background: i % 2 === 0 ? theme.palette.primary.light : 'inherit',
                  '&:last-child td, &:last-child th': {border: 0}
                }}
              >
                <DataTableCell component="th" scope="row">
                  {getFrenchFormattedDate(row.period_start)}
                </DataTableCell>
                <DataTableCell align="right">Facture</DataTableCell>
                <DataTableCell align="right"> {row.product}</DataTableCell>
                <DataTableCell align="right">{row.amount_paid} €</DataTableCell>
                <DataTableCell align="right">
                  <Tooltip title="Visionner la facture">
                    <IconButton target="_blank" href={row.hosted_invoice_url}>
                      <VisibilityIcon aria-label="Visionner la facture" color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Télécharger la facture">
                    <IconButton href={row.invoice_pdf}>
                      <FileDownloadIcon aria-label="télécharger la facture" color="primary" />
                    </IconButton>
                  </Tooltip>
                </DataTableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{height: 62 * emptyRows}}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelDisplayedRows={() => ''}
                rowsPerPageOptions={[rowsPerPage]}
                colSpan={12}
                count={bills?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

BillsTable.defaultProps = {
  bills: []
};

const billPropShape = shape({
  period_start: string,
  type: string,
  product: string,
  amount_paid: number,
  hosted_invoice_url: string,
  invoice_pdf: string
});

BillsTable.propTypes = {
  bills: arrayOf(billPropShape)
};

export default BillsTable;

import {NumberCellTemplate} from '@silevis/reactgrid';

class FrenchNumberCellTemplate extends NumberCellTemplate {
  constructor() {
    super();
    this.languageFormat = 'fr-FR';
  }

  getCompatibleCell(uncertainCell) {
    let value;

    try {
      value = uncertainCell.value ?? NaN;
    } catch {
      value = NaN;
    }

    const displayValue = uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value;

    const numberFormat = new Intl.NumberFormat(this.languageFormat, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });

    const text = Number.isNaN(displayValue) || (uncertainCell.hideZero && displayValue === 0) ? '' : numberFormat.format(displayValue);

    return {...uncertainCell, value: displayValue, text};
  }
}

export default FrenchNumberCellTemplate;

/* eslint-disable class-methods-use-this */
import {DateCellTemplate, getCellProperty} from '@silevis/reactgrid';

import {createDateObjectFromFrenchFormattedDate} from '../../../utils';

class FrenchDateCellTemplate extends DateCellTemplate {
  constructor() {
    super();
    this.dateFormatter = new Intl.DateTimeFormat('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  formatDateWithTrailingZero(date) {
    const [day, month, year] = date.split('/');

    const formattedDay = day.length === 1 ? `0${day}` : day;
    const formattedMonth = month.length === 1 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  update(cell, cellToMerge) {
    const formattedCellToMerge = cellToMerge;
    formattedCellToMerge.text = this.formatDateWithTrailingZero(cellToMerge.text);

    return this.getCompatibleCell({
      ...cell,
      date: createDateObjectFromFrenchFormattedDate(cellToMerge.text), // cellToMerge.value is `NaN` when copy a date from outside into a Date Cell
      text: formattedCellToMerge.text
    });
  }

  getCompatibleCell(uncertainCell) {
    const date = getCellProperty(uncertainCell, 'date', 'object');
    const frenchFormattedDate = this.dateFormatter.format(new Date(date));

    const parsedDate = date ? createDateObjectFromFrenchFormattedDate(date) : null;

    const updatedCell = {
      ...uncertainCell,
      type: 'date',
      date: parsedDate,
      format: this.dateFormatter,
      text: frenchFormattedDate
    };

    return updatedCell;
  }

  render(cell, isInEditMode, onCellChanged) {
    const frenchFormattedDate = cell.date ? this.dateFormatter.format(cell.date) : '';
    return super.render({...cell, text: frenchFormattedDate}, isInEditMode, onCellChanged);
  }
}

export default FrenchDateCellTemplate;

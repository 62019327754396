import {useEffect} from 'react';

import {oneOfType, node, func, bool} from 'prop-types';
import {Navigate} from 'react-router-dom';

import {APP_ROUTES} from '../const';
import useAuth from '../hooks/providers/useAuth';
import useLoading from '../hooks/providers/useLoading';
import usePayment from '../hooks/providers/usePayment';

const RequireSubscription = ({children, requiresSubscriptionOrTrial = false}) => {
  const {authorizationsLoading: loading, authorizationsLoaded, getUserSubscriptionStatus, numberOfProjectsOwnedByUser, maxNumberOfProjects} = usePayment();
  const auth = useAuth();
  const {setCompletedApiCalls} = useLoading();

  useEffect(() => {
    (async () => {
      if (auth?.user?.tokenAad) {
        await getUserSubscriptionStatus();
        setCompletedApiCalls(prevCompleted => prevCompleted + 1);
      }
    })();
  }, [auth?.user?.tokenAad]);

  if (loading || !authorizationsLoaded) {
    return null;
  }

  const userCanAccessRestrictedPages = numberOfProjectsOwnedByUser <= maxNumberOfProjects;
  if (requiresSubscriptionOrTrial && !userCanAccessRestrictedPages) {
    return <Navigate to={APP_ROUTES.restrictedAccess} replace />;
  }

  return children;
};

RequireSubscription.defaultProps = {
  requiresSubscriptionOrTrial: false
};

RequireSubscription.propTypes = {
  children: oneOfType([node, func]).isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  requiresSubscriptionOrTrial: bool
};

export default RequireSubscription;

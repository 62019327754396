/* eslint-disable camelcase */

import {FormControlLabel} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import {string, bool, shape, func, arrayOf} from 'prop-types';
import styled from 'styled-components';

const ConsentContainer = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
`;

const ConsentText = styled.p`
  font-style: italic;
  margin-top: 4px;
  color: #a5a5a5;
  font-size: 13px;
`;

const ConsentLink = styled.a`
  font-style: italic;
  color: ${process.env.REACT_APP_SECONDARY_COLOR};
`;

const NotConsentedText = styled.p`
  display: inline-block;
`;

const NotConsentedLink = styled.a`
  color: ${process.env.REACT_APP_SECONDARY_COLOR};
`;

const consentConstants = {
  cgu: {
    prefix: 'les',
    text: "conditions générales d'utilisation",
    textPartners: "conditions générales d'utilisation de notre partenaire Drivn",
    link: 'https://www.drivn.fr/conditions-generales-utilisation/'
  },
  confidentiality: {
    prefix: 'la',
    text: 'politique de confidentialité',
    textPartners: 'politique de confidentialité de notre partenaire Drivn',
    link: 'https://www.drivn.fr/politique-de-confidentialite/'
  }
};

const dateFormat = 'DD/MM/YYYY [à] HH:mm';

const Consents = ({consents, onCheckConsent}) => {
  const getMostRecentConsent = consentsOfType => {
    let mostRecentConsent = null;
    if (consentsOfType.length > 0) {
      mostRecentConsent = consentsOfType.reduce((a, b) => {
        return new Date(a.last_update) > new Date(b.last_update) ? a : b;
      });
    }
    return mostRecentConsent;
  };

  // Sometimes, API can return multiple results for one consent type.
  // In that case, we have to find and display the most recent consent.
  const cguConsents = (consents || []).filter(({consent_type}) => consent_type === 'cgu');
  const confidentialityConsents = (consents || []).filter(({consent_type}) => consent_type === 'confidentiality');

  const mostRecentCguConsent = getMostRecentConsent(cguConsents);
  const mostRecentConfidentialityConsent = getMostRecentConsent(confidentialityConsents);

  return (
    <ConsentContainer>
      {['cgu', 'confidentiality'].map(type => {
        const consent = type === 'cgu' ? mostRecentCguConsent : mostRecentConfidentialityConsent;
        const text = process.env.REACT_APP_COMPANY_NAME === 'drivn' ? consentConstants[type]?.text : consentConstants[type]?.textPartners;

        if (consent && consent.consent_value) {
          const {last_update} = consent;

          return (
            <ConsentText key={type}>
              Vous avez accepté {consentConstants[type]?.prefix}{' '}
              <ConsentLink href={consentConstants[type]?.link} target="_blank">
                {text}
              </ConsentLink>{' '}
              le {dayjs(last_update).format(dateFormat)}
            </ConsentText>
          );
        }
        return (
          <Grid container alignItems="center" key={type}>
            <FormControlLabel
              control={<Checkbox onChange={ev => onCheckConsent(type, ev.target.checked)} />}
              label={
                <NotConsentedText>
                  J'accepte {consentConstants[type]?.prefix}{' '}
                  <NotConsentedLink href={consentConstants[type]?.link} target="_blank">
                    {text}
                  </NotConsentedLink>
                </NotConsentedText>
              }
            />
          </Grid>
        );
      })}
    </ConsentContainer>
  );
};

Consents.propTypes = {
  consents: arrayOf(
    shape({
      consent_type: string.isRequired,
      consent_value: bool.isRequired,
      last_update: string
    })
  ).isRequired,
  onCheckConsent: func.isRequired
};

export default Consents;

import {useReducer} from 'react';

import {Alert, Box} from '@mui/material';
import {bool} from 'prop-types';

import useProfile from '../../../hooks/providers/useProfile';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import AddUserToProjectOrWorkspace from '../../users/AddUserToProjectOrWorkspace';
import DeleteUserModal from '../../users/DeleteUserModal';
import WorkspaceOrReportUsersList from './WorkspaceOrReportUsersList';

const ACTIONS_TYPES = {
  SET_EMAIL_ERROR: 'SET_EMAIL_ERROR',
  SET_IS_SUBMITTING: 'SET_IS_SUBMITTING',
  DELETE_USER: 'DELETE_USER',
  ADD_USER: 'ADD_USER',
  TOGGLE_DELETE_USER_MODAL: 'TOGGLE_DELETE_USER_MODAL',
  SET_USER_TO_DELETE: 'SET_USER_TO_DELETE',
  INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS'
};

const ActionCreators = dispatch => ({
  setEmailError: errorText => {
    dispatch({type: ACTIONS_TYPES.SET_EMAIL_ERROR, payload: errorText});
  },
  setIsSubmitting: submitting => {
    dispatch({type: ACTIONS_TYPES.SET_IS_SUBMITTING, payload: submitting});
  },
  deleteUser: userId => {
    dispatch({type: ACTIONS_TYPES.DELETE_USER, payload: userId});
  },
  addUser: user => {
    dispatch({type: ACTIONS_TYPES.ADD_USER, payload: user});
  },
  setDeleteUserModalOpen: isOpen => {
    dispatch({type: ACTIONS_TYPES.TOGGLE_DELETE_USER_MODAL, payload: isOpen});
  },
  setUserToDelete: userId => {
    dispatch({type: ACTIONS_TYPES.SET_USER_TO_DELETE, payload: userId});
  },
  setUserInvitedSuccessMessage: message => {
    dispatch({type: ACTIONS_TYPES.INVITE_USER_SUCCESS, payload: message});
  }
});

const initialState = {
  emailError: '',
  submittingText: '',
  userInvitedSuccessMessage: '',
  users: [],
  isSubmitting: false,
  deleteUserModalOpen: false,
  userToDelete: null
};

// eslint-disable-next-line complexity
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_EMAIL_ERROR:
      return {...state, emailError: action.payload};
    case ACTIONS_TYPES.SET_IS_SUBMITTING:
      return {...state, isSubmitting: action.payload};

    case ACTIONS_TYPES.DELETE_USER:
      return {
        ...state,
        isSubmitting: true,
        submittingText: "Suppression de l'utilisateur en cours",
        deleteUserModalOpen: false
      };
    case ACTIONS_TYPES.ADD_USER:
      return {
        ...state,
        isSubmitting: true,
        submittingText: "Ajout de l'utilisateur en cours"
      };

    case ACTIONS_TYPES.TOGGLE_DELETE_USER_MODAL:
      return {...state, deleteUserModalOpen: action.payload};
    case ACTIONS_TYPES.SET_USER_TO_DELETE:
      return {...state, userToDelete: action.payload};
    case ACTIONS_TYPES.INVITE_USER_SUCCESS:
      return {...state, userInvitedSuccessMessage: action.payload};
    default:
      throw new Error(`Action type ${action.type} doesn't exist`);
  }
};

const ManageWorkspaceOrReportUsersModal = ({isWorkspaceUsersList = false}) => {
  const {
    isWorkspaceUsersModalOpen: open,
    setIsWorkspaceUsersModalOpen,
    rightClickedWorkspace,
    rightClickedReportTab,
    addUserToReport,
    deleteUserFromReport,
    addUserToWorkspace,
    deleteUserFromWorkspace,
    selectedWorkspace,
    selectedWorkspaceId,
    closeWorkspaceTabsMenu
  } = useWorkspaces();
  const {profile} = useProfile();

  const [state, dispatch] = useReducer(reducer, initialState);
  const {emailError, isSubmitting, deleteUserModalOpen, userToDelete, submittingText, userInvitedSuccessMessage} = state;
  const {
    setEmailError,
    setIsSubmitting,
    setDeleteUserModalOpen,
    setUserToDelete,
    deleteUser: deleteUserActionCreator,
    addUser: addUserActionCreator,
    setUserInvitedSuccessMessage
  } = ActionCreators(dispatch);

  const onClose = () => {
    setIsWorkspaceUsersModalOpen(false);
    closeWorkspaceTabsMenu();
  };

  const addUser = async mail => {
    addUserActionCreator();
    let result;
    const formattedEmail = mail.toLowerCase();
    const workspaceId = isWorkspaceUsersList ? rightClickedWorkspace.workspace_id : selectedWorkspaceId;

    if (isWorkspaceUsersList) {
      result = await addUserToWorkspace(formattedEmail, workspaceId);
    } else {
      result = await addUserToReport(formattedEmail, workspaceId, rightClickedReportTab.report_id);
    }

    if (result.status === 402) {
      setUserInvitedSuccessMessage(result.data);
    }

    setEmailError('');
    setIsSubmitting(false);

    return result;
  };

  const deleteUser = async userId => {
    deleteUserActionCreator(userId);
    const isUserDeletingHimSelf = userId.toLowerCase() === profile.id.toLowerCase();
    const workspaceId = isWorkspaceUsersList ? rightClickedWorkspace.workspace_id : selectedWorkspaceId;
    let result;

    if (isWorkspaceUsersList) {
      result = await deleteUserFromWorkspace(userId, workspaceId, isUserDeletingHimSelf);
    } else {
      result = await deleteUserFromReport({
        userId,
        workspaceId,
        reportId: rightClickedReportTab.report_id,
        isUserDeletingHimSelf
      });
    }

    if (result.status === 200) {
      if (isUserDeletingHimSelf) {
        onClose();
      }
    }
    setIsSubmitting(false);
  };

  const openDeleteModal = userId => {
    setUserToDelete(userId);
    setDeleteUserModalOpen(true);
  };

  const closeDeleteModal = () => {
    setUserToDelete(null);
    setDeleteUserModalOpen(false);
  };

  /*  useEffect(() => {
                                      const report = isWorkspaceUsersList ? null : selectedWorkspace?.reports?.find(r => r.report_id === rightClickedReportTab.report_id);
                                      const reportUsers = report?.report_users || [];
                                      const initialUsers = isWorkspaceUsersList ? rightClickedWorkspace.workspace_users : reportUsers;
                                      setUsers(initialUsers);
                                    }, [open]); */

  const entityName = isWorkspaceUsersList ? 'cet espace de travail' : 'ce rapport';
  const workspaceUsers = selectedWorkspace?.workspace_users;

  const report = isWorkspaceUsersList ? null : selectedWorkspace?.reports?.find(r => r.report_id === rightClickedReportTab.report_id);
  const reportUsers = report?.report_users || [];
  const users = isWorkspaceUsersList ? rightClickedWorkspace.workspace_users : reportUsers;

  return (
    <BaseModal title="Gérer les utilisateurs" onClose={onClose} open={open}>
      {isWorkspaceUsersList && (
        <Alert severity="info" sx={{m: -0.5}}>
          Les utilisateurs ayant accès à l'espace de travail ont accès à <b>tous les rapports</b> de l'espace. Ils pourront effectuer <b>l'ensemble des opérations</b> (ajout de rapport, suppression de
          rapport, téléchargement)
        </Alert>
      )}

      {deleteUserModalOpen && (
        <DeleteUserModal isOpen username={users?.find(u => u.user_id === userToDelete)?.username} onSubmit={() => deleteUser(userToDelete)} onCancel={closeDeleteModal} entity={entityName} />
      )}

      <Box mt={3} px={3}>
        <AddUserToProjectOrWorkspace addUser={addUser} emailError={emailError} setEmailError={setEmailError} isSubmitting={isSubmitting} isWorkspaceForm />

        <WorkspaceOrReportUsersList
          isWorkspaceUsersList={isWorkspaceUsersList}
          users={users}
          onChipDelete={openDeleteModal}
          isSubmitting={isSubmitting}
          title="Liste des utilisateurs"
          titleTooltip="Vous pouvez supprimer un propriétaire en cliquant sur l'icône croix à côté de son email"
        />

        {!isWorkspaceUsersList && (
          <WorkspaceOrReportUsersList
            isWorkspaceUsersList={isWorkspaceUsersList}
            readonly
            users={workspaceUsers}
            isSubmitting={isSubmitting}
            title="Liste des utilisateurs de l'espace de travail"
            titleTooltip="Vous ne pouvez pas interagir avec les utilisateurs de l'espace de travail depuis le rapport"
          />
        )}

        {isSubmitting && (
          <Box mb={2}>
            <Spinner isLoading text={submittingText} />
          </Box>
        )}
      </Box>
      {userInvitedSuccessMessage && (
        <Alert variant="filled" severity="success">
          {userInvitedSuccessMessage}
        </Alert>
      )}
    </BaseModal>
  );
};

ManageWorkspaceOrReportUsersModal.propTypes = {
  isWorkspaceUsersList: bool.isRequired
};

export default ManageWorkspaceOrReportUsersModal;

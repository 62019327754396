import {arrayOf, bool, func, instanceOf, string} from 'prop-types';

import {CONNECTORS} from '../../const';
import useProjects from '../../hooks/providers/useProjects';
import useTiimeProjects from '../../hooks/providers/useTiimeProjects';
import Button from '../form/buttons/Button';

// eslint-disable-next-line complexity
const CreateProjectSubmitButton = props => {
  const {connector, handleSubmit, handleImport, projectCreated, files, filesError, isPennylaneFormValid, isCegidFormValid, isMyUnisoftFormValid, isAcdFormValid} = props;
  const {tiimeProjectToLink, createTiimeFolder} = useTiimeProjects();
  const isTiimeFormValid = tiimeProjectToLink !== null;
  const {isSubmitting} = useProjects();

  const isConnectorManual = connector === CONNECTORS.manual;
  const isConnectorPennylane = connector === CONNECTORS.pennylane;
  const isConnectorCegid = connector === CONNECTORS.cegidQuadra;
  const isConnectorAcd = connector === CONNECTORS.acd;
  const isConnectorMyUnisoft = connector === CONNECTORS.unisoft;
  const isConnectorTiime = connector === CONNECTORS.tiime;

  if (isConnectorManual && !projectCreated) {
    return null;
  }

  return (
    <Button
      disabled={
        isSubmitting ||
        !connector ||
        (isConnectorManual && files.length === 0) ||
        filesError !== '' ||
        (isConnectorPennylane && !isPennylaneFormValid) ||
        (isConnectorCegid && !isCegidFormValid) ||
        (isConnectorMyUnisoft && !isMyUnisoftFormValid) ||
        (isConnectorAcd && !isAcdFormValid) ||
        (isConnectorTiime && !isTiimeFormValid)
      }
      onClick={() => {
        if (isConnectorManual) return handleImport();
        if (isConnectorTiime) return createTiimeFolder(tiimeProjectToLink);
        return handleSubmit();
      }}
      fullWidth
      variant="contained"
      color="secondary"
      size="large"
    >
      {isConnectorManual ? 'Importer vos données' : 'Créer le projet'}
    </Button>
  );
};

CreateProjectSubmitButton.defaultProps = {
  files: []
};

CreateProjectSubmitButton.propTypes = {
  connector: string.isRequired,
  isPennylaneFormValid: bool.isRequired,
  isCegidFormValid: bool.isRequired,
  isMyUnisoftFormValid: bool.isRequired,
  isAcdFormValid: bool.isRequired,
  handleSubmit: func.isRequired,
  handleImport: func.isRequired,
  projectCreated: bool.isRequired,
  files: arrayOf(instanceOf(File)),
  filesError: string.isRequired
};

export default CreateProjectSubmitButton;

import {func} from 'prop-types';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../const';
import usePayment from '../../hooks/providers/usePayment';
import useProjects from '../../hooks/providers/useProjects';
import StyledTab from './StyledTab';
import StyledTabs from './StyledTabs';
import TabSeparator from './TabSeparator';

const DeFiReportTabs = ({handleChange}) => {
  const {authorizedModules} = usePayment();
  const {isLoading} = useProjects();

  const defiTabs = [
    {schema: DEFAULT_PROJECTS_SCHEMAS.gestion, label: 'Gestion'},
    {schema: DEFAULT_PROJECTS_SCHEMAS.previ, label: 'Prévi'},
    {schema: DEFAULT_PROJECTS_SCHEMAS.staff, label: 'Staff'},
    {schema: DEFAULT_PROJECTS_SCHEMAS.z, label: 'Z'}
  ];

  const isTabVisible = schema => {
    return authorizedModules.includes(schema);
  };

  const visibleTabs = defiTabs.filter(({schema}) => isTabVisible(schema));

  const renderTabs = () => {
    const tabElements = [];

    visibleTabs.forEach((tab, index) => {
      tabElements.push(<StyledTab key={tab.schema} isReportTab disabled={isLoading} label={tab.label} value={tab.schema} />);
      const isLastTab = index === visibleTabs.length - 1;
      if (!isLastTab) {
        tabElements.push(<TabSeparator key={`${tab.schema}-separator`} />);
      }
    });

    return tabElements;
  };

  return (
    <StyledTabs variant="scrollable" isReportTabs scrollButtons={false} onChange={handleChange} aria-label="Sélecteur de schema">
      {renderTabs()}
    </StyledTabs>
  );
};

DeFiReportTabs.propTypes = {
  handleChange: func.isRequired
};

export default DeFiReportTabs;

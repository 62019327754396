import {useState, useEffect, useCallback} from 'react';

import {Howl} from 'howler';

const useAudioPlayer = audio => {
  const [sound, setSound] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (audio) {
      const binary = atob(audio.data);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const blob = new Blob([new Uint8Array(array)], {type: 'audio/mpeg'});
      const url = URL.createObjectURL(blob);

      const howl = new Howl({
        src: [url],
        format: ['mpeg'],
        html5: true,
        onend: () => {
          setIsPlaying(false);
          setSound(null); // Setting sound to null when it ends
        }
      });

      setSound(howl);

      return () => {
        URL.revokeObjectURL(url);
        howl.unload();
      };
    }
  }, [audio]);

  const play = useCallback(() => {
    if (sound && !isPlaying) {
      sound.play();
      setIsPlaying(true);
    }
  }, [sound, isPlaying]);

  const pause = useCallback(() => {
    if (sound && isPlaying) {
      sound.pause();
      setIsPlaying(false);
    }
  }, [sound, isPlaying]);

  const togglePlayPause = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };

  return {
    isPlaying,
    setIsPlaying,
    sound,
    setSound,
    togglePlayPause
  };
};

export default useAudioPlayer;

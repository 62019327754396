import {useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import {string} from 'prop-types';

const ErrorMessage = ({message}) => {
  const theme = useTheme();
  if (!message) return null;

  return (
    <Typography sx={{marginTop: 2, color: theme.palette.error.main}} variant="inherit">
      {message}
    </Typography>
  );
};

ErrorMessage.propTypes = {
  message: string.isRequired
};

export default ErrorMessage;

import {Tooltip, tooltipClasses, useTheme} from '@mui/material';
import {func, node, oneOfType} from 'prop-types';

const BrandedTooltip = props => {
  const theme = useTheme();
  const {children} = props;

  return (
    <Tooltip
      {...props}
      componentsProps={{
        popper: {
          sx: {
            [`& .${tooltipClasses.arrow}`]: {
              color: theme.palette.primary.main
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: theme.palette.primary.main
            }
          }
        }
      }}
    >
      {children}
    </Tooltip>
  );
};

BrandedTooltip.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default BrandedTooltip;

import {useState} from 'react';

import {Button, DialogActions, DialogContent, Typography} from '@mui/material';
import {bool, func, string} from 'prop-types';
import styled from 'styled-components';

import useReportSummary from '../../../hooks/providers/useReportSummary';
import BaseModal from '../../modal/BaseModal';

const BoldText = styled.span`
  font-family: InstrumentSansBold;
`;

const DeletePromptConfirmModal = ({isOpen, onClose, model}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {deletePromptModel} = useReportSummary();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const res = await deletePromptModel(model);
    setIsSubmitting(false);

    if (res.success) {
      onClose();
    }
  };

  return (
    <BaseModal canCloseOnBackdropClick={false} onClose={onClose} title="Supprimer le modèle" open={isOpen}>
      <DialogContent sx={{p: 3}}>
        <Typography>
          Vous êtes sur le point de supprimer le modèle <BoldText>" {model} "</BoldText>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose}>
          Annuler
        </Button>
        <Button disabled={isSubmitting} variant="contained" color="error" onClick={handleSubmit}>
          Supprimer le modèle
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

DeletePromptConfirmModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  model: string.isRequired
};

export default DeletePromptConfirmModal;

import {useState} from 'react';

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DeleteForever from '@mui/icons-material/DeleteForeverOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import {Divider, ListItemIcon, Menu, MenuItem, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';

import {SNACKBAR_ACTIONS} from '../../const';
import useProfile from '../../hooks/providers/useProfile';
import useSnackbar from '../../hooks/providers/useSnackbar';
import useWorkspaces from '../../hooks/providers/useWorkspaces';

const ReportTabsMenu = () => {
  const {
    setCreateReportModalOpen,
    setDeleteWorkspaceOrReportModalOpen,
    selectedWorkspaceId,
    exportReport,
    setIsCustomReportCreation,
    anchorElReportTabsMenu: anchorEl,
    setAnchorElReportTabsMenu,
    rightClickedReportTab: report,
    setIsWorkspaceUsersModalOpen,
    isUserInWorkspace,
    selectedWorkspace
  } = useWorkspaces();
  const {profile} = useProfile();
  const {showSnackbar, defaultSnackbarOptions} = useSnackbar();
  const [isReportDownloading, setIsReportDownloading] = useState(false);
  const theme = useTheme();
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorElReportTabsMenu(false);
  };

  const downloadReport = async () => {
    setIsReportDownloading(true);
    showSnackbar(
      SNACKBAR_ACTIONS.EXPORT_REPORT_IN_PROGRESS,
      {
        severity: 'warning',
        autoHide: false,
        hasSpinner: true
      },
      {exportedReportName: report.report_name}
    );

    const res = await exportReport(selectedWorkspaceId, report.report_id);

    if (res.success) {
      const blob = new Blob([res.data], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${report.report_name}.pbix`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      showSnackbar(SNACKBAR_ACTIONS.EXPORT_REPORT_SUCCESS, defaultSnackbarOptions, {exportedReportName: report.report_name});
    }

    setIsReportDownloading(false);
    handleClose();
  };

  if (!report) return null;

  const userIsInWorkspace = isUserInWorkspace(profile.id, selectedWorkspace);

  return (
    <Menu
      id="workspaces-tabs-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      onClose={handleClose}
      slotProps={{paper: {sx: {width: 300}}}}
    >
      <MenuItem disabled={!userIsInWorkspace} key={2} sx={{pt: 1.5}} onClick={() => setIsWorkspaceUsersModalOpen(true)}>
        <ListItemIcon>
          <GroupRoundedIcon color="primary" />
        </ListItemIcon>
        <Typography fontSize={14} variant="inherit">
          Gérer les utilisateurs
        </Typography>
      </MenuItem>
      <Divider sx={{margin: '0 !important'}} />
      {report.isCustomModel && (
        <MenuItem
          disabled
          sx={{py: 1.5}}
          onClick={() => {
            setCreateReportModalOpen(true);
            setIsCustomReportCreation(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <CloudSyncIcon fontSize="medium" color="primary" />
          </ListItemIcon>
          <Typography fontSize={14} variant="inherit">
            Synchroniser le rapport
          </Typography>
        </MenuItem>
      )}

      <MenuItem disabled={!userIsInWorkspace || !report.isCustomModel || isReportDownloading} sx={{py: 1.5}} onClick={downloadReport}>
        <ListItemIcon>
          <DownloadIcon fontSize="medium" color="primary" />
        </ListItemIcon>
        <Typography noWrap fontSize={14} variant="inherit">
          {isReportDownloading ? 'Téléchargement du rapport en cours ...' : `Télécharger ${report.report_name}`}
        </Typography>
      </MenuItem>
      <Divider sx={{margin: '0 !important'}} />

      <MenuItem
        disabled={!userIsInWorkspace || report.is_notice_report}
        sx={{pt: 1.5}}
        onClick={() => {
          setDeleteWorkspaceOrReportModalOpen(true);
          handleClose();
        }}
      >
        <ListItemIcon>
          <DeleteForever fontSize="medium" color="error" />
        </ListItemIcon>
        <Typography noWrap fontSize={14} sx={{color: theme.palette.error.main}} variant="inherit">
          Supprimer {report.report_name}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default ReportTabsMenu;

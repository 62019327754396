/* eslint-disable react/boolean-prop-naming */
import {FormControl, InputLabel, Select} from '@mui/material';
import {arrayOf, bool, func, node, oneOfType, shape, string} from 'prop-types';

const FormSelect = ({variant, sx, label, labelId, value, onChange, items, disabled, size = 'medium', renderValue}) => {
  return (
    <FormControl variant={variant} disabled={disabled} sx={sx} size={size}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select renderValue={renderValue} labelId={labelId} value={value} label={label} onChange={onChange}>
        {items}
      </Select>
    </FormControl>
  );
};

FormSelect.defaultProps = {
  sx: {},
  disabled: false,
  size: 'medium',
  renderValue: null,
  variant: 'outlined'
};

FormSelect.propTypes = {
  disabled: bool,
  sx: shape({}),
  label: string.isRequired,
  labelId: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  items: oneOfType([arrayOf(node), node]).isRequired,
  size: string,
  renderValue: func,
  variant: string
};

export default FormSelect;

import {createTheme} from '@mui/material';
import {grey} from '@mui/material/colors';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: grey[500],
            cursor: 'not-allowed',
            pointerEvents: 'all'
          }
        }
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          width: 28,
          height: 28,
          fontSize: 13,
          background: process.env.REACT_APP_MAIN_COLOR
        }
      }
    }
  },
  typography: {
    fontFamily: ['InstrumentSans', 'Open-sans'].join(','),
    color: {
      light: grey[700],
      main: grey[800],
      dark: grey[900]
    },
    allVariants: {
      color: grey[800]
    },
    button: {
      textTransform: 'none'
    }
  },

  palette: {
    primary: {
      main: process.env.REACT_APP_MAIN_COLOR,
      light: `${process.env.REACT_APP_MAIN_COLOR}30`,
      contrastText: '#fff'
    },
    warning: {
      main: '#f49022' // TODO maybe change depending on client env. See with adrian.
    },
    secondary: {
      main: process.env.REACT_APP_SECONDARY_COLOR,
      light: `${process.env.REACT_APP_SECONDARY_COLOR}30`,
      contrastText: '#fff'
    },
    text: {
      primary: grey[700]
    },
    white: {
      main: '#fff',
      contrastText: process.env.REACT_APP_MAIN_COLOR
    }
  }
});

theme = createTheme(theme, {
  palette: {
    lightPrimary: theme.palette.augmentColor({
      color: {
        main: theme.palette.success.light,
        contrastText: 'white'
      },
      name: 'lightPrimary'
    })
  }
});

export default theme;

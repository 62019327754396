/* eslint-disable class-methods-use-this */

class InvisibleCell {
  getCompatibleCell(uncertainCell) {
    return {...uncertainCell, text: '', nonEditable: true};
  }

  isFocusable() {
    return false;
  }

  render(cell) {
    return <div />;
  }
}

export default InvisibleCell;

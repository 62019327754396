import {useState} from 'react';

import {Refresh} from '@mui/icons-material';
import {Grid, IconButton, MenuItem, TextField, Tooltip, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {func} from 'prop-types';

import useProjects from '../../../hooks/providers/useProjects';
import useTiimeProjects from '../../../hooks/providers/useTiimeProjects';
import {getHumanReadableSiren} from '../../../utils';
import Button from '../../form/buttons/Button';
import RotatingIcon from '../../spinner/RotatingIcon';

const TiimeConnectorForm = ({handleClose}) => {
  const [isRefreshingCompaniesList, setIsRefreshingCompaniesList] = useState(false);
  const {projects} = useProjects();
  const {tiimeCompanies, tiimeProjectToLink, setTiimeProjectToLink, tiimeProjectCreationError, tiimeProjectSuccessfullyImported, setTiimeProjectSuccessfullyImported, checkTiimeToken} =
    useTiimeProjects();

  const projectsSirens = (projects || []).map(p => p.siren);
  const availableCompaniesSirens = tiimeCompanies.filter(c => !projectsSirens.includes(c.siren)).map(c => c.siren);

  const refreshCompaniesList = async () => {
    setIsRefreshingCompaniesList(true);
    // We refresh companies list by calling the API check token route
    await checkTiimeToken(true);
    setIsRefreshingCompaniesList(false);
  };

  return (
    <Box ml={2}>
      {tiimeProjectSuccessfullyImported ? (
        <>
          <Typography fontSize={16} color="text.primary" fontFamily="InstrumentSansBold">
            Votre dossier est en cours d'import. Vous recevrez un email lorsqu'il sera prêt.
          </Typography>
          <Grid container spacing={1} mt={2} justifyContent="center">
            <Grid item>
              <Button variant="contained" color="secondary" onClick={() => setTiimeProjectSuccessfullyImported(false)}>
                Importer un autre dossier
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                Fermer
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Typography mb={2} variant="h6" fontSize={15} fontFamily="InstrumentSansBold">
            Sélectionnez un dossier :
          </Typography>

          <Grid container spacing={1} alignItems="center" mb={2.5}>
            <Grid item flex={1}>
              <TextField
                error={tiimeProjectCreationError !== ''}
                onChange={e => setTiimeProjectToLink(e.target.value)}
                fullWidth
                id="outlined-select-tiime-company"
                select
                label="Choisissez une entreprise ..."
                value={tiimeProjectToLink || ''}
                helperText={tiimeProjectCreationError || 'Nécessite un compte Tiime Expert pour activer la connexion'}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 6 + 8
                      }
                    }
                  }
                }}
              >
                {tiimeCompanies.length === 0 && (
                  <MenuItem disabled value={null}>
                    Pas d'entreprises
                  </MenuItem>
                )}
                {tiimeCompanies.map(company => {
                  const isCompanyAlreadyImported = !availableCompaniesSirens.includes(company.siren);
                  return (
                    <MenuItem disabled={isCompanyAlreadyImported} key={company.siren} value={company.siren}>
                      {company.name} ({getHumanReadableSiren(company.siren)}){isCompanyAlreadyImported && ' - Dossier déja importé'}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item sx={{marginTop: -20}}>
              <Tooltip title="Mettre à jour la liste">
                <IconButton size="large" aria-label="Actualiser maintenant" sx={{p: 1, mr: 0.5}} onClick={refreshCompaniesList}>
                  <RotatingIcon isRotating={isRefreshingCompaniesList} icon={Refresh} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

TiimeConnectorForm.propTypes = {
  handleClose: func.isRequired
};

export default TiimeConnectorForm;

import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';

import useReport from '../../../hooks/providers/useReport';
import Switch from '../../switch/Switch';

const AutoSaveButton = () => {
  const {enableOrDisableAutoSave, autoSaveDisabled} = useReport();

  return (
    <Grid container alignItems="center" spacing={0.5}>
      <Grid item>
        <Typography color="text.primary" fontWeight={300} fontSize={13} lineHeight={1}>
          Enregistrement automatique
        </Typography>
      </Grid>
      <Grid item position="relative">
        <Switch onChange={enableOrDisableAutoSave} checked={!autoSaveDisabled} />
      </Grid>
    </Grid>
  );
};

AutoSaveButton.propTypes = {};

export default AutoSaveButton;

import {useLayoutEffect, useRef, useState} from 'react';

import {useWindowSize} from '@uidotdev/usehooks';

const useDimensions = () => {
  const ref = useRef();
  const windowSize = useWindowSize();
  const [dimensions, setDimensions] = useState({});

  useLayoutEffect(() => {
    if (ref.current) {
      setDimensions(ref.current.getBoundingClientRect().toJSON());
    }
  }, [ref.current, windowSize.width]);

  return [ref, dimensions];
};

export default useDimensions;

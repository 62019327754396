import {SUMMARY_THEMES} from '../../const';

const getHeaderRow = summaryTheme => {
  const categoryHeaderCell = {type: 'header', text: 'Catégorie', className: 'header-cell'};
  return {
    rowId: 'header',
    height: 40,
    cells: [
      {type: 'header', text: 'Mois', className: 'header-cell'},
      ...(summaryTheme !== SUMMARY_THEMES.ca ? [categoryHeaderCell] : []),
      {type: 'header', text: 'Libellé', className: 'header-cell'},
      {type: 'header', text: 'Montant', className: 'header-cell'},
      {type: 'header', text: ' ', className: 'header-cell'}
    ]
  };
};

const SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_HEADER_ROW = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'Mois', className: 'header-cell'},
    {type: 'header', text: 'Montant', className: 'header-cell'},
    {type: 'header', text: ' ', className: 'header-cell'}
  ]
};

const SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES = {
  date: 'date',
  category: 'category',
  amount: 'amount',
  label: 'label',
  delete: 'delete'
};

const SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS = {
  date: {
    name: 'date',
    width: 250
  },
  amount: {
    name: 'amount',
    width: 250
  },
  delete: {
    name: 'delete',
    width: 50
  }
};

const SUMMARIES_SHARE_FULL_DATA_SPREADSHEET_COLUMNS = {
  date: {
    name: 'date',
    width: 150
  },
  label: {
    name: 'label',
    width: 150
  },
  amount: {
    name: 'amount',
    width: 150
  },
  delete: {
    name: 'delete',
    width: 50
  }
};

const getSummariesShareDataColumns = (isAnonymized, summaryTheme = null) => {
  const cols = isAnonymized ? SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS : SUMMARIES_SHARE_FULL_DATA_SPREADSHEET_COLUMNS;
  let columnsToCreate = Object.values(cols);

  if (isAnonymized) {
    columnsToCreate = columnsToCreate.filter(c => c.name !== SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.label);
    return columnsToCreate.map(c => ({
      columnId: c.name,
      width: c.width,
      resizable: true
    }));
  }

  if (summaryTheme !== SUMMARY_THEMES.ca) {
    const categoryColumn = {
      resizable: true,
      columnId: SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.category,
      width: 150
    };
    const index = 1; // Category column is at index 1
    columnsToCreate.splice(index, 0, categoryColumn);
  }
  return columnsToCreate.map(c => ({
    columnId: c.name,
    width: c.width,
    resizable: true
  }));
};

const groupEntriesAmountsByDate = array => {
  const groupedMap = array.reduce((acc, item) => {
    const date = new Date(item.Mois);
    const dateKey = date.toLocaleDateString('fr-FR');

    if (acc.has(dateKey)) {
      const currentAmount = acc.get(dateKey).Montant;
      const newAmount = item.Montant + currentAmount;
      acc.set(dateKey, {
        ...item,
        Montant: newAmount
      });
    } else {
      acc.set(dateKey, {
        ...item,
        Montant: item.Montant
      });
    }

    return acc;
  }, new Map());

  return Array.from(groupedMap.values());
};

export {getHeaderRow, SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_HEADER_ROW, SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES, getSummariesShareDataColumns, groupEntriesAmountsByDate};

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {Divider, Menu, Tooltip, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import {bool, element, func, node, object, oneOf, oneOfType} from 'prop-types';

const ChatbotMainMenu = ({isOpen, onClose, anchorEl}) => {
  const theme = useTheme();

  return (
    <Menu
      sx={{zIndex: 999999999999999}}
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Tooltip placement="top" arrow title="Bientôt disponible">
        <Box mb={0.5}>
          <MenuItem disabled>
            <ListItemIcon sx={{color: theme.palette.primary.main}}>
              <AddCircleOutlineRoundedIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Créer mon assistant</ListItemText>
          </MenuItem>
        </Box>
      </Tooltip>
      <Divider />
      <Tooltip placement="bottom" arrow title="Bientôt disponible">
        <Box mt={0.5}>
          <MenuItem disabled>
            <ListItemIcon color={theme.palette.primary.main}>
              <SettingsOutlinedIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Paramètres assistant Drivn</ListItemText>
          </MenuItem>
        </Box>
      </Tooltip>
    </Menu>
  );
};

ChatbotMainMenu.defaultProps = {
  anchorEl: null
};

ChatbotMainMenu.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  anchorEl: oneOfType([object, element, node, oneOf([null])])
};

export default ChatbotMainMenu;

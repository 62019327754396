import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import IconButton from '@mui/material/IconButton';
import {func} from 'prop-types';

const OpenOverflowedTabsMenuButton = ({onClick}) => {
  return (
    <IconButton onClick={onClick} edge="start" color="inherit" aria-label="plus de rapports" sx={{mr: 1}}>
      <ExpandMoreOutlinedIcon
        sx={{
          fontSize: 28,
          borderRadius: '50%',
          background: 'white',
          boxShadow: '0px 0px 6px #00000029'
        }}
        color="primary"
      />
    </IconButton>
  );
};

OpenOverflowedTabsMenuButton.propTypes = {
  onClick: func.isRequired
};

export default OpenOverflowedTabsMenuButton;

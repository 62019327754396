import {useState} from 'react';

import 'dayjs/locale/fr';

import {TextField, DialogContent, DialogActions} from '@mui/material';
import {bool, func, string} from 'prop-types';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../../const';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button';
import BaseModal from '../../modal/BaseModal';

const ChoseNewPrompteTitleModal = ({instructions, isOpen, onClose}) => {
  const {promptModels, savePromptModel} = useReportSummary();

  const [title, setTitle] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveModel = async () => {
    setIsSubmitting(true);
    const res = await savePromptModel(instructions, title);
    setIsSubmitting(false);

    if (res.success) {
      onClose();
      setTitle('');
    }
  };

  const minimumNumberOrChars = 5;
  const maximumNumberOrChars = 255;

  const isTitleAlreadyTaken = promptModels.find(m => m.title === title) !== undefined;
  const hasTitleTextError = (title !== '' && !REGULAR_EXPRESSIONS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars).test(title)) || isTitleAlreadyTaken;

  const getHelperText = () => {
    if (!hasTitleTextError) return '';

    if (isTitleAlreadyTaken) {
      return 'Ce titre est déja utilisé';
    }

    return REGULAR_EXPRESSIONS_ERROR_TEXTS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars);
  };

  const isSaveModelButtonDisabled = () => {
    if (isSubmitting || !title || hasTitleTextError) {
      return true;
    }
    return false;
  };
  const saveModelButtonDisabled = isSaveModelButtonDisabled();

  return (
    <BaseModal onClose={onClose} open={isOpen} title="Choisir le titre du modèle">
      <DialogContent>
        <TextField
          autoFocus
          sx={{mb: 2}}
          required
          value={title}
          onChange={event => {
            setTitle(event.target.value);
          }}
          label="Titre du modèle"
          placeholder="Renseigner le titre..."
          color="primary"
          fullWidth
          error={hasTitleTextError}
          helperText={getHelperText()}
        />
      </DialogContent>

      <DialogActions px={3} sx={{justifyContent: 'flex-start', width: 'auto'}}>
        <Button disabled={saveModelButtonDisabled} variant="contained" color="success" onClick={saveModel}>
          Sauvegarder
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

ChoseNewPrompteTitleModal.defaultProps = {
  isOpen: false
};

ChoseNewPrompteTitleModal.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
  instructions: string.isRequired
};

export default ChoseNewPrompteTitleModal;

import {memo, useEffect, useState} from 'react';

import dayjs from 'dayjs';
import {arrayOf, bool, func, number, shape} from 'prop-types';

import {
  FORECAST_COLUMNS_NAMES as COLUMNS_NAMES,
  FORECAST_HEADER_ROW as HEADER_ROW,
  mappingCellTypeAndPropertyToSet,
  taxRatesDropdownOptions,
  convertFloatTaxRateToHumanReadable,
  getForecastColumns,
  movementTypesDropdownOptions,
  addContextMenuOption
} from '../../../utils';
import BaseReactGrid from '../../spreadsheets/BaseReactGrid';
import CreateForecastEntryModal from './CreateForecastEntryModal';

const ForecastSpreadsheet = ({data, setData, currentProject, setAddLineModalOpen, addLineModalOpen, hiddenRows, hiddenRowsVisible, showRows, hideRows}) => {
  const [isTaxRateDropdownOpen, setIsTaxRateDropdownOpen] = useState(false);
  const [isMovementTypeDropdownOpen, setIsMovementTypeDropdownOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const openAddLineModal = () => {
    setAddLineModalOpen(true);
  };

  const getRows = forecastData => {
    const r = [];
    forecastData.forEach((entry, index) => {
      const lines = forecastData.map(e => e.line).filter(line => typeof line === 'number');
      const maxRowId = forecastData.length > 0 ? Math.max(...lines) : 0;
      const id = entry.line || maxRowId + index;

      const rowIsHidden = hiddenRows.includes(id);
      if (!rowIsHidden || hiddenRowsVisible) {
        r.push({
          rowId: id,
          height: 40,
          cells: [
            {type: 'frenchDate', date: new Date(entry.date)},
            {type: 'text', text: entry.label},
            {type: 'frenchNumber', value: parseInt(entry.amount, 10)},
            {
              type: 'dropdown',
              selectedValue: convertFloatTaxRateToHumanReadable(entry.txTVA),
              values: taxRatesDropdownOptions,
              isOpen: isTaxRateDropdownOpen === id
            },
            {
              type: 'dropdown',
              selectedValue: entry.typeMouvement,
              values: movementTypesDropdownOptions,
              isOpen: isMovementTypeDropdownOpen === id
            }
          ]
        });
      }
    });
    return [HEADER_ROW, ...r];
  };

  const handleTaxRateChange = change => {
    if (change.type === 'dropdown' && change.columnId === COLUMNS_NAMES.txTVA) {
      if (change.previousCell.isOpen !== change.newCell.isOpen) {
        setIsTaxRateDropdownOpen(change.newCell.isOpen ? change.rowId : false);
      }
    }
  };

  const handleMovementTypeChange = change => {
    if (change.type === 'dropdown' && change.columnId === COLUMNS_NAMES.typeMouvement) {
      if (change.previousCell.isOpen !== change.newCell.isOpen) {
        setIsMovementTypeDropdownOpen(change.newCell.isOpen ? change.rowId : false);
      }
    }
  };

  const isNewValueValid = (columnId, newValue) => {
    let isValid = true;
    switch (columnId) {
      case COLUMNS_NAMES.date:
        isValid = dayjs(newValue).isValid();
        break;
      case COLUMNS_NAMES.amount:
        isValid = !Number.isNaN(newValue);
        break;
      case COLUMNS_NAMES.txTVA:
        isValid = taxRatesDropdownOptions.map(opt => opt.value).includes(newValue);
        break;
      case COLUMNS_NAMES.typeMouvement:
        isValid = movementTypesDropdownOptions.map(opt => opt.value).includes(newValue);
        break;
      default:
    }

    return isValid;
  };

  const applyChangesToEntries = (changes, previousData) => {
    const updatedData = [...previousData];

    changes.forEach(change => {
      const {rowId, columnId, type} = change;

      const cellPropertyToSet = mappingCellTypeAndPropertyToSet[type];
      const oldValue = change.previousCell[cellPropertyToSet];
      const newValue = change.newCell[cellPropertyToSet];

      handleTaxRateChange(change);
      handleMovementTypeChange(change);

      const isChangeValid = isNewValueValid(columnId, newValue);

      if (newValue !== oldValue && isChangeValid) {
        const itemToUpdateIndex = updatedData.findIndex(item => item.line === rowId);
        const updatedItem = updatedData[itemToUpdateIndex];

        updatedItem[columnId] = newValue;
        updatedData[itemToUpdateIndex] = updatedItem;
      }
    });

    return [...updatedData];
  };

  const handleChanges = changes => {
    setData(previousData => applyChangesToEntries(changes, previousData));
  };

  const handleRemoveAccountingEntries = rowIds => {
    setData(currentData => {
      return [...currentData.filter(entry => !rowIds.includes(entry.line))];
    });
  };

  const handleContextMenu = (selectedRowIds, selectedColIds, selectionMode, menuOptions) => {
    if (selectedRowId === null) {
      return [];
    }
    let options = [...menuOptions];

    // We only display 'Ajouter une ligne' when user selects a single row OR when he right-clicks (no rows selected)
    // We only display 'Supprimer une ligne' when user selects a single row OR he right-clicks on a cell (no rows selected)
    if (selectedRowIds.length <= 1) {
      options = addContextMenuOption(options, 'addChildRow', 'Ajouter une ligne', openAddLineModal);
    }

    const deleteRowsLabel = selectedRowIds.length > 1 ? 'Supprimer ces lignes' : 'Supprimer cette ligne';
    options = addContextMenuOption(options, 'removeRow', deleteRowsLabel, () => handleRemoveAccountingEntries(selectedRowIds));

    // We only display 'Masquer une ligne' when user selects a single row OR he right-clicks on a cell (no rows selected)
    if (selectedRowIds.length <= 1 && !hiddenRows.includes(selectedRowId)) {
      options = addContextMenuOption(options, 'hideRows', 'Masquer la ligne', () => hideRows([selectedRowId]));
    }

    // We only display 'Montrer une ligne' when user selects a single row OR he right-clicks on a cell (no rows selected)
    if (selectedRowIds.length <= 1 && hiddenRows.includes(selectedRowId)) {
      options = addContextMenuOption(options, 'showRows', 'Démasquer la ligne', () => showRows([selectedRowId]));
    }

    if (selectedRowIds.length > 1) {
      options = addContextMenuOption(options, 'hideManyRows', 'Masquer ces lignes', () => hideRows(selectedRowIds));
      options = addContextMenuOption(options, 'showManyRows', 'Démasquer ces lignes', () => showRows(selectedRowIds));
    }

    return options;
  };

  const handleAddNewAccountingEntry = newEntryData => {
    const {vatRate, movementType, ...rest} = newEntryData;
    const lastLineIndex = data.reduce((max, obj) => {
      return obj.line > max.line ? obj : max;
    }, data[0])?.line;

    const newAccountingEntry = {
      ...rest,
      txTVA: vatRate,
      typeMouvement: movementType,
      siren: currentProject.siren,
      line: lastLineIndex + 1
    };

    setData(currentData => {
      currentData.push(newAccountingEntry);
      return [...currentData];
    });
  };

  const handleCellFocusChange = cellLocation => {
    setSelectedRowId(cellLocation.rowId);
  };

  const columns = getForecastColumns();
  const rows = getRows(data);

  const calculatePaddingBottomForDropdown = () => {
    const dropdownOpen = isTaxRateDropdownOpen !== false || isMovementTypeDropdownOpen !== false;
    if (dropdownOpen) {
      const defaultPadding = isTaxRateDropdownOpen ? 130 : 160;
      const numberOfRowsToBottomOfSheet = rows.length - dropdownOpen - 1;
      return defaultPadding - numberOfRowsToBottomOfSheet * 35;
    }
    return 0;
  };

  const paddingBottomForDropdown = calculatePaddingBottomForDropdown();

  return (
    <>
      {data?.length > 0 && (
        <BaseReactGrid
          columnIdThatShouldTakeAvailableSpace={COLUMNS_NAMES.label}
          containerStyle={{paddingBottom: paddingBottomForDropdown}}
          onFocusLocationChanged={handleCellFocusChange}
          onContextMenu={handleContextMenu}
          onCellsChanged={handleChanges}
          rows={rows}
          columns={columns}
          enableFillHandle
        />
      )}

      <CreateForecastEntryModal closeModal={() => setAddLineModalOpen(false)} isOpen={addLineModalOpen} onSubmit={handleAddNewAccountingEntry} />
    </>
  );
};

ForecastSpreadsheet.propTypes = {
  currentProject: shape({}).isRequired,
  setAddLineModalOpen: func.isRequired,
  addLineModalOpen: bool.isRequired,
  data: arrayOf(shape({})).isRequired,
  setData: func.isRequired,
  hiddenRows: arrayOf(number).isRequired,
  hiddenRowsVisible: bool.isRequired,
  showRows: func.isRequired,
  hideRows: func.isRequired
};

export default memo(ForecastSpreadsheet);

import {useEffect, useMemo, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, CONNECTORS, DEFAULT_PROJECTS_SCHEMAS, PROJECTS_VIEW_TYPES, REGULAR_EXPRESSIONS, SNACKBAR_ACTIONS} from '../const';
import ProjectsContext from '../contexts/ProjectsContext';
import useHttp from '../hooks/misc/useHttp';
import {usePersistedReducer} from '../hooks/misc/usePersistedReducer';
import usePayment from '../hooks/providers/usePayment';
import useReport from '../hooks/providers/useReport';
import useSnackbar from '../hooks/providers/useSnackbar';
import useWorkspaces from '../hooks/providers/useWorkspaces';
import {getFileB64, getManualConnectorFilename} from '../utils';

const sortProjectsAlphabetically = (projects = []) => projects.sort((a, b) => a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase()));

const ACTIONS_TYPES = {
  SET_PENNYLANE_DATA_INTEGRATION_SIREN: 'setPennylaneDataIntegrationSiren',
  SET_PENNYLANE_DATA_INTEGRATION_JOB_URL: 'setPennylaneDataIntegrationJobUrl'
};

const ActionCreators = dispatch => ({
  setPennylaneDataIntegrationJobUrl: url => {
    dispatch({type: ACTIONS_TYPES.SET_PENNYLANE_DATA_INTEGRATION_JOB_URL, payload: url});
  },
  setPennylaneDataIntegrationSiren: siren => {
    dispatch({type: ACTIONS_TYPES.SET_PENNYLANE_DATA_INTEGRATION_SIREN, payload: siren});
  }
});

const initialState = {
  pennylaneDataIntegrationSiren: null,
  pennylaneDataIntegrationJobUrl: null
};

const pennylaneJobUrlStorageKey = 'PENNYLANE_DATA_INTEGRATION_JOB_URL';

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_PENNYLANE_DATA_INTEGRATION_JOB_URL:
      return {...state, pennylaneDataIntegrationJobUrl: action.payload};
    case ACTIONS_TYPES.SET_PENNYLANE_DATA_INTEGRATION_SIREN:
      return {...state, pennylaneDataIntegrationSiren: action.payload};
    default:
      throw new Error(`Action type ${action.type} doesn't exist`);
  }
};
const ProjectsProvider = ({children}) => {
  const paymentContext = usePayment();
  const reportsContext = useReport();
  const {shouldRegenerateToken, setShouldRegenerateToken, loadReports} = reportsContext;
  const {isDefaultWorkspace, selectedWorkspace} = useWorkspaces();
  const {_post, _get} = useHttp();
  const {showSnackbar, closeSnackbar, defaultSnackbarOptions, isOpen: isSnackbarOpen} = useSnackbar();

  const [projects, setProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [createError, setCreateError] = useState('');
  const [createProjectApiKeyError, setCreateProjectApiKeyError] = useState('');
  const [updateError, setUpdateError] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [updateProjectUsersError, setUpdateProjectUsersError] = useState('');
  const [updateProjectUsersSuccess, setUpdateProjectUsersSuccess] = useState(false);
  const [createProjectUsername, setCreateProjectUsername] = useState('');
  const [createProjectPassword, setCreateProjectPassword] = useState('');
  const [createProjectApiKey, setCreateProjectApiKey] = useState('');
  const [importFecFileError, setImportFecFileError] = useState('');
  const [selectedReportTab, setSelectedReportTab] = useState(paymentContext.authorizedModules ? paymentContext.authorizedModules[0] : DEFAULT_PROJECTS_SCHEMAS.gestion);
  const [createProjectSiren, setCreateProjectSiren] = useState('');
  const [isSirenValid, setIsSirenValid] = useState(false);
  const [projectCreated, setProjectCreated] = useState(false);
  const [isCegidFormValid, setIsCegidFormValid] = useState(false);
  const [createProjectSirenError, setCreateProjectSirenError] = useState('');
  const [shouldFetchProjects, setShouldFetchProjects] = useState(true);

  const {state, dispatch} = usePersistedReducer(reducer, initialState, pennylaneJobUrlStorageKey);
  const {setPennylaneDataIntegrationJobUrl, setPennylaneDataIntegrationSiren} = ActionCreators(dispatch);

  const [projectsViewType, setProjectsViewType] = useState(PROJECTS_VIEW_TYPES.card);
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [updateProjectModalOpen, setUpdateProjectModalOpen] = useState(false);
  const [manageProjectUsersModalOpen, setManageProjectUsersModalOpen] = useState(false);
  const [projectCardMenuAnchorEl, setProjectCardMenuAnchorEl] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectsSirenCurrentlyRefreshing, setProjectsSirenCurrentlyRefreshing] = useState([]);
  const [isProjectCreationSubmitting, setIsProjectCreationSubmitting] = useState(false);
  const [connector, setConnector] = useState('');
  const [actualizeProjectDataDatePickerOpen, setActualizeProjectDataDatePickerOpen] = useState(false);
  const [isActualizeProjectMenuOpen, setIsActualizeProjectMenuOpen] = useState(null);

  const setDefaultDefiReportTab = () => {
    const {authorizedModules} = paymentContext;
    // Default project page tab is "Defi Gestion", but some users do not have access to this module
    // For them, we set another module as a default tab.
    if (authorizedModules && !authorizedModules.includes(DEFAULT_PROJECTS_SCHEMAS.gestion)) {
      setSelectedReportTab(authorizedModules[0]);
    } else {
      setSelectedReportTab(DEFAULT_PROJECTS_SCHEMAS.gestion);
    }
  };

  useEffect(() => {
    setDefaultDefiReportTab();
  }, [paymentContext.authorizedModules]);

  // When a custom WS is deleted, we set DeFi WorkspacesTabs as current Tab.
  // This hook aims to automatically set "Gestion" (or any other default DeFi schema) after custom WS deletion.
  useEffect(() => {
    if (isDefaultWorkspace) {
      setDefaultDefiReportTab();
    }
  }, [selectedWorkspace]);

  // When a custom report is deleted, we have to set a new selected tab within current workspace.
  // This hook aims to automatically set a new selected tab after custom report deletion.
  useEffect(() => {
    if (!isDefaultWorkspace) {
      const noticeReport = selectedWorkspace.reports.find(r => r.is_notice_report);
      if (noticeReport) {
        setSelectedReportTab(noticeReport.report_id);
      } else {
        setSelectedReportTab(selectedWorkspace.reports[0].report_id);
      }
    }
  }, [selectedWorkspace.reports]);

  useEffect(() => {
    (async () => {
      if (shouldRegenerateToken) {
        await loadReports();
        setShouldRegenerateToken(false);
      }
    })();
  }, [shouldRegenerateToken]);

  const checkSirenValidity = sirenInput => {
    setIsSirenValid(REGULAR_EXPRESSIONS.siren.test(sirenInput));
  };

  const handleSirenChange = e => {
    const sirenWithoutSpaces = e.target.value.replaceAll(' ', '');
    setCreateProjectSiren(sirenWithoutSpaces);
    checkSirenValidity(sirenWithoutSpaces);
  };

  const incrementProjectsOwnedByUser = () => {
    paymentContext.setNumberOfProjectsOwnedByUser(prevNumber => prevNumber + 1);
  };

  const decrementProjectsOwnedByUser = () => {
    paymentContext.setNumberOfProjectsOwnedByUser(prevNumber => prevNumber - 1);
  };

  const fetchProjects = async () => {
    setIsLoading(true);

    const url = API_ENDPOINTS.projects.findAll;
    try {
      const {response: projectsResponse, responseJson: data} = await _get(url);

      if (projectsResponse.ok) {
        setProjects(sortProjectsAlphabetically(data));
        setIsLoading(false);
      } else {
        // eslint-disable-next-line no-console
        console.error({projectsResponse});
        if (projectsResponse.status === 401) {
          setShouldFetchProjects(true);
        }
        throw Error('Error during projects fecthing');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getCreateProjectApiUrl = pickedConnector => {
    const connectorEndpoints = {
      [CONNECTORS.unisoft]: API_ENDPOINTS.projects.create.myUnisoft,
      [CONNECTORS.vectron]: API_ENDPOINTS.projects.create.vectron,
      [CONNECTORS.pennylane]: API_ENDPOINTS.projects.create.pennylane,
      [CONNECTORS.cegidQuadra]: API_ENDPOINTS.projects.create.cegidQuadra,
      [CONNECTORS.acd]: API_ENDPOINTS.projects.create.acd
    };

    return connectorEndpoints[pickedConnector] || API_ENDPOINTS.projects.create.manual;
  };

  // eslint-disable-next-line complexity
  const createProject = async project => {
    const {siren, myUnisoftApiKey, vectronApiKey, vectronSiteId, cegidFolderId, siret, serverISuite} = project;
    try {
      setCreateError('');
      setCreateProjectApiKeyError('');
      setCreateProjectSirenError('');
      setPennylaneDataIntegrationJobUrl('');

      const url = getCreateProjectApiUrl(connector);
      const reportId = isDefaultWorkspace ? null : selectedReportTab;
      const schema = isDefaultWorkspace ? selectedReportTab : selectedWorkspace.schema_name;

      const {response, responseJson: data} = await _post(url, {
        siren,
        report_id: reportId,
        schema,
        ...(connector === CONNECTORS.manual && {first_fiscal_month: 1}),
        ...(connector === CONNECTORS.unisoft && {myunisoft_client_token: myUnisoftApiKey}),
        ...(connector === CONNECTORS.vectron && {
          vectron_customer_token: vectronApiKey,
          site_id: vectronSiteId
        }),
        ...(connector === CONNECTORS.pennylane && {
          penny_user: createProjectUsername,
          penny_password: createProjectPassword
        }),
        ...(connector === CONNECTORS.cegidQuadra && {
          siret,
          numero_dossier: cegidFolderId,
          client_id: createProjectUsername,
          client_secret: createProjectPassword
        }),
        ...(connector === CONNECTORS.acd && {
          acd_server: serverISuite,
          acd_usermail: createProjectUsername,
          acd_password: createProjectPassword
        })
      });

      // Business rule: If projects.length passes from 0 to 1 , or from 1 to 0 in a given module, we should regenerate token
      const numberOfProjectInSchemaBefore = projects.filter(p => p.schema_name === selectedReportTab).length;
      const numberOfProjectInSchemaAfter = numberOfProjectInSchemaBefore + 1;

      if (numberOfProjectInSchemaBefore === 0 && numberOfProjectInSchemaAfter === 1) {
        setShouldRegenerateToken(true);
      }

      switch (response.status) {
        case 200:
          // TODO handle this diffrently. We might be able to manually rebuild projects list without fetching again.
          await fetchProjects(); // We re-fetch projects so the projects page is up-to-date
          showSnackbar(SNACKBAR_ACTIONS.CREATE_PROJECT_SUCCESS);
          // Business rule : Projects created within custom workspace are not counted on creation
          // So we must not increment projects counter when creating a project within a custom ws.
          if (isDefaultWorkspace) {
            incrementProjectsOwnedByUser();
          }
          return {
            message: data,
            status: 200
          };
        case 201:
          // TODO handle this diffrently. We might be able to manually rebuild projects list without fetching again.
          await fetchProjects(); // We re-fetch projects so the projects page is up-to-date
          showSnackbar(data.message || SNACKBAR_ACTIONS.CREATE_PROJECT_SUCCESS_WITH_NAME, defaultSnackbarOptions, {createdProjectName: data.project_name});
          incrementProjectsOwnedByUser();
          return {
            message: data,
            status: 201
          };
        case 202:
          setPennylaneDataIntegrationSiren(siren);
          setPennylaneDataIntegrationJobUrl(data.statusQueryGetUri);
          // TODO handle this diffrently. We might be able to manually rebuild projects list without fetching again.
          await fetchProjects(); // We re-fetch projects so the projects page is up-to-date
          showSnackbar(SNACKBAR_ACTIONS.PENNYLANE_DATA_INTEGRATION_IN_PROGRESS, {
            // TODO see with adrian to change this message, or make it dynamic, or use backend message
            severity: 'info',
            autoHide: false
          });
          return {
            message: data,
            status: 202
          };
        case 203:
        case 403:
          setCreateProjectApiKeyError(data);
          return {
            message: data,
            status: response.status
          };
        case 400:
          if (selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.previ) {
            setCreateError(data);
          } else {
            setCreateProjectSirenError(data);
          }
          return {
            message: data,
            status: 400
          };
        case 502:
          throw Error("Une erreur d'intégration à eu lieu. Nos équipes sont prévenues et le problème est en cours de résolution. Pour toutes questions, vous pouvez nous contacter via le chat");
        default:
          throw Error(data?.message || data);
      }
    } catch (e) {
      setCreateError(e.message);
      return {
        message: e.message
      };
    }
  };

  const updateProject = async project => {
    const {siren, logo, name, month} = project;
    try {
      setUpdateError('');
      const integerMonth = parseInt(month, 10);

      const url = API_ENDPOINTS.projects.update;
      const {response, responseJson: data} = await _post(url, {
        siren,
        url_logo: logo,
        project_name: name,
        first_fiscal_month: integerMonth,
        schema: selectedReportTab
      });

      if (response.status === 200) {
        const projectToUpdate = projects.find(p => p.siren === siren);
        showSnackbar(SNACKBAR_ACTIONS.UPDATE_PROJECT_SUCCESS, defaultSnackbarOptions, {firstFiscalMonthHasChanged: integerMonth !== projectToUpdate.first_fiscal_month});
        await fetchProjects(); // We re-fetch projects so the projects page is up-to-date
        return {
          message: data?.message || data,
          status: 200
        };
      }
      throw Error(data?.message || data);
    } catch (e) {
      setUpdateError(e.message);
      return {
        message: e.message
      };
    }
  };

  const addUser = (siren, user) => {
    const projectsBefore = [...projects];
    const projectIndex = projectsBefore.findIndex(p => p.siren === siren && p.schema_name === selectedReportTab);
    const project = projectsBefore[projectIndex];
    project.users = [
      ...project.users,
      {
        username: user.username,
        user_id: user.userId,
        is_owner: false
      }
    ];
    projectsBefore[projectIndex] = project;
  };

  const addUserToProject = async (email, siren) => {
    try {
      setUpdateProjectUsersError('');

      const url = API_ENDPOINTS.projects.addUser;
      const {response, responseJson: data} = await _post(url, {
        guest_usermail: email,
        siren,
        schema: selectedReportTab
      });

      if (response.status === 200) {
        addUser(siren, {
          username: data.givenName,
          userId: data.id
        });
        showSnackbar(SNACKBAR_ACTIONS.ADD_USER_SUCCESS, defaultSnackbarOptions, {newUserUsername: data.givenName});
      }

      if (response.status === 200 || response.status === 402) {
        const user = {
          username: data.givenName,
          userId: data.id
        };
        return {
          message: data,
          status: response.status,
          user: response.status === 200 ? user : null
        };
      }

      throw Error(data?.message || data);
    } catch (e) {
      setUpdateProjectUsersError(e.message);
      return {
        message: e
      };
    }
  };

  const deleteUser = (siren, userIdToRemove) => {
    const projectsBefore = [...projects];
    const projectIndex = projectsBefore.findIndex(p => p.siren === siren && p.schema_name === selectedReportTab);
    const project = projectsBefore[projectIndex];
    project.users = project.users.filter(u => u.user_id !== userIdToRemove);
    projectsBefore[projectIndex] = project;
  };

  const deleteUserFromProject = async (userId, siren, isUserDeletingHimself = false) => {
    try {
      setUpdateProjectUsersError('');

      const url = API_ENDPOINTS.projects.deleteUser;
      const {response, responseJson: data} = await _post(url, {
        delete_user_id: userId,
        siren,
        schema: selectedReportTab
      });

      if (response.status === 200) {
        // We have to remove project from projects list if user deleted himself
        if (isUserDeletingHimself) {
          setProjects(previousProjects => previousProjects.filter(p => p.siren !== siren));
        } else {
          deleteUser(siren, userId);
        }
        showSnackbar(SNACKBAR_ACTIONS.DELETE_USER_SUCCESS);

        return {
          message: data,
          status: 200
        };
      }
      throw Error(data?.message || data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setUpdateProjectUsersError(e.message);
      return {
        message: e
      };
    }
  };

  const promoteOrDemoteUser = async ({siren, userId, isOwner}) => {
    try {
      const url = API_ENDPOINTS.users.promoteOrDemote;
      const {response, responseJson: data} = await _post(url, {
        siren,
        owner_id: userId,
        is_owner: isOwner,
        schema: selectedReportTab
      });

      const project = projects.find(p => p.siren === siren && p.schema_name === selectedReportTab);

      const {username} = project.users.find(u => u.user_id === userId);

      if (response.status === 200) {
        showSnackbar(isOwner ? SNACKBAR_ACTIONS.PROMOTE_USER_SUCCESS : SNACKBAR_ACTIONS.DEMOTE_USER_SUCCESS, defaultSnackbarOptions, {
          promotedUserEmail: isOwner ? username : undefined,
          demotedUserEmail: !isOwner ? username : undefined
        });
        return {
          message: data,
          status: response.status
        };
      }
      if (response.status === 400) {
        showSnackbar(data, {severity: 'error', duration: 4000});
      }
      throw Error(data?.message || data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e
      };
    }
  };

  const deleteProject = async (project, schema = selectedReportTab) => {
    try {
      setDeleteError('');

      const url = API_ENDPOINTS.projects.delete;
      const {response, responseJson: data} = await _post(url, {
        siren: project.siren,
        schema: isDefaultWorkspace ? schema : selectedWorkspace.schema_name,
        report_id: project.report_id
      });

      if (response.status !== 200) {
        throw Error(data?.message || data);
      } else {
        // Business rule : Projects created within custom workspace are not counted on creation
        // So we must not decrement projects counter when deleting a custom ws project.
        // PS : only custom ws projects have a report_id
        if (!project.report_id) {
          decrementProjectsOwnedByUser();
        }

        // Business rule: DeFi Previ projects must be deleted automatically when associated DeFi Gestion project is deleted.
        // If a DeFi gestion project is deleted and a DeFi Previ project exists within the same siren
        // Same logic applies for projects created in custom workspaces. Because they are created from DeFi Gestion projects,
        // They must be deleted if "source" project is deleted
        const associatedProjects = schema === DEFAULT_PROJECTS_SCHEMAS.gestion ? projects.filter(p => p.siren === project.siren && p.schema_name !== DEFAULT_PROJECTS_SCHEMAS.gestion) : [];
        if (associatedProjects.length > 0) {
          const deleteAssociatedProjectsPromises = associatedProjects.map(p => deleteProject(p, p.schema_name));
          await Promise.all(deleteAssociatedProjectsPromises);
        }

        // Business rule: If projects.length passes from 0 to 1 , or from 1 to 0 in a given module, we should regenerate token
        const numberOfProjectInSchemaBefore = projects.filter(p => p.schema_name === schema).length;
        const numberOfProjectInSchemaAfter = numberOfProjectInSchemaBefore - 1;

        if (numberOfProjectInSchemaBefore === 1 && numberOfProjectInSchemaAfter === 0) {
          setShouldRegenerateToken(true);
        }
        return {
          status: 200
        };
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setDeleteError(e.message);
      return {
        message: e.message
      };
    }
  };

  const sendContactMessage = async (subject, message, shouldDisplaySnackbar = true) => {
    try {
      const url = API_ENDPOINTS.projects.contact;
      const {response, responseJson: data} = await _post(url, {
        subject,
        message
      });

      if (response.status === 200) {
        if (shouldDisplaySnackbar) {
          showSnackbar(SNACKBAR_ACTIONS.SEND_CONTACT_MESSAGE_SUCCESS);
        }
        return {
          message: data?.message || data,
          status: 200
        };
      }
      return {
        status: response.status,
        message: data?.message || data
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e
      };
    }
  };

  const importFecFile = async (siren, file) => {
    try {
      const filename = await getManualConnectorFilename(file, siren);
      const b64 = await getFileB64(file);

      const url = API_ENDPOINTS.projects.import;
      const {response, responseJson: data} = await _post(url, {
        filename,
        file_b64: b64.split('base64,')[1],
        siren
      });

      if (response.status !== 200) {
        throw Error(data?.message || data);
      }
      return {
        status: 200
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setImportFecFileError(e.message);
      return {
        message: e.message
      };
    }
  };

  const enableOrDisableDemo = async activated => {
    try {
      const url = API_ENDPOINTS.projects.demo;
      const {response, responseJson: data} = await _post(url, {
        activate: activated,
        schema: selectedReportTab
      });

      if (response.status !== 200 && response.status !== 201) {
        throw Error(data?.message || data);
      }
      // Business rule: If projects.length passes from 0 to 1 , or from 1 to 0 in a given module, we should regenerate token
      const numberOfProjectInSchemaBefore = projects.filter(p => p.schema_name === selectedReportTab).length;
      const numberOfProjectInSchemaAfter = activated ? numberOfProjectInSchemaBefore + 2 : numberOfProjectInSchemaBefore - 2; // We have 2 demo projects

      if ((numberOfProjectInSchemaBefore >= 1 && numberOfProjectInSchemaAfter === 0) || (numberOfProjectInSchemaBefore === 0 && numberOfProjectInSchemaAfter >= 1)) {
        setShouldRegenerateToken(true);
      }
      await fetchProjects();
      showSnackbar(data);
      return {
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };

  const manualRefreshProject = async (project, date = null) => {
    try {
      const url = API_ENDPOINTS.projects.refresh.manual;
      showSnackbar(
        SNACKBAR_ACTIONS.REFRESH_PROJECT_LOADING,
        {
          severity: 'info',
          autoHide: false // We want to wait for API response to close the snackbar, not setting a timeout for X seconds before closing it.
        },
        {projectRefreshing: project.project_name}
      );

      const {response} = await _post(url, {
        siren: project.siren,
        ...(date !== null && {pivot_date: date})
      });

      if (response.status !== 200 && response.status !== 201) {
        throw Error(response);
      }
      closeSnackbar();
      showSnackbar(SNACKBAR_ACTIONS.REFRESH_PROJECT_SUCCESS);
      setTimeout(async () => {
        await fetchProjects();
      }, 1000);
      return {
        status: 200,
        message: 'success'
      };
    } catch (e) {
      closeSnackbar();
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };

  const enableOrDisableProjectAutoRefresh = async (siren, autoUpdate) => {
    try {
      const url = API_ENDPOINTS.projects.refresh.enableDisableAuto;
      const {response} = await _post(url, {
        siren,
        auto_update: autoUpdate,
        schema: selectedReportTab
      });

      if (response.status !== 200 && response.status !== 201) {
        throw Error(response);
      }
      await fetchProjects();
      return {
        status: 200,
        message: 'success'
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };

  // eslint-disable-next-line complexity
  const getPennylaneDataIntegrationJobStatus = async () => {
    const JOB_STATUSES = {
      completed: 'Completed',
      pending: 'Pending',
      failed: 'Failed'
    };

    const url = API_ENDPOINTS.projects.pennylaneDataIntegrationStatus;

    try {
      const {response, responseJson: data} = await _post(url, {
        statusQueryGetUri: state.pennylaneDataIntegrationJobUrl
      });

      // If API indicated that job has failed, we throw an error and will display a snackbar
      if (response.status === 200 && data.runtimeStatus === JOB_STATUSES.failed) {
        setPennylaneDataIntegrationSiren(null);
        throw Error(response);
      }

      // If API choked ( job has not failed, but API does not return either 200 or 202 success statutes)
      // We just return and we do not throw an error
      if (response.status !== 200 && response.status !== 202) {
        return {
          success: false
        };
      }

      if (response.status === 200 && data.runtimeStatus === JOB_STATUSES.completed) {
        setPennylaneDataIntegrationSiren(null);
        closeSnackbar();
        showSnackbar(data.output);
      }

      if (response.status === 202 && data.runtimeStatus === JOB_STATUSES.pending && !isSnackbarOpen) {
        showSnackbar(SNACKBAR_ACTIONS.PENNYLANE_DATA_INTEGRATION_IN_PROGRESS, {
          severity: 'info',
          autoHide: false
        });
      }
      return {
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      closeSnackbar();
      showSnackbar(SNACKBAR_ACTIONS.PENNYLANE_DATA_INTEGRATION_ERROR, {severity: 'error'});
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };

  const schemaProjects = (projects || []).filter(p => p.schema_name === selectedReportTab);
  const isDemoActivated = schemaProjects.some(p => REGULAR_EXPRESSIONS.demoSiren.test(p.siren));

  const projectsWithoutDemoProjects = projects?.filter(p => !REGULAR_EXPRESSIONS.demoSiren.test(p.siren));
  // App is in "guest mode" if user is invited at least on one project (except demo projects)
  const isGuestMode = projectsWithoutDemoProjects?.some(p => !p.is_owner);

  const currentReport = selectedWorkspace?.reports?.find(r => r.report_id === selectedReportTab) || {};

  const runManualProjectDataRefresh = async (project, date = null) => {
    setProjectsSirenCurrentlyRefreshing(sirens => [...sirens, project.siren]);
    await manualRefreshProject(project, date);
    setProjectsSirenCurrentlyRefreshing(sirens => [...sirens.filter(s => s !== project.siren)]);
  };

  const closeProjectMenu = () => {
    setProjectCardMenuAnchorEl(null);
    setSelectedProject(null);
  };

  const updateSummaryInProjects = ({newSummary, theme, siren}) => {
    const projectToUpdateIndex = projects.findIndex(p => p.siren === siren && p.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion);
    const newProjects = [...projects];
    const updatedProject = newProjects[projectToUpdateIndex];
    newProjects.splice(projectToUpdateIndex, 1);

    const summaryToUpdateIndex = updatedProject.summaries.findIndex(s => s.themes === theme);
    const updatedSummary = updatedProject.summaries[summaryToUpdateIndex];

    updatedProject.summaries.splice(summaryToUpdateIndex, 1);
    updatedSummary.summaries = newSummary;
    updatedProject.summaries.push(updatedSummary);

    newProjects.push(updatedProject);
    setProjects(newProjects);
    reportsContext.setSelectedProject(updatedProject);
  };

  const memoizedValues = {
    projects,
    setProjects,
    fetchProjects,
    updateProject,
    deleteProject,
    updateError,
    setUpdateError,
    deleteError,
    setDeleteError,
    addUserToProject,
    updateProjectUsersError,
    setUpdateProjectUsersError,
    deleteUserFromProject,
    updateProjectUsersSuccess,
    setUpdateProjectUsersSuccess,
    createProject,
    createError,
    createProjectApiKeyError,
    setCreateProjectApiKeyError,
    setCreateError,
    isLoading,
    setIsLoading,
    sendContactMessage,
    importFecFileError,
    setImportFecFileError,
    importFecFile,
    enableOrDisableDemo,
    promoteOrDemoteUser,
    selectedReportTab,
    setSelectedReportTab,
    siren: createProjectSiren,
    setSiren: setCreateProjectSiren,
    isSirenValid,
    handleSirenChange,
    projectCreated,
    setProjectCreated,
    isCegidFormValid,
    setIsCegidFormValid,
    createProjectSirenError,
    setCreateProjectSirenError,
    shouldFetchProjects,
    setShouldFetchProjects,
    isDemoActivated,
    isGuestMode,
    username: createProjectUsername,
    setUsername: setCreateProjectUsername,
    password: createProjectPassword,
    setPassword: setCreateProjectPassword,
    apiKey: createProjectApiKey,
    setApiKey: setCreateProjectApiKey,
    manualRefreshProject,
    enableOrDisableProjectAutoRefresh,
    setDefaultDefiReportTab,
    pennylaneDataIntegrationSiren: state.pennylaneDataIntegrationSiren,
    pennylaneDataIntegrationInProgress: state.pennylaneDataIntegrationSiren !== null,
    getPennylaneDataIntegrationJobStatus,
    viewType: projectsViewType,
    setViewType: setProjectsViewType,
    deleteProjectModalOpen,
    setDeleteProjectModalOpen,
    updateProjectModalOpen,
    setUpdateProjectModalOpen,
    manageProjectUsersModalOpen,
    setManageProjectUsersModalOpen,
    projectCardMenuAnchorEl,
    setProjectCardMenuAnchorEl,
    selectedProject,
    setSelectedProject,
    projectsSirenCurrentlyRefreshing,
    setProjectsSirenCurrentlyRefreshing,
    currentReport,
    runManualProjectDataRefresh,
    isSubmitting: isProjectCreationSubmitting,
    setIsSubmitting: setIsProjectCreationSubmitting,
    connector,
    setConnector,
    actualizeProjectDataDatePickerOpen,
    setActualizeProjectDataDatePickerOpen,
    closeProjectMenu,
    isActualizeProjectMenuOpen,
    setIsActualizeProjectMenuOpen,
    updateSummaryInProjects
  };

  const useMemoDeps = Object.values(memoizedValues).map(value => value);

  const value = useMemo(() => memoizedValues, useMemoDeps);

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};
ProjectsProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ProjectsProvider;

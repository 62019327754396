import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';

import useTranscript from '../../../hooks/providers/useTranscript';
import Button from '../../form/buttons/Button';

const StartRecordingButton = () => {
  const {handleStartRecording: startRecording} = useTranscript();

  return (
    <Button size="large" startIcon={<KeyboardVoiceOutlinedIcon />} variant="contained" color="primary" onClick={startRecording} sx={{mt: 1, margin: '0 auto'}}>
      Lancer l'enregistrement vocal
    </Button>
  );
};

export default StartRecordingButton;

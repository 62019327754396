import {useEffect, useState} from 'react';

import ReplayIcon from '@mui/icons-material/Replay';
import {DialogActions, DialogContent, Typography, useTheme} from '@mui/material';
import {grey} from '@mui/material/colors';

import {SNACKBAR_ACTIONS} from '../../../const';
import useSnackbar from '../../../hooks/providers/useSnackbar';
import useTranscript from '../../../hooks/providers/useTranscript';
import CustomAlert from '../../alert/Alert';
import OpenContactFormButton from '../../contact/OpenContactFormButton';
import Button from '../../form/buttons/Button';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import RecordForm from '../RecordForm/RecordForm';
import RecordResult from '../RecordResult/RecordResult';

const TranscriptWorkingDayModal = () => {
  const [isTranscripting, setIsTranscripting] = useState(false);
  const [isSavingTranscription, setIsSavingTranscription] = useState(false);

  const {showSnackbar} = useSnackbar();
  const {
    transcriptionData,
    setTranscriptionData,
    isTranscriptWorkingDayModalOpen: isOpen,
    setIsTranscriptWorkingDayModalOpen: setIsOpen,
    transcriptWorkingDay,
    isRecordingDone,
    setIsRecordingDone,
    base64Data,
    setBase64Data,
    isRecording,
    userHasDeclinedMicrophonePermission,
    audioExceedsMaximumSize,
    audioWillSoonExceedMaximumSize,
    estimatedFileSize,
    formatApiTranscriptionData,
    saveWorkingDayTranscription
  } = useTranscript();
  const theme = useTheme();

  const closeModal = () => {
    setIsOpen(false);
  };

  const sendRecordForTranscription = async () => {
    try {
      setIsTranscripting(true);
      const res = await transcriptWorkingDay(base64Data);

      if (res.success) {
        const ids = (transcriptionData || []).map(e => e.id);
        const maxRowId = transcriptionData?.length > 0 ? Math.max(...ids) : 0;
        const lastId = maxRowId + 1;

        const formattedData = formatApiTranscriptionData(res.data, lastId);
        setTranscriptionData(currentData => [...(currentData || [])].concat(formattedData));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsTranscripting(false);
    }
  };

  const reset = () => {
    setIsRecordingDone(false);
    setTranscriptionData(null);
    setBase64Data('');
  };

  const saveTranscription = async () => {
    setIsSavingTranscription(true);
    const res = await saveWorkingDayTranscription(transcriptionData);
    setIsSavingTranscription(false);
    if (res.success) {
      reset();
      closeModal();
    }
  };

  const isTranscriptionDataValid = () => {
    if (!transcriptionData) return false;
    return transcriptionData.every(obj => Object.values(obj).every(value => value !== ''));
  };

  const isSubmitButtonDisabled = () => {
    if (isTranscripting || isSavingTranscription) return true;

    const isSpreadsheetDataValid = isTranscriptionDataValid();
    if (!isSpreadsheetDataValid) return true;

    return false;
  };

  useEffect(() => {
    (async () => {
      if (base64Data) {
        setIsRecordingDone(true);
        await sendRecordForTranscription();
        // setBase64Data('');
      }
    })();
  }, [base64Data]);

  useEffect(() => {
    if (userHasDeclinedMicrophonePermission) {
      showSnackbar(SNACKBAR_ACTIONS.USER_HAS_DECLINED_MICROPHONE_PERMISSION, {severity: 'error', duration: 5000});
    }
  }, [userHasDeclinedMicrophonePermission]);

  useEffect(() => {
    if (audioExceedsMaximumSize) {
      showSnackbar(SNACKBAR_ACTIONS.TRANSCRIPTION_AUDIO_FILE_EXCEEDS_MAXIMUM_SIZE, {
        severity: 'error',
        duration: 5000
      });
    }
  }, [audioExceedsMaximumSize]);

  useEffect(() => {
    if (audioWillSoonExceedMaximumSize && isRecording) {
      showSnackbar(
        SNACKBAR_ACTIONS.TRANSCRIPTION_AUDIO_FILE_WILL_SOON_EXCEED_MAXIMUM_SIZE_WARNING,
        {
          severity: 'info',
          autoHide: false
        },
        {currentFileSize: estimatedFileSize}
      );
    }
  }, [isRecording, estimatedFileSize, audioWillSoonExceedMaximumSize]);

  const submitButtonDisabled = isSubmitButtonDisabled();

  return (
    <BaseModal
      overridesPaperProps={base64Data && !isTranscripting ? {height: '100%'} : {}}
      canCloseOnBackdropClick={false}
      hasCloseIcon
      onClose={closeModal}
      maxWidth="xl"
      open={isOpen}
      title="Enregistrer ma journée"
    >
      <DialogContent sx={{px: 3, py: 2, mb: 1}}>
        {isTranscripting && <Spinner isLoading text="Transcription en cours..." />}
        {!isTranscripting && (
          <>
            <RecordForm />
            <RecordResult isSubmitting={isTranscripting} />
          </>
        )}
      </DialogContent>

      {isSavingTranscription && <Spinner isLoading text="Sauvegarde en cours..." />}

      {isRecordingDone && (
        <DialogActions sx={{justifyContent: 'center'}} pb={1}>
          <Button onClick={saveTranscription} disabled={submitButtonDisabled} variant="contained" color="secondary">
            Valider l'enregistrement
          </Button>
          <Button disabled={isTranscripting} startIcon={<ReplayIcon />} variant="contained" color="error" onClick={reset}>
            Recommencer
          </Button>
        </DialogActions>
      )}
      <CustomAlert sx={{background: theme.palette.primary.light, color: grey[700]}}>
        <OpenContactFormButton text="Ecrivez-nous" />
        <Typography fontSize={14}>pour nous partager vos impressions </Typography>
      </CustomAlert>
    </BaseModal>
  );
};

export default TranscriptWorkingDayModal;

import dayjs from 'dayjs';

import {CHATBOT_MESSAGES_ROLES, CHATBOT_USER_MESSAGE_TYPES} from '../const';

const isSameDay = (date1, date2) => {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

const groupThreadsByTime = threadsToGroup => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to the beginning of the day

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const last7Days = new Date(today);
  last7Days.setDate(last7Days.getDate() - 7);

  const last30Days = new Date(today);
  last30Days.setDate(last30Days.getDate() - 30);

  const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const thisYearStart = new Date(today.getFullYear(), 0, 1);

  const todayThreads = [];
  const yesterdayThreads = [];
  const last7DaysThreads = [];
  const last30DaysThreads = [];
  const thisMonthThreads = [];
  const thisYearThreads = [];

  threadsToGroup.forEach(thread => {
    const creationTime = new Date(thread.creation_time * 1000); // Convert seconds to milliseconds
    if (isSameDay(creationTime, today)) {
      todayThreads.push(thread);
    } else if (isSameDay(creationTime, yesterday)) {
      yesterdayThreads.push(thread);
    } else if (creationTime >= last7Days) {
      last7DaysThreads.push(thread);
    } else if (creationTime >= last30Days) {
      last30DaysThreads.push(thread);
    } else if (creationTime >= thisMonthStart) {
      thisMonthThreads.push(thread);
    } else if (creationTime >= thisYearStart) {
      thisYearThreads.push(thread);
    }
  });

  return {
    today: todayThreads,
    yesterday: yesterdayThreads,
    last7Days: last7DaysThreads,
    last30Days: last30DaysThreads,
    thisMonth: thisMonthThreads,
    thisYear: thisYearThreads
  };
};

const isFirstMessageOfDay = (threadMessages, message) => {
  const messageDate = dayjs.unix(message.created_at);
  const messagesOfSameDay = threadMessages.filter(m => dayjs.unix(m.created_at).isSame(messageDate, 'day'));
  let firstMessageOfDay = message;

  messagesOfSameDay.forEach(m => {
    if (dayjs.unix(m.created_at).isBefore(dayjs.unix(firstMessageOfDay.created_at))) {
      firstMessageOfDay = m;
    }
  });

  return firstMessageOfDay.created_at === message.created_at;
};

const getMessageBefore = (assistantsMessages, message) => {
  const messageTimestamp = message.created_at;
  let closestMessage = null;
  let minTimeDifference = Infinity;

  for (let i = 0; i < assistantsMessages.length; i++) {
    const currentMessageTimestamp = assistantsMessages[i].created_at;
    if (currentMessageTimestamp < messageTimestamp) {
      const timeDifference = messageTimestamp - currentMessageTimestamp;
      if (timeDifference < minTimeDifference) {
        closestMessage = assistantsMessages[i];
        minTimeDifference = timeDifference;
      }
    }
  }
  return closestMessage;
};

const hasAssistantChangedSinceLastMessage = (threadMessages, currentMessage) => {
  const assistantsMessages = threadMessages.filter(m => m.role === CHATBOT_MESSAGES_ROLES.assistant); // We do not want to get user messages

  // there is only a single message from any assistant in the thread
  if (assistantsMessages.length < 2) {
    return false;
  }

  const previousMessage = getMessageBefore(assistantsMessages, currentMessage);
  return previousMessage ? previousMessage.assistant_id !== currentMessage.assistant_id : false;
};

const groupVisualsByPage = visualsToGroup => {
  const groupedVisuals = {};

  visualsToGroup.forEach(visual => {
    const pageName = visual.page.name;
    if (!groupedVisuals[pageName]) {
      groupedVisuals[pageName] = [];
    }
    groupedVisuals[pageName].push(visual);
  });

  return groupedVisuals;
};

const createGptFormattedMessageForUser = (message, type = CHATBOT_USER_MESSAGE_TYPES.text) => {
  const creationTime = Math.floor(new Date().getTime() / 1000); // we want the user message to appear right before the answer

  return {
    role: CHATBOT_MESSAGES_ROLES.user,
    created_at: creationTime,
    content: [
      {
        type,
        text: {
          value: message
        }
      }
    ]
  };
};

const createGptFormattedMessageForAssistant = (message, assistant) => {
  const creationTime = Math.floor(new Date().getTime() / 1000); // we want the assistant message to appear right before the answer

  return {
    assistant_id: assistant.id,
    role: CHATBOT_MESSAGES_ROLES.assistant,
    created_at: creationTime,
    content: [
      {
        type: CHATBOT_USER_MESSAGE_TYPES.text,
        text: {
          value: message
        }
      }
    ]
  };
};

const csvToMarkdown = csvData => {
  // Split data into rows
  const rows = csvData.trim().split(/\r?\n/);

  // Extract headers
  const headers = rows.shift().split(',');

  // Initialize Markdown string
  let markdown = '';

  // Add headers to Markdown
  markdown += `| ${headers.join(' | ')} |\n`;
  markdown += `| ${'-'.repeat(headers.length * 4 - 1)} |\n`;

  // Iterate through rows
  rows.forEach(row => {
    // Use regex to split row into values
    const values = row.match(/(?:"[^"]*"|[^,])+/g);

    // Sometimes, we have csv values inside double quotes because they contain commas
    // So we remove leading and trailing double quotes from values
    values.forEach((value, index) => {
      values[index] = value.replace(/^"|"$/g, '');
    });

    // Add values to Markdown
    markdown += `| ${values.join(' | ')} |\n`;
  });

  return markdown;
};

export {groupThreadsByTime, isFirstMessageOfDay, hasAssistantChangedSinceLastMessage, groupVisualsByPage, createGptFormattedMessageForUser, createGptFormattedMessageForAssistant, csvToMarkdown};

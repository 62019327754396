import {useTheme, ThemeProvider, Tooltip} from '@mui/material';
import Button from '@mui/material/Button';
import {bool, oneOfType, string, node, arrayOf} from 'prop-types';

import {createButtonTheme} from './buttonCommonTheme';

const AppButton = props => {
  const {hasTooltip, ...rest} = props;
  const {children, color, tooltip} = rest;
  const theme = useTheme();
  const buttonTheme = createButtonTheme(theme, color);

  if (hasTooltip) {
    return (
      <ThemeProvider theme={buttonTheme}>
        <Tooltip enterTouchDelay={100} title={tooltip}>
          <span>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Button {...rest}>{children}</Button>
          </span>
        </Tooltip>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={buttonTheme}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Button {...rest}>{children}</Button>
    </ThemeProvider>
  );
};

AppButton.propTypes = {
  hasTooltip: bool,
  tooltip: string,
  color: string,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: bool,
  children: oneOfType([arrayOf(node), node]).isRequired
};

AppButton.defaultProps = {
  color: 'primary',
  disabled: false,
  hasTooltip: false,
  tooltip: ''
};
export default AppButton;

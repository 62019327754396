import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import useTranscript from '../../../hooks/providers/useTranscript';

const CancelRecordingButton = () => {
  const {handleStopRecording: stopRecording} = useTranscript();
  const shouldSaveOnStop = false;

  return (
    <IconButton onClick={() => stopRecording(shouldSaveOnStop)}>
      <DeleteIcon sx={{fontSize: 28}} color="error" />
    </IconButton>
  );
};

export default CancelRecordingButton;

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const FileUploadProgress = ({totalNumberOfFiles, numberOfFileAlreadyUploaded}) => {
  const filesWord = totalNumberOfFiles <= 1 ? 'fichier' : 'fichiers';
  const progressPercentage = Math.round((numberOfFileAlreadyUploaded / totalNumberOfFiles) * 100);

  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <CircularProgress size={70} variant={numberOfFileAlreadyUploaded === 0 || numberOfFileAlreadyUploaded === totalNumberOfFiles ? 'indeterminate' : 'determinate'} value={progressPercentage} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '0 10px'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {numberOfFileAlreadyUploaded} / {totalNumberOfFiles} {filesWord}
        </Typography>
      </Box>
    </Box>
  );
};

FileUploadProgress.propTypes = {
  totalNumberOfFiles: PropTypes.number,
  numberOfFileAlreadyUploaded: PropTypes.number.isRequired
};

FileUploadProgress.defaultProps = {
  totalNumberOfFiles: 0
};

export default FileUploadProgress;

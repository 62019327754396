import {useState, useMemo, useEffect} from 'react';

import {Typography} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import muiStyled from '@mui/material/styles/styled';
import TextField from '@mui/material/TextField';
import jwtDecode from 'jwt-decode';
import {bool} from 'prop-types';
import styled from 'styled-components';

import useAuth from 'hooks/providers/useAuth';
import useProfile from 'hooks/providers/useProfile';
import useReport from 'hooks/providers/useReport';
import {getFileB64, isPhoneNumberValid, getHumanReadablePhoneNumber} from 'utils';

import {BREAKPOINTS, REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS, SNACKBAR_ACTIONS} from '../../const';
import useBreakpoint from '../../hooks/dom/useBreakpoint';
import useSnackbar from '../../hooks/providers/useSnackbar';
import DefaultAvatar from '../../img/default-avatar.png';
import Button from '../form/buttons/Button';
import Spinner from '../spinner/Spinner';
import Consents from './Consents';

const SaveButton = muiStyled(Button)(() => ({
  padding: '12px 5vw'
}));

const UserAvatar = styled.img`
  // cursor: pointer;  // TODO UNCOMMENT ONCE AVATAR FEATURE IS IMPLEMENTED
  width: 100px;
  height: 100px;
  margin: 16px 0;
  border-radius: 50px;
  background-color: transparent;
  box-shadow: 0px 0px 10px #00000029;
  opacity: 1;
`;

const ProfileTitle = muiStyled(Typography)(() => ({
  fontSize: 15,
  font: 'normal normal normal 16px/25px SoehneBreitKraftig'
}));

const ChangePhotoText = styled.p`
  cursor: pointer;
  font: normal normal bold 12px InstrumentSans;
  text-decoration: underline;
`;

const IssuerTag = styled.p`
  border-radius: 5px;
  border: 1px solid ${process.env.REACT_APP_SECONDARY_COLOR};
  color: ${process.env.REACT_APP_SECONDARY_COLOR};
  padding: 8px 12px;
  font: normal normal normal 12px InstrumentSans;
`;

const BackdropContainer = muiStyled(Backdrop)(({theme}) => ({
  color: '#fff',
  zIndex: theme.zIndex.drawer + 1
}));

const extractEmailFromToken = token => {
  const decodedToken = jwtDecode(token);
  return decodedToken.emails ? decodedToken.emails[0] : decodedToken.email; // IDK why but sometimes Azure B2C sends back an array of emails in an "emails" key, sometimes only one email in an "email" property
};

// eslint-disable-next-line complexity
const ProfileForm = ({isSubscribe}) => {
  const {profile, consents, updateProfile, saveConsent, fetchConsents, emailAlreadyTakenError, setEmailAlreadyTakenError} = useProfile();
  const auth = useAuth();
  const {showSnackbar} = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState(profile?.avatar || '');
  const [avatarB64, setAvatarB64] = useState(null);
  const [firstname, setFirstname] = useState(profile?.givenName && profile?.givenName !== 'unknown' ? profile?.givenName : '');
  const [lastname, setLastname] = useState(profile?.surname && profile?.surname !== 'unknown' ? profile?.surname : '');
  const [email, setEmail] = useState(extractEmailFromToken(auth?.user?.tokenAad));
  const [phone, setPhone] = useState(profile?.businessPhones?.length > 0 ? profile.businessPhones[0] : '');
  const [entreprise, setEntreprise] = useState(profile?.companyName || '');
  const [newConsents, setNewConsents] = useState([]);

  const [initialFormValues] = useState({
    firstname,
    lastname,
    email,
    phone,
    entreprise
  });
  const currentFormValues = {
    firstname,
    lastname,
    email,
    phone,
    entreprise
  };

  const pristine = JSON.stringify(initialFormValues) === JSON.stringify(currentFormValues) && !avatarB64;

  // This hooks aims to keep form update after a profile change
  useEffect(() => {
    setAvatar(profile?.avatar || '');
    setFirstname(profile?.givenName && profile?.givenName !== 'unknown' ? profile?.givenName : '');
    setLastname(profile?.surname && profile?.surname !== 'unknown' ? profile?.surname : '');
    setEmail(extractEmailFromToken(auth.user.tokenAad) || '');
    setPhone(profile?.businessPhones?.length > 0 ? profile.businessPhones[0] : '');
    setEntreprise(profile?.companyName || '');
  }, [profile]);

  const openNewAvatar = () => {
    document.querySelector('#image-input').click();
  };

  const changeAvatar = async newAvatar => {
    setAvatar(URL.createObjectURL(newAvatar));
    setAvatarB64((await getFileB64(newAvatar)).split('base64,')[1]);
  };

  const saveData = async data => {
    setIsLoading(true);
    if (isSubscribe) {
      showSnackbar(SNACKBAR_ACTIONS.SUBSCRIBE_LOADING, {
        severity: 'info',
        autoHide: false
      });
    }
    try {
      const res = await updateProfile(data, profile?.businessPhones?.length > 0 ? profile.businessPhones[0] : '');
      if (res.success) {
        await Promise.all(newConsents.map(consentType => saveConsent(consentType)));
        await fetchConsents();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCheckConsent = (consentType, value) => {
    if (value) {
      setNewConsents([...new Set([...newConsents, consentType])]); // Set to have unique values
    } else {
      setNewConsents(newConsents.filter(c => c !== consentType));
    }
  };

  const hasFirstnameError = !REGULAR_EXPRESSIONS.label.test(firstname);
  const hasLastnameError = !REGULAR_EXPRESSIONS.label.test(lastname);
  const hasCompanyNameError = !REGULAR_EXPRESSIONS.label.test(entreprise);
  const isPhoneValid = useMemo(() => isPhoneNumberValid(phone), [phone]);
  const hasSomeIdentityError = hasFirstnameError || hasLastnameError || hasCompanyNameError;

  const hasCheckedAllConsents = newConsents?.length >= 2 || (newConsents?.length || 0) + (consents?.filter(c => c.consent_value).length || 0) >= 2;
  const canSubscribe = hasCheckedAllConsents && firstname && lastname && email && phone && entreprise && isPhoneValid && !hasSomeIdentityError && !emailAlreadyTakenError;
  const canUpdate = firstname && lastname && email && phone && entreprise && isPhoneValid && !hasSomeIdentityError;

  const emailInputDisabled = !!extractEmailFromToken(auth?.user?.tokenAad);
  const currentBreakpoint = useBreakpoint();
  const isExtraSmallScreen = currentBreakpoint === BREAKPOINTS.xs;

  return (
    <>
      {isLoading && (
        <BackdropContainer open>
          <Spinner withoutText isLoading />
        </BackdropContainer>
      )}
      <Grid container spacing={2} flexDirection="column" justifyContent="center" alignItems="center">
        <Grid pt={2} container justifyContent="space-between" alignItems="center">
          <Grid item sm={3} />
          <Grid sx={isExtraSmallScreen ? {mt: 1, textAlign: 'center'} : {}} item xs={12} sm="auto" order={isExtraSmallScreen ? 2 : 1}>
            <ProfileTitle>Photo de profil</ProfileTitle>
          </Grid>
          <Grid item xs={12} sm={3} order={isExtraSmallScreen ? 1 : 2}>
            <Grid container justifyContent="flex-end" flex={1}>
              {profile?.identities && profile.identities.find(({issuer}) => issuer === 'google.com') && <IssuerTag>Connecté via Google</IssuerTag>}
              {profile?.identities && profile.identities.find(({issuer}) => issuer === 'facebook.com') && <IssuerTag>Connecté via Facebook</IssuerTag>}
            </Grid>
          </Grid>
        </Grid>
        <UserAvatar
          // onClick={openNewAvatar}
          src={DefaultAvatar}
        />
        {/* TODO CHANGE DEFAULT AVATAR AND UNCOMMENT ONCLICK HANDLER + TEXT BELOW ONCE ADRIAN DOES BACKEND PART */}
        {/* <ChangePhotoText onClick={openNewAvatar}>Changer la photo</ChangePhotoText> */}
        <input
          hidden
          onChange={ev => {
            if (ev?.target?.files?.length > 0) {
              changeAvatar(ev.target.files[0]);
            }
          }}
          type="file"
          id="image-input"
          name="image-input"
          accept="image/*"
        />
      </Grid>
      <Grid mt={2} container spacing={2} justifyContent="center" alignItems="stretch">
        <Grid item md={6} xs={12}>
          <TextField
            size="small"
            required={isSubscribe}
            value={firstname}
            onChange={event => {
              setFirstname(event.target.value);
            }}
            label="Prénom"
            placeholder="Votre prénom ..."
            disabled={isLoading}
            color="primary"
            fullWidth
            error={hasFirstnameError}
            helperText={hasFirstnameError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.label : ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            size="small"
            required={isSubscribe}
            value={lastname}
            onChange={event => {
              setLastname(event.target.value);
            }}
            label="Nom"
            placeholder="Votre nom ..."
            disabled={isLoading}
            color="primary"
            fullWidth
            error={hasLastnameError}
            helperText={hasLastnameError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.label : ''}
          />
        </Grid>
      </Grid>
      <Grid mt={1} container spacing={2} justifyContent="center" alignItems="stretch">
        <Grid item md={6} xs={12}>
          <TextField
            size="small"
            disabled={emailInputDisabled}
            required={isSubscribe}
            value={email}
            onChange={event => {
              setEmail(event.target.value);
              if (emailAlreadyTakenError) {
                setEmailAlreadyTakenError(false);
              }
            }}
            label="Adresse mail"
            placeholder="foo.bar@example.com"
            color="primary"
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            inputProps={{maxLength: 14, minLength: 14}}
            size="small"
            value={getHumanReadablePhoneNumber(phone)}
            required={isSubscribe}
            error={!isPhoneValid && phone !== ''}
            helperText={!isPhoneValid && phone !== '' && 'Numéro invalide'}
            onChange={event => {
              const phoneWithoutSpaces = event.target.value.replaceAll(' ', '');
              setPhone(phoneWithoutSpaces);
            }}
            label="Numéro de téléphone"
            placeholder="00 00 00 00 00"
            disabled={isLoading}
            color="primary"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid mt={2} spacing={2} container justifyContent="center" alignItems="stretch">
        <Grid item xs={12}>
          <TextField
            size="small"
            required={isSubscribe}
            value={entreprise}
            onChange={event => {
              setEntreprise(event.target.value);
            }}
            label="Entreprise"
            placeholder="Nom de votre entreprise ..."
            disabled={isLoading}
            color="primary"
            fullWidth
            error={hasCompanyNameError}
            helperText={hasCompanyNameError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.label : ''}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Consents onCheckConsent={onCheckConsent} consents={consents} />
      </Grid>
      <Grid mt={2} spacing={2} container justifyContent="center">
        <SaveButton
          disabled={(isSubscribe && !canSubscribe) || (!isSubscribe && !canUpdate) || isLoading || (pristine && !isSubscribe)}
          onClick={() => saveData({firstname, lastname, phone, entreprise, email, avatar: avatarB64})}
          size="large"
          variant="contained"
          color="primary"
        >
          {isSubscribe ? 'Finaliser mon inscription' : 'Mettre à jour mon profil'}
        </SaveButton>
      </Grid>
    </>
  );
};

ProfileForm.defaultProps = {
  isSubscribe: false
};

ProfileForm.propTypes = {
  isSubscribe: bool
};

export default ProfileForm;

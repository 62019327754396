import {useEffect, useState} from 'react';

import {Box} from '@mui/material';
import {styled} from '@mui/system';

const IndicatorContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: theme => theme.spacing(2) // Adjust as needed
});

const Dot = styled(Box)(({theme}) => ({
  marginLeft: 2,
  width: theme.spacing(1), // Adjust the size of the dots
  height: theme.spacing(1),
  borderRadius: '50%',
  backgroundColor: 'white', // Set dot color to white
  transition: 'transform 0.3s ease-in-out' // Smooth transition
}));

const RoundedBox = styled(Box)(({theme}) => ({
  display: 'flex', // Display dots horizontally
  backgroundColor: theme.palette.primary.main, // Set background color to primary
  padding: theme.spacing(1.2, 1), // Adjust padding as needed
  borderRadius: theme.spacing(2) // Set border radius to create rounded box
}));

const TypingIndicator = () => {
  const [activeDotIndex, setActiveDotIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDotIndex(prevIndex => (prevIndex + 1) % 3);
    }, 300); // Adjust the speed of animation

    return () => clearInterval(interval);
  }, []);

  return (
    <IndicatorContainer data-testid="typing-indicator">
      <RoundedBox>
        {[0, 1, 2].map(index => (
          <Dot
            key={index}
            style={{
              transform: `translateY(${activeDotIndex === index ? '-5px' : '0'})`, // Adjust the jump height
              transitionDelay: `${Math.abs(index - activeDotIndex) * 0.15}s` // Introduce delay based on difference
            }}
          />
        ))}
      </RoundedBox>
    </IndicatorContainer>
  );
};

export default TypingIndicator;

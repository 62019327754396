import {useTheme, Grid, Typography} from '@mui/material';
import {bool, func, string} from 'prop-types';
import styled from 'styled-components';

const ConnectorImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const ConnectorContainer = styled.div`
  height: 100%;
  max-height: ${props => (props.selected ? '28px' : '30px')};
  padding: 12px;
  border: ${props => (props.selected ? `3px solid ${process.env.REACT_APP_SECONDARY_COLOR}` : `1px solid ${process.env.REACT_APP_MAIN_COLOR} `)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
`;

const ConnectorRibbon = styled.div`
  position: absolute;
  background: ${props => props.color};
  top: ${props => (props.selected ? '-8px' : '-6px')};
  color: white;
  padding: 2px 6px;
  margin-bottom: 4px;
  font-size: 11px;
  border-radius: 5px;
  min-width: 55px;
`;

const ConnectorBox = ({name, selected, setConnector, logo, text, hasBetaRibbon = false, ribbonColor, ribbonText}) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} sm={4}>
      <ConnectorContainer onClick={setConnector} selected={selected}>
        {hasBetaRibbon && (
          <ConnectorRibbon color={theme.palette[ribbonColor].main} selected={selected}>
            {ribbonText}
          </ConnectorRibbon>
        )}
        {logo ? <ConnectorImage src={logo} alt={`Logo ${name}`} /> : <Typography sx={{fontSize: 13}}>{text}</Typography>}
      </ConnectorContainer>
    </Grid>
  );
};
ConnectorBox.defaultProps = {
  hasBetaRibbon: false,
  logo: '',
  text: '',
  ribbonColor: '',
  ribbonText: ''
};

ConnectorBox.propTypes = {
  selected: bool.isRequired,
  setConnector: func.isRequired,
  logo: string,
  text: string,
  name: string.isRequired,
  hasBetaRibbon: bool,
  ribbonColor: string,
  ribbonText: string
};

export default ConnectorBox;

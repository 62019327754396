import AutoRenewIcon from '@mui/icons-material/Autorenew';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIconOutlined from '@mui/icons-material/SaveOutlined';
import {Tooltip} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {bool, func} from 'prop-types';
import styled from 'styled-components';

import useReport from '../../../hooks/providers/useReport';
import RotatingIcon from '../../spinner/RotatingIcon';

const Container = styled.div`
  cursor: ${props => (props.isSaving || props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  margin-top: 2px;
`;

const SaveIcon = muiStyled(SaveIconOutlined)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: 24
}));

const commonIconStyle = {
  fontSize: 10,
  background: 'white',
  zIndex: 9999,
  position: 'absolute',
  top: 14,
  left: 14,
  borderRadius: '50%'
};
const IsCurrentlySavingIcon = muiStyled(AutoRenewIcon)(({theme}) => ({
  ...commonIconStyle,
  color: theme.palette.primary.main
}));

const UnsavedContentIcon = muiStyled(CloseIcon)(({theme}) => ({
  ...commonIconStyle,
  color: theme.palette.error.main
}));

const UpToDateContentIcon = muiStyled(CheckIcon)(({theme}) => ({
  ...commonIconStyle,
  color: theme.palette.success.main
}));

// eslint-disable-next-line complexity
const AutoSaveIcon = ({isSaving, hasUnsavedData, saveData, mustWaitBeforeNextRefresh, requestManualSave}) => {
  const {autoSaveDisabled} = useReport();
  const saveButtonDisabled = !autoSaveDisabled || (autoSaveDisabled && !hasUnsavedData);

  const handleClick = () => {
    if (saveButtonDisabled) {
      return null;
    }
    if (mustWaitBeforeNextRefresh) {
      return requestManualSave();
    }
    return saveData();
  };

  const renderContent = () => {
    return (
      <Container isSaving={isSaving} disabled={saveButtonDisabled} onClick={handleClick}>
        <SaveIcon sx={{color: saveButtonDisabled && 'grey'}} />
        {!isSaving && !hasUnsavedData && <UpToDateContentIcon />}
        {isSaving && <RotatingIcon isRotating icon={IsCurrentlySavingIcon} />}
        {hasUnsavedData && !isSaving && <UnsavedContentIcon />}
      </Container>
    );
  };

  if (autoSaveDisabled) {
    return (
      <Tooltip sx={{height: 24}} title="Sauvegarder maintenant">
        {renderContent()}
      </Tooltip>
    );
  }

  return renderContent();
};

AutoSaveIcon.propTypes = {
  isSaving: bool.isRequired,
  hasUnsavedData: bool.isRequired,
  mustWaitBeforeNextRefresh: bool.isRequired,
  saveData: func.isRequired,
  requestManualSave: func.isRequired
};

export default AutoSaveIcon;

import {useEffect, useState} from 'react';

import {POWER_BI_PAGES} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button';
import UpdateReportSummaryModal from '../analysis/UpdateReportSummaryModal';

const UpdateSummaryButton = () => {
  const [reportSummaryModalOpen, setReportSummaryModalOpen] = useState(false);

  const {selectedProject, reportSelectedPage} = useReport();
  const {setShouldShareAnonymizedData, summaryTheme} = useReportSummary();

  const pagesHavingUpdateSummaryButton = [POWER_BI_PAGES.reportCharges.id, POWER_BI_PAGES.reportHome.id];
  const shouldDisplayUpdateSummaryButton = pagesHavingUpdateSummaryButton.includes(reportSelectedPage.name);

  useEffect(() => {
    setShouldShareAnonymizedData(true);
  }, [summaryTheme]);

  if (shouldDisplayUpdateSummaryButton && selectedProject?.is_owner) {
    return (
      <>
        <Button variant="contained" color="secondary" onClick={() => setReportSummaryModalOpen(true)}>
          Modifier l'analyse
        </Button>
        <UpdateReportSummaryModal isOpen={reportSummaryModalOpen} closeModal={() => setReportSummaryModalOpen(false)} />
      </>
    );
  }

  return null;
};

export default UpdateSummaryButton;

import {useState, useEffect} from 'react';

import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {Fab} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {POWER_BI_PAGES, REPORT_PAGES_WITHOUT_AUDIO} from '../../../../const';
import useAudioPlayer from '../../../../hooks/misc/useAudioPlayer';
import useReport from '../../../../hooks/providers/useReport';
import useReportSummary from '../../../../hooks/providers/useReportSummary';

const ReadAudioButton = () => {
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);

  const {reportSelectedPage, selectedProject, selectedReport} = useReport();
  const {summaryTheme, summaryHasBeenUpdated, setSummaryTheme, getSummaryTheme, reportAudios, getReportThemeAudio} = useReportSummary();

  const shouldDisplay = reportSelectedPage.name !== POWER_BI_PAGES.defiPreviReportTreasury.id;
  const audio = (reportAudios || []).find(a => a.theme === summaryTheme && a.siren === selectedProject?.siren);
  const {isPlaying, sound, setIsPlaying, togglePlayPause} = useAudioPlayer(audio);

  const currentPageHasAudio = !REPORT_PAGES_WITHOUT_AUDIO.includes(reportSelectedPage.name);
  const isCurrentPageWithoutAudio = REPORT_PAGES_WITHOUT_AUDIO.includes(reportSelectedPage.name);
  const readAudioButtonDisabled = isLoadingAudio || (isCurrentPageWithoutAudio && !sound) || !sound;

  useEffect(() => {
    if (currentPageHasAudio && shouldDisplay) {
      (async () => {
        const newPageSummaryTheme = await getSummaryTheme(selectedReport);
        if (newPageSummaryTheme) {
          setSummaryTheme(newPageSummaryTheme);
        }
      })();
    }
  }, [reportSelectedPage, summaryTheme]);

  const loadAudio = async () => {
    setIsLoadingAudio(true);
    setIsPlaying(false);
    const {siren} = selectedProject;
    await getReportThemeAudio({theme: summaryTheme, siren});
    setIsLoadingAudio(false);
  };

  useEffect(() => {
    setIsPlaying(false);
    if (summaryTheme && !audio && selectedProject) {
      loadAudio();
    }
  }, [summaryTheme]);

  useEffect(() => {
    setIsPlaying(false);
    if (currentPageHasAudio && summaryHasBeenUpdated && selectedProject) {
      loadAudio();
    }
  }, [summaryHasBeenUpdated]);

  if (shouldDisplay) {
    return (
      <Fab
        aria-label="audio button"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 80
        }}
        disabled={readAudioButtonDisabled}
        variant="contained"
        color="primary"
        onClick={togglePlayPause}
      >
        {isLoadingAudio && <CircularProgress data-testid="circularProgress" sx={{position: 'absolute'}} size={55} />}
        {isPlaying ? <GraphicEqIcon color="white" className="wave-animation" /> : <PlayCircleIcon color="white" />}
      </Fab>
    );
  }

  return null;
};

export default ReadAudioButton;

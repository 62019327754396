import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const IosPwaPrompt = () => {
  return (
    <Box px={1} pb={2}>
      <Grid container alignItems="center">
        <Grid item>
          <ShareOutlinedIcon color="primary" />
        </Grid>
        <Grid item ml={2}>
          <Typography fontSize={16} fontFamily="InstrumentSansBold">
            1) Appuyez sur le bouton partager
          </Typography>
        </Grid>
      </Grid>
      <Grid mt={1} container alignItems="center">
        <Grid item>
          <AddBoxOutlinedIcon color="primary" />
        </Grid>
        <Grid item ml={2}>
          <Typography fontSize={16} fontFamily="InstrumentSansBold">
            2) Appuyez sur "Ajouter à l'écran d'accueil"
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IosPwaPrompt;

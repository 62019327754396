import {createTheme} from '@mui/material';

const createButtonTheme = (parentTheme, color) => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette) and use it
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '2rem',
            fontFamily: 'SoehneBreitKraftig',
            '&.Mui-disabled': {
              background: color !== 'inherit' && parentTheme.palette[color].main,
              color: color !== 'white' ? '#fff' : 'inherit',
              opacity: 0.6,
              cursor: 'not-allowed',
              pointerEvents: 'all'
            }
          },
          outlined: {
            borderWidth: 2,
            '&:hover': {
              borderWidth: 2
            }
          }
        }
      }
    }
  });
};

export {createButtonTheme};

/* eslint-disable react/jsx-props-no-spreading */
import {useEffect, useRef, useState} from 'react';

import {Button, createTheme, ThemeProvider, Tooltip, useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';
import muiStyled from '@mui/material/styles/styled';
import PropTypes, {bool, string} from 'prop-types';

const createSharedButtonTheme = (parentTheme, color, isLeftButton = false) => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette) and use it
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderTopLeftRadius: isLeftButton ? '2rem' : 0,
            borderBottomLeftRadius: isLeftButton ? '2rem' : 0,
            borderTopRightRadius: !isLeftButton ? '2rem' : '0 !important',
            borderBottomRightRadius: !isLeftButton ? '2rem' : '0 !important',
            fontFamily: 'SoehneBreitKraftig',
            paddingRight: isLeftButton && '32px !important',
            paddingLeft: !isLeftButton && '32px !important',
            boxShadow: '2px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            '&.Mui-disabled': {
              background: color !== 'inherit' && parentTheme.palette[color].main,
              color: color !== 'white' ? '#fff' : 'inherit',
              opacity: 0.6,
              cursor: 'not-allowed',
              pointerEvents: 'all',
              boxShadow: '-2px 3px 1px -2px rgba(0,0,0,0.4), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
            }
          }
        }
      }
    }
  });
};

const getSeparatorPixelsFromLeft = buttonWidth => {
  return buttonWidth - 25;
};

const getSeparatorPixelsFromTop = size => (size === 'large' ? 4 : 3);

const getSeparatorWidthOrHeight = size => {
  switch (size) {
    case 'small':
      return 25;
    case 'large':
      return 34;
    default:
      return 31;
  }
};

const getSeparatorFontSize = size => {
  switch (size) {
    case 'small':
      return 10;
    case 'large':
      return 12;
    default:
      return 11;
  }
};

const ButtonSeparator = muiStyled(Grid)(({theme, ...props}) => ({
  borderRadius: '50%',
  background: 'white',
  position: 'absolute',
  top: getSeparatorPixelsFromTop(props.size),
  padding: 1,
  margin: '0 10px',
  left: getSeparatorPixelsFromLeft(props.width),
  zIndex: 10,
  border: `2px solid ${props.color}`,
  color: props.color,
  fontWeight: 'bold',
  fontSize: getSeparatorFontSize(props.size),
  width: getSeparatorWidthOrHeight(props.size),
  height: getSeparatorWidthOrHeight(props.size),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const SharedButton = ({leftButtonProps, rightButtonProps, leftButtonText, rightButtonText, size}) => {
  const leftButtonRef = useRef();
  const rightButtonRef = useRef();
  const theme = useTheme();
  const leftButtonTheme = createSharedButtonTheme(theme, leftButtonProps.color, true);
  const rightButtonTheme = createSharedButtonTheme(theme, rightButtonProps.color);
  const [dimensions, setDimensions] = useState({width: 0, height: 0});

  const {hasTooltip, tooltipText, ...rightDomValidButtonProps} = rightButtonProps;
  useEffect(() => {
    const {offsetWidth: rightButtonWidth} = rightButtonRef.current;
    const {offsetWidth: leftButtonWidth} = leftButtonRef.current;
    const widerButtonWidth = leftButtonWidth > rightButtonWidth ? leftButtonWidth : rightButtonWidth;
    const widerButtonHeight = leftButtonWidth > rightButtonWidth ? leftButtonRef.current.offsetHeight : rightButtonRef.current.offsetHeight;

    const getDimensions = () => ({
      width: widerButtonWidth,
      height: widerButtonHeight
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (leftButtonRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [leftButtonRef.current, rightButtonRef.current]);

  return (
    <Grid container flexDirection="row" sx={{position: 'relative'}}>
      <Grid item>
        <ThemeProvider theme={leftButtonTheme}>
          <Button size={size} {...leftButtonProps} ref={leftButtonRef} sx={{minWidth: dimensions.width}}>
            {leftButtonText}
          </Button>
        </ThemeProvider>
      </Grid>

      <ButtonSeparator color={theme.palette[leftButtonProps.color].main} width={dimensions.width} size={size}>
        OU
      </ButtonSeparator>

      <Grid item>
        <ThemeProvider theme={rightButtonTheme}>
          {hasTooltip ? (
            <Tooltip title={tooltipText}>
              <span>
                <Button size={size} {...rightDomValidButtonProps} ref={rightButtonRef} sx={{minWidth: dimensions.width}}>
                  {rightButtonText}
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button size={size} {...rightDomValidButtonProps} ref={rightButtonRef} sx={{minWidth: dimensions.width}}>
              {rightButtonText}
            </Button>
          )}
        </ThemeProvider>
      </Grid>
    </Grid>
  );
};

SharedButton.propTypes = {
  leftButtonProps: PropTypes.shape({color: string.isRequired, hasTooltip: bool, tooltipText: string}),
  rightButtonProps: PropTypes.shape({color: string.isRequired, hasTooltip: bool, tooltipText: string}),
  leftButtonText: string.isRequired,
  rightButtonText: string.isRequired,
  size: string
};

SharedButton.defaultProps = {
  leftButtonProps: {},
  rightButtonProps: {},
  size: 'medium'
};

export default SharedButton;

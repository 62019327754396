/* eslint-disable class-methods-use-this */
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import IconButton from '@mui/material/IconButton';

class DeleteCellTemplate {
  getCompatibleCell(uncertainCell) {
    return {...uncertainCell, text: ''};
  }

  isFocusable() {
    return false;
  }

  render(cell) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <IconButton onClick={cell.onDelete} size="small">
          <DeleteIcon color="error" />
        </IconButton>
      </div>
    );
  }
}

export default DeleteCellTemplate;

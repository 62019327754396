import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';

const TabSeparator = muiStyled(Box)(() => ({
  borderRightWidth: 1,
  borderStyle: 'solid',
  borderColor: 'lightgrey',
  height: 16,
  display: 'block'
}));

export default TabSeparator;

import {useEffect, useState} from 'react';

import {POWER_BI_PAGES, POWER_BI_REPORT_BOOKMARKS} from '../../../const';
import useProjectCrudModal from '../../../hooks/dom/useProjectCrudModal';
import useProjectsCustomMappings from '../../../hooks/providers/useProjectsCustomMappings';
import useReport from '../../../hooks/providers/useReport';
import Button from '../../form/buttons/Button';

const CustomizeMappingButton = () => {
  const [customMappingBookmarkApplied, setCustomMappingBookmarkApplied] = useState(false);
  const [customMappingBookmarkAppliedBeforeNavigating, setCustomMappingBookmarkAppliedBeforeNavigating] = useState(false);

  const {selectedProject: project} = useReport();
  const {reportSelectedPage, lastBookmarkExceptFilters, currentBookmark, setCurrentBookmark} = useReport();
  const {setCustomizeMappingModalOpen} = useProjectsCustomMappings();
  const {openModal} = useProjectCrudModal({setModalOpen: setCustomizeMappingModalOpen, project});

  const buttonText = "Modifier l'affectation des comptes";

  const isSynthesisPage = reportSelectedPage.name === POWER_BI_PAGES.reportSynthesis.id;

  useEffect(() => {
    const isCustomMappingBookmark = lastBookmarkExceptFilters === POWER_BI_REPORT_BOOKMARKS.customMapping.name;
    if (isCustomMappingBookmark) {
      setCustomMappingBookmarkAppliedBeforeNavigating(true);
      setCustomMappingBookmarkApplied(true);
    } else {
      if (lastBookmarkExceptFilters === POWER_BI_REPORT_BOOKMARKS.defaultMapping.name) {
        setCustomMappingBookmarkApplied(false);
        return setCustomMappingBookmarkAppliedBeforeNavigating(false);
      }

      if (lastBookmarkExceptFilters === POWER_BI_REPORT_BOOKMARKS.soldes.name) {
        return;
      }
      setCustomMappingBookmarkApplied(false);
    }
  }, [lastBookmarkExceptFilters]);

  useEffect(() => {
    if (currentBookmark === POWER_BI_REPORT_BOOKMARKS.soldes.name && customMappingBookmarkAppliedBeforeNavigating) {
      setCustomMappingBookmarkApplied(true);
    }
  }, [currentBookmark]);

  useEffect(() => {
    return () => {
      setCurrentBookmark(null);
    };
  }, []);

  if (isSynthesisPage && customMappingBookmarkApplied) {
    return (
      <Button variant="contained" color="secondary" onClick={openModal}>
        {buttonText}
      </Button>
    );
  }

  return null;
};

export default CustomizeMappingButton;

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const CircularProgressWithLabel = ({progress, ...props}) => {
  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CircularProgress variant="determinate" {...props} color="warning" size={24} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography lineHeight={1} variant="caption" component="div" color="text.secondary" fontSize={9}>
          {progress}
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired
};

const normalise = value => (value * 100) / 15;

const AutoSaveTimerBeforeNextSave = ({progress}) => {
  return <CircularProgressWithLabel value={normalise(progress)} progress={progress} />;
};

AutoSaveTimerBeforeNextSave.propTypes = {
  progress: PropTypes.number.isRequired
};

export default AutoSaveTimerBeforeNextSave;

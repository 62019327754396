import {Divider, List, ListItem, Typography} from '@mui/material';
import MuiMarkdown, {getOverrides} from 'mui-markdown';
import {Highlight, themes} from 'prism-react-renderer';
import {string} from 'prop-types';

import FirstLetterCapitalizedText from './FirstLetterCapitalizedText';

const commonTitleProps = {
  fontFamily: 'SoehneBreitKraftig',
  mt: 1.5,
  mb: 0.5,
  fontSize: 14
};

const commonSimpleTextProps = {
  fontSize: 14,
  fontFamily: 'InstrumentSans',
  lineHeight: 1.5
};

const commonListProps = {
  ...commonSimpleTextProps,
  dense: true,
  mt: 2,
  mb: 1
};

// eslint-disable-next-line react/jsx-props-no-spreading
const createDynamicComponent = ComponentType => props => <FirstLetterCapitalizedText component={ComponentType} {...props} />;

const FirstLetterCapitalizedTypography = createDynamicComponent(Typography);
const FirstLetterCapitalizedListItem = createDynamicComponent(ListItem);

// eslint-disable-next-line react/prop-types
const CustomOrderedList = ({children, ...props}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <List component="ol" {...props}>
    {children}
  </List>
);

const MarkdownText = ({message}) => {
  const config = {Highlight, themes, theme: themes.shadesOfPurple, hideLineNumbers: true};
  return (
    <MuiMarkdown
      blockquoteBorderColor="gold"
      overrides={{
        ...getOverrides(config), // This will keep the other default overrides.
        p: {
          props: {
            style: {
              ...commonSimpleTextProps
            },
            variant: 'p'
          }
        },
        span: {
          props: {
            style: {
              ...commonSimpleTextProps
            },
            variant: 'span'
          }
        },
        h1: {
          component: FirstLetterCapitalizedTypography,
          props: {
            ...commonTitleProps,
            variant: 'h1',
            fontSize: 18
          }
        },
        h2: {
          component: FirstLetterCapitalizedTypography,
          props: {
            ...commonTitleProps,
            variant: 'h2',
            fontSize: 16
          }
        },
        h3: {
          component: FirstLetterCapitalizedTypography,
          props: {
            ...commonTitleProps,
            variant: 'h3',
            fontSize: 15
          }
        },
        h4: {
          component: FirstLetterCapitalizedTypography,
          props: {
            ...commonTitleProps,
            variant: 'h4'
          }
        },
        h5: {
          component: FirstLetterCapitalizedTypography,
          props: {
            ...commonTitleProps,
            variant: 'h5'
          }
        },
        h6: {
          component: FirstLetterCapitalizedTypography,
          props: {
            ...commonTitleProps,
            variant: 'h6'
          }
        },
        ul: {
          component: List,
          props: {
            ...commonListProps,
            style: {
              listStyleType: 'disc'
            }
          }
        },
        ol: {
          component: CustomOrderedList,
          props: {
            ...commonListProps,
            style: {
              listStyleType: 'auto'
            }
          }
        },
        li: {
          component: FirstLetterCapitalizedListItem,
          props: {
            style: {
              ...commonSimpleTextProps,
              display: 'list-item',
              paddingRight: 16,
              paddingLeft: 0,
              margin: '0 0 0 20px'
            }
          }
        },
        hr: {
          component: Divider,
          props: {
            style: {
              margin: '10px 0'
            }
          }
        },
        code: {
          component: 'code',
          props: {
            style: {
              lineHeight: '1.3',
              fontFamily: 'monospace',
              backgroundColor: 'rgb(45, 42, 85)',
              color: 'rgb(250, 208, 0)',
              padding: 2,
              borderRadius: 5,
              margin: '0 3px',
              fontSize: 11
            }
          }
        }
      }}
    >
      {message}
    </MuiMarkdown>
  );
};

MarkdownText.propTypes = {
  message: string.isRequired
};

export default MarkdownText;

import {useEffect} from 'react';

import {InteractionType} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import jwtDecode from 'jwt-decode';
import {oneOfType, node, func, number} from 'prop-types';
import {Navigate, useLocation} from 'react-router-dom';

import {APP_ROUTES} from '../const';
import useAuth from '../hooks/providers/useAuth';
import useLoading from '../hooks/providers/useLoading';
import {isTokenExpired} from '../utils';
import RequireConsentsAndHelpButtons from './RequireConsentsAndHelpButtons';

const RequireAuth = ({numberOfApiCallsToMake, children}) => {
  const {inProgress} = useMsal();
  const auth = useAuth();
  const location = useLocation();
  const {setTotalNumberOfApiCalls, setGlobalLoading} = useLoading();

  useEffect(() => {
    if (inProgress === InteractionType.None) {
      setGlobalLoading(true);
      setTotalNumberOfApiCalls(numberOfApiCallsToMake);
    }

    if (auth?.user?.tokenAad) {
      const decodedToken = jwtDecode(auth.user.tokenAad);
      auth.setIsNewUser(decodedToken.newUser || false);
      const tokenExpired = isTokenExpired(decodedToken);
      if (tokenExpired) {
        auth.setIsTokenInvalid(true);
      }
    }
  }, []);

  useEffect(() => {
    if (auth.loggedIntoBadPartnerErrorMessage) {
      setGlobalLoading(false);
      // This will be automatically handled by React Router and display root ErrorComponent
      throw new Response('Logged into bad partner', {status: 401});
    }
  }, [auth.loggedIntoBadPartnerErrorMessage]);

  if (!auth?.user && inProgress === InteractionType.None) {
    return <Navigate to={APP_ROUTES.login} state={{from: location}} replace />;
  }

  return <RequireConsentsAndHelpButtons>{children}</RequireConsentsAndHelpButtons>;
};

RequireAuth.propTypes = {
  numberOfApiCallsToMake: number.isRequired,
  children: oneOfType([node, func]).isRequired
};

export default RequireAuth;

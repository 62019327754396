import {Grid} from '@mui/material';

import {assistantShape} from '../../proptypes-shapes';
import AssistantWelcomeMessage from './AssistantWelcomeMessage';
import ConversationEventSubtitle from './ConversationEventSubtitle';

const AssistantSwitchedSentence = ({assistant}) => {
  if (!assistant) {
    return null;
  }

  return (
    <Grid container direction="column" alignItems="center" mt={2} mb={1} spacing={2}>
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <ConversationEventSubtitle text={`Votre assistant est maintenant ${assistant.name}`} />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <AssistantWelcomeMessage assistant={assistant} />
        </Grid>
      </Grid>
    </Grid>
  );
};

AssistantSwitchedSentence.propTypes = {
  assistant: assistantShape.isRequired
};

export default AssistantSwitchedSentence;

import {useState} from 'react';

import {createTheme, useTheme, ThemeProvider, Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@mui/material';
import {bool, func, shape, string} from 'prop-types';
import styled from 'styled-components';

import {APP_ROUTES} from '../../const';
import usePayment from '../../hooks/providers/usePayment';
import {getFrenchFormattedDate} from '../../utils';
import Button from '../form/buttons/Button';
import Link from '../link/Link';
import Spinner from '../spinner/Spinner';

const createDialogTheme = parentTheme => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette)
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            padding: 1
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '24px'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            margin: '12px auto'
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 800,
            textAlign: 'center',
            fontSize: 18
          }
        }
      }
    }
  });
};

const NewProductName = styled.span`
  font-weight: bold;
  line-height: 3;
  text-decoration: underline;
`;

const List = styled.ul`
  padding: 8px 0;
  list-style-type: disc;

  li {
    margin-left: 16px;
  }
`;

const UpdateSubscriptionModal = ({updateSubscription, product, onClose, isOpen}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const parentTheme = useTheme();
  const dialogTheme = createDialogTheme(parentTheme);
  const {products, updateSubscriptionErrorMessage, setMaxNumberOfProjects, getUserSubscriptions, maxNumberOfProjects, productSubscribed} = usePayment();

  const formattedDate = productSubscribed ? getFrenchFormattedDate(productSubscribed?.current_period_end, 'long') : '';

  const getIsUpgradeOrDowngrade = () => {
    if (!product) {
      return false;
    }
    const currentSubscriptionProduct = products.find(p => p.id === productSubscribed.product_id);
    const newSubscriptionHasMoreProjects = maxNumberOfProjects <= parseInt(product?.numberOfProjects, 10);
    const productsHaveSameInterval = currentSubscriptionProduct.interval === product.interval;
    const newProductHasBiggerInterval = currentSubscriptionProduct.interval === 'month' && product.interval === 'year';

    if ((productsHaveSameInterval || newProductHasBiggerInterval) && newSubscriptionHasMoreProjects) {
      return true;
    }
    return false;
  };
  const isUpgradingSubscription = getIsUpgradeOrDowngrade();

  const resetState = () => {
    setIsLoading(false);
    setIsUpdated(false);
    onClose();
  };

  const onConfirm = async () => {
    setIsLoading(true);
    setIsUpdated(false);
    try {
      const res = await updateSubscription();
      if (res.status === 200) {
        await getUserSubscriptions();
        setIsUpdated(true);
      }
      if (res.data === 'upgrade') {
        setMaxNumberOfProjects(product.numberOfProjects);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={dialogTheme}>
      <Dialog maxWidth="sm" onClose={resetState} open={isOpen} fullWidth>
        {isUpdated && !updateSubscriptionErrorMessage ? (
          <>
            <DialogTitle>Abonnement modifié</DialogTitle>
            <DialogContent dividers>
              <p>Votre abonnement a bien été modifié!</p>
            </DialogContent>
            <DialogActions>
              <Link to={APP_ROUTES.profile}>
                <Button variant="contained" size="large" color="secondary">
                  Retour à mon compte
                </Button>
              </Link>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Modifier mon abonnement</DialogTitle>
            <DialogContent dividers sx={{pt: 5}}>
              {isLoading ? (
                <Spinner withoutText isLoading />
              ) : (
                <>
                  <p>
                    Etes vous sur de vouloir modifier votre abonnement actuel vers: <br />
                    <NewProductName>{product?.name} </NewProductName>
                  </p>
                  {isUpgradingSubscription && (
                    <>
                      <p>Attention, cliquer sur "Modifier mon abonnement" débitera votre moyen de paiement. Une nouvelle facture sera générée avec :</p>
                      <List>
                        <li>Le paiement de votre nouvel abonnement</li>
                        <li>Le remboursement au prorata sur le temps inutilisé concernant votre abonnement actuel.</li>
                      </List>
                    </>
                  )}

                  {!isUpgradingSubscription && <p>Votre abonnement actuel sera actif jusqu'au {formattedDate}. Votre nouvel abonnement le remplacera après cette date.</p>}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button disabled={isLoading} variant="outlined" size="large" color="primary" autoFocus onClick={resetState}>
                Annuler
              </Button>
              <Button disabled={isLoading} variant="contained" size="large" color="secondary" onClick={onConfirm}>
                Modifier mon abonnement
              </Button>
            </DialogActions>
            {updateSubscriptionErrorMessage && (
              <Typography pb={2} px={3} color="error" fontWeight="bold">
                {updateSubscriptionErrorMessage}
              </Typography>
            )}
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

UpdateSubscriptionModal.defaultProps = {
  isOpen: false,
  product: {}
};

UpdateSubscriptionModal.propTypes = {
  onClose: func.isRequired,
  updateSubscription: func.isRequired,
  product: shape({
    name: string
  }),
  isOpen: bool
};

export default UpdateSubscriptionModal;

/* eslint-disable class-methods-use-this,jsx-a11y/no-autofocus */
import {useState, useRef} from 'react';

import {Tooltip} from '@mui/material';
import {getCellProperty, getCharFromKey, isAlphaNumericKey, isNavigationKey, keyCodes} from '@silevis/reactgrid';
import PropTypes from 'prop-types';

import {isMobile} from '../../../utils';

class TooltipTextCellTemplate {
  constructor() {
    this.wasEscKeyPressed = false;
  }

  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let placeholder;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    const value = parseFloat(text);
    const tooltipContent = getCellProperty(uncertainCell, 'tooltipContent', 'string');
    return {...uncertainCell, text, value, placeholder, tooltipContent};
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
      tooltipContent: cellToMerge.tooltipContent
    });
  }

  handleKeyDown(cell, keyCode, ctrl, shift, alt, key, capsLock) {
    const char = getCharFromKey(key, shift, capsLock);

    if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE)) {
      return {cell: this.getCompatibleCell({...cell, text: char}), enableEditMode: true};
    }
    return {cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER};
  }

  handleCompositionEnd(cell, eventData) {
    return {cell: {...cell, text: eventData}, enableEditMode: true};
  }

  getClassName(cell, isInEditMode) {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className ? cell.className : '';
    return `${isValid ? 'valid' : 'rg-invalid'} ${cell.placeholder && cell.text === '' ? 'placeholder' : ''} ${className}`;
  }

  render(cell, isInEditMode, onCellChanged) {
    if (!isInEditMode) {
      return <TooltipCell cell={cell} getClassName={() => this.getClassName(cell, isInEditMode)} />;
    }

    return (
      <input
        className="rg-input"
        ref={input => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.text}
        onChange={e => onCellChanged(this.getCompatibleCell({...cell, text: e.currentTarget.value}), false)}
        onBlur={e => {
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              text: e.currentTarget.value
            }),
            !this.wasEscKeyPressed
          );
          this.wasEscKeyPressed = false;
        }}
        onCopy={e => e.stopPropagation()}
        onCut={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onPointerDown={e => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={e => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode)) e.stopPropagation();
          if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
        }}
      />
    );
  }
}

const TooltipCell = ({cell, getClassName}) => {
  const [open, setOpen] = useState(false);
  const cellRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleContextMenu = event => {
    if (isMobile()) {
      event.preventDefault();
      handleOpen();
    }
  };

  const isValid = cell.validator ? cell.validator(cell.text) : true;
  const cellText = cell.text || cell.placeholder || '';
  const textToDisplay = !isValid && cell.errorMessage ? cell.errorMessage : cellText;

  return (
    <Tooltip open={open} onClose={handleClose} title={cell.tooltipContent || ''} arrow placement="top">
      <div
        ref={cellRef}
        className={getClassName()}
        style={{cursor: 'text', width: '100%'}}
        onMouseEnter={isMobile() ? undefined : handleOpen}
        onMouseLeave={isMobile() ? undefined : handleClose}
        onContextMenu={handleContextMenu}
      >
        {cell.renderer ? cell.renderer(textToDisplay) : textToDisplay}
      </div>
    </Tooltip>
  );
};

TooltipCell.propTypes = {
  cell: PropTypes.shape({
    text: PropTypes.string,
    placeholder: PropTypes.string,
    tooltipContent: PropTypes.string,
    validator: PropTypes.func,
    errorMessage: PropTypes.string,
    renderer: PropTypes.func
  }).isRequired,
  getClassName: PropTypes.func.isRequired
};

export default TooltipTextCellTemplate;

import {useEffect} from 'react';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes, {arrayOf, func, number, oneOfType, shape, string} from 'prop-types';

const ValueLabelComponent = props => {
  const {children, value} = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired
};

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const StyledSlider = styled(Slider)(({theme}) => ({
  height: 5,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    boxShadow: iOSBoxShadow,
    backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
    border: `3px solid ${process.env.REACT_APP_MAIN_COLOR}`,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow
      }
    }
  },
  '& .MuiSlider-markLabel': {
    font: 'normal normal bold 18px/29px InstrumentSans',
    marginTop: theme.spacing(1)
  },
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf'
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 12,
    width: 12,
    borderRadius: '50%',
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor'
    }
  }
}));

const ProductsSlider = ({setSelectedNumberOfProjects, selectedNumberOfProjects, products}) => {
  const getSliderMarks = () => {
    const differentNumberOfProjects = [...new Set(products.map(p => parseInt(p.numberOfProjects, 10)))].sort((a, b) => a - b);
    const biggestNumberOfProjects = Math.max(...differentNumberOfProjects);
    // Eg: If product with the biggest number of projects is 20, we want the last ProductSlider value to show '20+ projects'
    const customPricingNumberOfProjects = `${biggestNumberOfProjects}+`;
    differentNumberOfProjects.push(customPricingNumberOfProjects);
    return differentNumberOfProjects.map((numberOfProjects, index) => {
      return {
        value: index + 1,
        label: numberOfProjects
      };
    });
  };

  const marks = getSliderMarks();
  const numberOfProjectsBySliderValue = marks.map((mark, index) => {
    return {
      value: index + 1,
      numberOfProjects: mark.label
    };
  });

  const value = numberOfProjectsBySliderValue.find(item => item.numberOfProjects === selectedNumberOfProjects)?.value || 1;

  const handleChange = (event, val) => {
    const {numberOfProjects} = numberOfProjectsBySliderValue.find(item => item.value === val);
    setSelectedNumberOfProjects(numberOfProjects);
  };

  useEffect(() => {
    if (marks.length > 1 && !selectedNumberOfProjects) {
      // Business rule : by default, if user does not already have a subscrption, we initialize slider on 20 projects$
      setSelectedNumberOfProjects(20);
    }
  }, [products]);

  return (
    <Box sx={{maxWidth: 650, width: '90%'}}>
      <StyledSlider onChange={handleChange} min={1} max={numberOfProjectsBySliderValue.length} value={value} marks={marks} valueLabelDisplay="off" />
    </Box>
  );
};

const productShape = shape({
  amount: number,
  currency: string,
  description: string,
  id: string,
  interval: string,
  name: string,
  numberOfProjects: oneOfType([number, string]),
  price_id: string
});

ProductsSlider.defaultProps = {
  products: [],
  selectedNumberOfProjects: 1
};

ProductsSlider.propTypes = {
  selectedNumberOfProjects: oneOfType([number, string]),
  setSelectedNumberOfProjects: func.isRequired,
  products: arrayOf(productShape)
};

export default ProductsSlider;

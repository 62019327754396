import {Alert} from '@mui/material';
import {Link} from 'react-router-dom';

import {APP_ROUTES} from '../../../const';
import usePayment from '../../../hooks/providers/usePayment';

const InsufficientSubscriptionAlert = () => {
  const {numberOfProjectsOwnedByUser, productSubscribed, maxNumberOfProjects, hasBeenSubscribedOnce} = usePayment();
  const userHasInsufficientSubscription = numberOfProjectsOwnedByUser > maxNumberOfProjects;

  const shouldDisplayAlert = userHasInsufficientSubscription && productSubscribed && hasBeenSubscribedOnce;
  return shouldDisplayAlert ? (
    <Alert severity="error">
      <Link to={APP_ROUTES.profile}>Mettez à niveau votre abonnement</Link>&nbsp; pour activer les services , ou supprimez les projets dont vous n'avez plus besoin pour continuer à utiliser les autres
      projets.
    </Alert>
  ) : null;
};

export default InsufficientSubscriptionAlert;

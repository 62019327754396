import {useMemo, useState} from 'react';

import {func, node, oneOfType} from 'prop-types';

import ErrorContext from '../contexts/ErrorContext';

const ErrorProvider = ({children}) => {
  const [error, setError] = useState(null);
  const triggerError = e => {
    setError(e);
  };

  const resetError = () => {
    setError(null);
  };

  const useMemoDeps = [error, triggerError, resetError];

  const value = useMemo(
    () => ({
      error,
      triggerError,
      resetError
    }),
    useMemoDeps
  );

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};

ErrorProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ErrorProvider;
